

export const COUNTRIES = 
[
	{
		"name": "Afghanistan",
		"dial_code": "+93",
		"code": "af",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/af.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Albania",
		"dial_code": "+355",
		"code": "al",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/al.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Algeria",
		"dial_code": "+213",
		"code": "dz",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/dz.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "AmericanSamoa",
		"dial_code": "+1 684",
		"code": "as",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/as.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Andorra",
		"dial_code": "+376",
		"code": "ad",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ad.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Angola",
		"dial_code": "+244",
		"code": "ao",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ao.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Anguilla",
		"dial_code": "+1 264",
		"code": "ai",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ai.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Antarctica",
		"dial_code": "+672",
		"code": "aq",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/aq.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Antigua and Barbuda",
		"dial_code": "+1268",
		"code": "ag",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ag.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Argentina",
		"dial_code": "+54",
		"code": "ar",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ar.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Armenia",
		"dial_code": "+374",
		"code": "am",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/am.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Aruba",
		"dial_code": "+297",
		"code": "aw",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/aw.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Australia",
		"dial_code": "+61",
		"code": "au",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/au.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Austria",
		"dial_code": "+43",
		"code": "at",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/at.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Azerbaijan",
		"dial_code": "+994",
		"code": "az",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/az.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Bahamas",
		"dial_code": "+1 242",
		"code": "bs",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/bs.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Bahrain",
		"dial_code": "+973",
		"code": "bh",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/bh.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Bangladesh",
		"dial_code": "+880",
		"code": "bd",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/bd.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Barbados",
		"dial_code": "+1 246",
		"code": "bb",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/bb.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Belarus",
		"dial_code": "+375",
		"code": "by",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/by.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Belgium",
		"dial_code": "+32",
		"code": "be",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/be.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Belize",
		"dial_code": "+501",
		"code": "bz",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/bz.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Benin",
		"dial_code": "+229",
		"code": "bj",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/bj.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Bermuda",
		"dial_code": "+1 441",
		"code": "bm",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/bm.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Bhutan",
		"dial_code": "+975",
		"code": "bt",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/bt.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Bolivia, Plurinational State of",
		"dial_code": "+591",
		"code": "bo",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/bo.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Bosnia and Herzegovina",
		"dial_code": "+387",
		"code": "ba",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ba.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Botswana",
		"dial_code": "+267",
		"code": "bw",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/bw.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Brazil",
		"dial_code": "+55",
		"code": "br",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/br.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "British Indian Ocean Territory",
		"dial_code": "+246",
		"code": "io",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/io.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Brunei Darussalam",
		"dial_code": "+673",
		"code": "bn",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/bn.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Bulgaria",
		"dial_code": "+359",
		"code": "bg",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/bg.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Burkina Faso",
		"dial_code": "+226",
		"code": "bf",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/bf.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Burundi",
		"dial_code": "+257",
		"code": "bi",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/bi.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Cambodia",
		"dial_code": "+855",
		"code": "kh",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/kh.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Cameroon",
		"dial_code": "+237",
		"code": "cm",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/cm.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Cape Verde",
		"dial_code": "+238",
		"code": "cv",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/cv.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Cayman Islands",
		"dial_code": "+ 345",
		"code": "ky",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ky.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Central African Republic",
		"dial_code": "+236",
		"code": "cf",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/cf.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Chad",
		"dial_code": "+235",
		"code": "td",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/td.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Chile",
		"dial_code": "+56",
		"code": "cl",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/cl.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "China",
		"dial_code": "+86",
		"code": "cn",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/cn.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Christmas Island",
		"dial_code": "+61",
		"code": "cx",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/cx.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Cocos (Keeling) Islands",
		"dial_code": "+61",
		"code": "cc",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/cc.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Colombia",
		"dial_code": "+57",
		"code": "co",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/co.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Comoros",
		"dial_code": "+269",
		"code": "km",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/km.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Congo",
		"dial_code": "+242",
		"code": "cg",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/cg.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Congo, The Democratic Republic of the",
		"dial_code": "+243",
		"code": "cd",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/cd.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Cook Islands",
		"dial_code": "+682",
		"code": "ck",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ck.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Costa Rica",
		"dial_code": "+506",
		"code": "cr",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/cr.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Cote d'Ivoire",
		"dial_code": "+225",
		"code": "ci",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ci.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Croatia",
		"dial_code": "+385",
		"code": "hr",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/hr.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Cuba",
		"dial_code": "+53",
		"code": "cu",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/cu.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Cyprus",
		"dial_code": "+357",
		"code": "cy",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/cy.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Czech Republic",
		"dial_code": "+420",
		"code": "cz",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/cz.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Denmark",
		"dial_code": "+45",
		"code": "dk",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/dk.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Djibouti",
		"dial_code": "+253",
		"code": "dj",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/dj.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Dominica",
		"dial_code": "+1 767",
		"code": "dm",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/dm.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Dominican Republic",
		"dial_code": "+1 849",
		"code": "do",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/do.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Ecuador",
		"dial_code": "+593",
		"code": "ec",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ec.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Egypt",
		"dial_code": "+20",
		"code": "eg",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/eg.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "El Salvador",
		"dial_code": "+503",
		"code": "sv",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/sv.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Equatorial Guinea",
		"dial_code": "+240",
		"code": "gq",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/gq.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Eritrea",
		"dial_code": "+291",
		"code": "er",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/er.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Estonia",
		"dial_code": "+372",
		"code": "ee",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ee.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Ethiopia",
		"dial_code": "+251",
		"code": "et",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/et.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Falkland Islands (Malvinas)",
		"dial_code": "+500",
		"code": "fk",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/fk.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Faroe Islands",
		"dial_code": "+298",
		"code": "fo",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/fo.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Fiji",
		"dial_code": "+679",
		"code": "fj",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/fj.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Finland",
		"dial_code": "+358",
		"code": "fi",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/fi.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "France",
		"dial_code": "+33",
		"code": "fr",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/fr.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "French Guiana",
		"dial_code": "+594",
		"code": "gf",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/gf.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "French Polynesia",
		"dial_code": "+689",
		"code": "pf",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/pf.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Gabon",
		"dial_code": "+241",
		"code": "ga",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ga.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Gambia",
		"dial_code": "+220",
		"code": "gm",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/gm.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Georgia",
		"dial_code": "+995",
		"code": "ge",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ge.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Germany",
		"dial_code": "+49",
		"code": "de",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/de.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Ghana",
		"dial_code": "+233",
		"code": "gh",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/gh.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Gibraltar",
		"dial_code": "+350",
		"code": "gi",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/gi.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Greece",
		"dial_code": "+30",
		"code": "gr",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/gr.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Greenland",
		"dial_code": "+299",
		"code": "gl",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/gl.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Grenada",
		"dial_code": "+1 473",
		"code": "gd",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/gd.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Guadeloupe",
		"dial_code": "+590",
		"code": "gp",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/gp.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Guam",
		"dial_code": "+1 671",
		"code": "gu",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/gu.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Guatemala",
		"dial_code": "+502",
		"code": "gt",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/gt.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Guernsey",
		"dial_code": "+44",
		"code": "gg",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/gg.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Guinea",
		"dial_code": "+224",
		"code": "gn",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/gn.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Guinea-Bissau",
		"dial_code": "+245",
		"code": "gw",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/gw.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Guyana",
		"dial_code": "+595",
		"code": "gy",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/gy.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Haiti",
		"dial_code": "+509",
		"code": "ht",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ht.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Holy See (Vatican City State)",
		"dial_code": "+379",
		"code": "va",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/va.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Honduras",
		"dial_code": "+504",
		"code": "hn",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/hn.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Hong Kong",
		"dial_code": "+852",
		"code": "hk",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/hk.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Hungary",
		"dial_code": "+36",
		"code": "hu",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/hu.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Iceland",
		"dial_code": "+354",
		"code": "is",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/is.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "India",
		"dial_code": "+91",
		"code": "in",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/in.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Indonesia",
		"dial_code": "+62",
		"code": "id",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/id.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Iran, Islamic Republic of",
		"dial_code": "+98",
		"code": "ir",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ir.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Iraq",
		"dial_code": "+964",
		"code": "iq",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/iq.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Ireland",
		"dial_code": "+353",
		"code": "ie",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ie.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Isle of Man",
		"dial_code": "+44",
		"code": "im",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/im.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Israel",
		"dial_code": "+972",
		"code": "il",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/il.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Italy",
		"dial_code": "+39",
		"code": "it",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/it.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Jamaica",
		"dial_code": "+1 876",
		"code": "jm",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/jm.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Japan",
		"dial_code": "+81",
		"code": "jp",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/jp.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Jersey",
		"dial_code": "+44",
		"code": "je",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/je.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Jordan",
		"dial_code": "+962",
		"code": "jo",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/jo.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Kazakhstan",
		"dial_code": "+7 7",
		"code": "kz",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/kz.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Kenya",
		"dial_code": "+254",
		"code": "ke",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ke.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Kiribati",
		"dial_code": "+686",
		"code": "ki",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ki.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Korea, Democratic People's Republic of",
		"dial_code": "+850",
		"code": "kp",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/kp.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Korea, Republic of",
		"dial_code": "+82",
		"code": "kr",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/kr.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Kuwait",
		"dial_code": "+965",
		"code": "kw",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/kw.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Kyrgyzstan",
		"dial_code": "+996",
		"code": "kg",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/kg.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Lao People's Democratic Republic",
		"dial_code": "+856",
		"code": "la",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/la.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Latvia",
		"dial_code": "+371",
		"code": "lv",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/lv.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Lebanon",
		"dial_code": "+961",
		"code": "lb",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/lb.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Lesotho",
		"dial_code": "+266",
		"code": "ls",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ls.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Liberia",
		"dial_code": "+231",
		"code": "lr",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/lr.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Libyan Arab Jamahiriya",
		"dial_code": "+218",
		"code": "ly",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ly.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Liechtenstein",
		"dial_code": "+423",
		"code": "li",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/li.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Lithuania",
		"dial_code": "+370",
		"code": "lt",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/lt.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Luxembourg",
		"dial_code": "+352",
		"code": "lu",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/lu.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Macao",
		"dial_code": "+853",
		"code": "mo",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/mo.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Macedonia, The Former Yugoslav Republic of",
		"dial_code": "+389",
		"code": "mk",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/mk.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Madagascar",
		"dial_code": "+261",
		"code": "mg",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/mg.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Malawi",
		"dial_code": "+265",
		"code": "mw",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/mw.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Malaysia",
		"dial_code": "+60",
		"code": "my",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/my.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Maldives",
		"dial_code": "+960",
		"code": "mv",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/mv.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Mali",
		"dial_code": "+223",
		"code": "ml",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ml.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Malta",
		"dial_code": "+356",
		"code": "mt",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/mt.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Marshall Islands",
		"dial_code": "+692",
		"code": "mh",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/mh.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Martinique",
		"dial_code": "+596",
		"code": "mq",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/mq.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Mauritania",
		"dial_code": "+222",
		"code": "mr",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/mr.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Mauritius",
		"dial_code": "+230",
		"code": "mu",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/mu.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Mayotte",
		"dial_code": "+262",
		"code": "yt",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/yt.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Mexico",
		"dial_code": "+52",
		"code": "mx",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/mx.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Micronesia, Federated States of",
		"dial_code": "+691",
		"code": "fm",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/fm.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Moldova, Republic of",
		"dial_code": "+373",
		"code": "md",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/md.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Monaco",
		"dial_code": "+377",
		"code": "mc",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/mc.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Mongolia",
		"dial_code": "+976",
		"code": "mn",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/mn.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Montenegro",
		"dial_code": "+382",
		"code": "me",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/me.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Montserrat",
		"dial_code": "+1664",
		"code": "ms",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ms.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Morocco",
		"dial_code": "+212",
		"code": "ma",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ma.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Mozambique",
		"dial_code": "+258",
		"code": "mz",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/mz.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Myanmar",
		"dial_code": "+95",
		"code": "mm",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/mm.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Namibia",
		"dial_code": "+264",
		"code": "na",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/na.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Nauru",
		"dial_code": "+674",
		"code": "nr",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/nr.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Nepal",
		"dial_code": "+977",
		"code": "np",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/np.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Netherlands",
		"dial_code": "+31",
		"code": "nl",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/nl.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Netherlands Antilles",
		"dial_code": "+599",
		"code": "an",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/an.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "New Caledonia",
		"dial_code": "+687",
		"code": "nc",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/nc.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "New Zealand",
		"dial_code": "+64",
		"code": "nz",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/nz.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Nicaragua",
		"dial_code": "+505",
		"code": "ni",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ni.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Niger",
		"dial_code": "+227",
		"code": "ne",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ne.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Nigeria",
		"dial_code": "+234",
		"code": "ng",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ng.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Niue",
		"dial_code": "+683",
		"code": "nu",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/nu.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Norfolk Island",
		"dial_code": "+672",
		"code": "nf",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/nf.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Northern Mariana Islands",
		"dial_code": "+1 670",
		"code": "mp",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/mp.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Norway",
		"dial_code": "+47",
		"code": "no",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/no.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Oman",
		"dial_code": "+968",
		"code": "om",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/om.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Pakistan",
		"dial_code": "+92",
		"code": "pk",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/pk.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Palau",
		"dial_code": "+680",
		"code": "pw",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/pw.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Palestinian Territory, Occupied",
		"dial_code": "+970",
		"code": "ps",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ps.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Panama",
		"dial_code": "+507",
		"code": "pa",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/pa.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Papua New Guinea",
		"dial_code": "+675",
		"code": "pg",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/pg.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Paraguay",
		"dial_code": "+595",
		"code": "py",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/py.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Peru",
		"dial_code": "+51",
		"code": "pe",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/pe.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Philippines",
		"dial_code": "+63",
		"code": "ph",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ph.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Pitcairn",
		"dial_code": "+872",
		"code": "pn",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/pn.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Poland",
		"dial_code": "+48",
		"code": "pl",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/pl.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Portugal",
		"dial_code": "+351",
		"code": "pt",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/pt.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Puerto Rico",
		"dial_code": "+1 939",
		"code": "pr",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/pr.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Qatar",
		"dial_code": "+974",
		"code": "qa",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/qa.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Romania",
		"dial_code": "+40",
		"code": "ro",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ro.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Russia",
		"dial_code": "+7",
		"code": "ru",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ru.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Rwanda",
		"dial_code": "+250",
		"code": "rw",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/rw.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Réunion",
		"dial_code": "+262",
		"code": "re",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/re.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Saint Barthélemy",
		"dial_code": "+590",
		"code": "bl",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/bl.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Saint Helena, Ascension and Tristan Da Cunha",
		"dial_code": "+290",
		"code": "sh",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/sh.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Saint Kitts and Nevis",
		"dial_code": "+1 869",
		"code": "kn",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/kn.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Saint Lucia",
		"dial_code": "+1 758",
		"code": "lc",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/lc.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Saint Martin",
		"dial_code": "+590",
		"code": "mf",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/mf.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Saint Pierre and Miquelon",
		"dial_code": "+508",
		"code": "pm",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/pm.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Saint Vincent and the Grenadines",
		"dial_code": "+1 784",
		"code": "vc",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/vc.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Samoa",
		"dial_code": "+685",
		"code": "ws",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ws.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "San Marino",
		"dial_code": "+378",
		"code": "sm",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/sm.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Sao Tome and Principe",
		"dial_code": "+239",
		"code": "st",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/st.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Saudi Arabia",
		"dial_code": "+966",
		"code": "sa",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/sa.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Senegal",
		"dial_code": "+221",
		"code": "sn",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/sn.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Serbia",
		"dial_code": "+381",
		"code": "rs",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/rs.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Seychelles",
		"dial_code": "+248",
		"code": "sc",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/sc.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Sierra Leone",
		"dial_code": "+232",
		"code": "sl",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/sl.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Singapore",
		"dial_code": "+65",
		"code": "sg",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/sg.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Slovakia",
		"dial_code": "+421",
		"code": "sk",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/sk.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Slovenia",
		"dial_code": "+386",
		"code": "si",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/si.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Solomon Islands",
		"dial_code": "+677",
		"code": "sb",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/sb.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Somalia",
		"dial_code": "+252",
		"code": "so",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/so.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "South Africa",
		"dial_code": "+27",
		"code": "za",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/za.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "South Georgia and the South Sandwich Islands",
		"dial_code": "+500",
		"code": "gs",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/gs.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Spain",
		"dial_code": "+34",
		"code": "es",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/es.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Sri Lanka",
		"dial_code": "+94",
		"code": "lk",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/lk.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Sudan",
		"dial_code": "+249",
		"code": "sd",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/sd.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Suriname",
		"dial_code": "+597",
		"code": "sr",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/sr.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Svalbard and Jan Mayen",
		"dial_code": "+47",
		"code": "sj",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/sj.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Swaziland",
		"dial_code": "+268",
		"code": "sz",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/sz.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Sweden",
		"dial_code": "+46",
		"code": "se",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/se.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Switzerland",
		"dial_code": "+41",
		"code": "ch",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ch.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Syrian Arab Republic",
		"dial_code": "+963",
		"code": "sy",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/sy.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Taiwan",
		"dial_code": "+886",
		"code": "tw",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/tw.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Tajikistan",
		"dial_code": "+992",
		"code": "tj",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/tj.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Tanzania, United Republic of",
		"dial_code": "+255",
		"code": "tz",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/tz.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Thailand",
		"dial_code": "+66",
		"code": "th",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/th.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Timor-Leste",
		"dial_code": "+670",
		"code": "tl",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/tl.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Togo",
		"dial_code": "+228",
		"code": "tg",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/tg.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Tokelau",
		"dial_code": "+690",
		"code": "tk",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/tk.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Tonga",
		"dial_code": "+676",
		"code": "to",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/to.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Trinidad and Tobago",
		"dial_code": "+1 868",
		"code": "tt",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/tt.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Tunisia",
		"dial_code": "+216",
		"code": "tn",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/tn.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Turkey",
		"dial_code": "+90",
		"code": "tr",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/tr.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Turkmenistan",
		"dial_code": "+993",
		"code": "tm",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/tm.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Turks and Caicos Islands",
		"dial_code": "+1 649",
		"code": "tc",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/tc.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Tuvalu",
		"dial_code": "+688",
		"code": "tv",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/tv.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Uganda",
		"dial_code": "+256",
		"code": "ug",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ug.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Ukraine",
		"dial_code": "+380",
		"code": "ua",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ua.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "United Arab Emirates",
		"dial_code": "+971",
		"code": "ae",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ae.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "United Kingdom",
		"dial_code": "+44",
		"code": "gb",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/gb.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "United States",
		"dial_code": "+1",
		"code": "us",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/us.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Uruguay",
		"dial_code": "+598",
		"code": "uy",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/uy.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Uzbekistan",
		"dial_code": "+998",
		"code": "uz",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/uz.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Vanuatu",
		"dial_code": "+678",
		"code": "vu",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/vu.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Venezuela, Bolivarian Republic of",
		"dial_code": "+58",
		"code": "ve",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ve.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Viet Nam",
		"dial_code": "+84",
		"code": "vn",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/vn.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Virgin Islands, British",
		"dial_code": "+1 284",
		"code": "vg",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/vg.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Virgin Islands, U.S.",
		"dial_code": "+1 340",
		"code": "vi",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/vi.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Wallis and Futuna",
		"dial_code": "+681",
		"code": "wf",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/wf.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Yemen",
		"dial_code": "+967",
		"code": "ye",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ye.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Zambia",
		"dial_code": "+260",
		"code": "zm",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/zm.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Zimbabwe",
		"dial_code": "+263",
		"code": "zw",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/zw.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	},
	{
		"name": "Åland Islands",
		"dial_code": "+358",
		"code": "ax",
		getFlagImage: () => {
			try {
				return require("../../assets/images/isd-flags/ax.svg")
			} catch (error) {
				return require("../../assets/images/isd-flags/af.svg");
			}
		}
	}
]