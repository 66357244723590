import React, { Component } from "react";
import style from "./mobile-top-nav.module.scss";

const MobileTopNav = props => {
  const { options, selectedOptionId, onSelect } = props;
  const _getClassName = option => {
    if (option.id === selectedOptionId) {
      return style.selectedOption;
    }
    return style.option;
  };
  return (
    <div id={style.mobileNavBarWrapper}>
      <div id={style.mobileNavBar}>
        {options.map((option, optionIndex) => (
          <div className={_getClassName(option)} key={optionIndex} onClick={e => onSelect(option.id)}>
            <i className={option.iconCode}></i>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileTopNav;
