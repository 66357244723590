import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ProtectedRoute from "./general/components/protected-routes";
import PublicRoute from "./general/components/public-route";
import PrivateModule from "./private";
import PublicModule from "./public";
import TermsAndConditionsPage from "./public/pages/terms-and-conditions/terms-and-conditions";
import PrivacyPolicyPage from "./public/pages/privacy-policy/privacy-policy";
import AccountVerified from "./public/pages/account-verified/account-verified";

class Modules extends Component {
  state = {};
  render() {
    return (
      <>
        <Switch>
          <Route path="/terms-and-conditions" component={TermsAndConditionsPage} />
          <Route path="/privacy-policy" component={PrivacyPolicyPage} />
          <Route path="/auth/account-verified" component={AccountVerified} redirectTo="/" />
          <PublicRoute path="/auth" component={PublicModule} redirectRoute="/home" />
          <ProtectedRoute path="/" component={PrivateModule} redirectRoute="/auth" />
          <Route path="*" render={() => <Redirect to="/home" />} />
        </Switch>
      </>
    );
  }
}

export default Modules;
