import React, { Component } from "react";
import "./curriculum-content-viewer.scss";
import { deepClone, scrollToTop } from "../../../../helper-methods";
import Quiz from "react-quiz-component";

class CurriculumContentViewer extends Component {
  state = { maxLevel: -1 };

  currentMenuId = null;
  sectionRefs = [];
  lastNodePath = null;
  timerInstance = false;

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.node) !== JSON.stringify(this.props.node)) {
      this.sectionRefs = [];
      scrollToTop();
      this._determineMaxLevel();
      setTimeout(() => {
        this._scrollToSection(this.props.nodePath[this.state.maxLevel]);
      }, 30);
    } else {
      if (JSON.stringify(this.lastNodePath) !== JSON.stringify(this.props.nodePath)) {
        this._scrollToSection(this.props.nodePath[this.state.maxLevel]);
      }
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  _determineMaxLevel = () => {
    const { nodePath } = this.props;
    let maxLevel = -1;
    for (let level in nodePath) {
      if (maxLevel < level) {
        maxLevel = level;
      }
    }
    this.setState({ maxLevel });
  };

  _renderContent = content => {
    let className = "";
    if (!this.currentMenuId) {
      this.currentMenuId = content.linkedMenuId;
      className = "contentMargin";
    } else {
      if (this.currentMenuId !== content.linkedMenuId) {
        this.currentMenuId = content.linkedMenuId;
        className = "contentMargin";
      }
    }
    switch (content.contentType) {
      case "heading": {
        return <h4 className={className}>{content.data}</h4>;
      }
      case "video": {
        return (
          <video controls className={"video " + className}>
            <source src={content.data} />
            Your browser does not support the video tag.
          </video>
        );
      }
      case "paragraph": {
        return <p className={className} dangerouslySetInnerHTML={{ __html: content.data }}></p>;
      }
      case "list": {
        return (
          <ul className={className}>
            {content.data.map((listItem, listIndex) => (
              <li key={listIndex} dangerouslySetInnerHTML={{ __html: listItem }}></li>
            ))}
          </ul>
        );
      }
      case "image": {
        let { source, altText, caption } = content.data;
        return (
          <figure>
            <img src={require(`./../../../../curriculum-data/images/${source}`)} alt={altText} />
            {caption && <figcaption dangerouslySetInnerHTML={{ __html: caption }}></figcaption>}
          </figure>
        );
      }
      case "table": {
        let { headerRow, bodyRows } = content.data;
        return (
          <table>
            <thead>
              <tr>
                {headerRow.map((cell, id) => (
                  <td key={id}>{cell}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              {bodyRows.map(row => (
                <tr>
                  {row.map(cell => (
                    <td>{cell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        );
      }
      case "quiz": {
        return (
          <Quiz quiz={content.data} showDefaultResult={true} showInstantFeedback={false} continueTillCorrect={false} />
        );
      }
    }
  };

  handleScroll = e => {
    if (!this.timerInstance) {
      this.timerInstance = true;
      setTimeout(() => {
        this.sectionRefs.forEach((ref, i) => {
          if (ref) {
            if (ref.getBoundingClientRect().y < 300 && ref.getBoundingClientRect().y > -200) {
              const { nodePath } = deepClone(this.props);
              nodePath[this.state.maxLevel] = i;
              this.lastNodePath = nodePath;
              this.props.onNodePathUpdate(nodePath);
            }
          }
        });
        this.timerInstance = false;
      }, 500);
    }
  };

  _scrollToSection = sectionIndex => {
    if (this.sectionRefs[sectionIndex]) {
      window.scrollTo(0, this.sectionRefs[sectionIndex].offsetTop - 120);
    }
  };

  // scrollToMyRef = itemIndex => window.scrollTo(0, this.myRef[itemIndex].offsetTop - 60);

  render() {
    if (this.props.node && this.props.node.menus && this.props.node.menus.length) {
      // Contains multiple sections
      // So parse sections
      return (
        <div id="contentViewer">
          <h2>{this.props.node.heading}</h2>
          {this.props.node.menus.map((menu, menuIndex) => (
            <div
              key={menuIndex}
              ref={ref => {
                this.sectionRefs[menuIndex] = ref;
              }}
            >
              {menu.contents.map((content, contentIndex) => (
                <React.Fragment key={menuIndex + contentIndex}>{this._renderContent(content)}</React.Fragment>
              ))}
            </div>
          ))}
        </div>
      );
    } else if (this.props.node && this.props.node.contents && this.props.node.contents.length) {
      return (
        <div id="contentViewer">
          <h2>{this.props.node.heading}</h2>
          {this.props.node.contents.map((content, contentIndex) => (
            <React.Fragment key={contentIndex}>{this._renderContent(content)}</React.Fragment>
          ))}
        </div>
      );
    } else {
      return <> </>;
    }
  }
}

export default CurriculumContentViewer;
