import React, { Component } from "react";
import style from "./side-nav-menu.module.scss";
import LanguageParsedText from "../../../../multi-lang/lang-parsed-text/lang-parsed-text";

const SideNavMenu = props => {
  const { options, selectedOptionId, onSelect } = props;
  const _getClassName = option => {
    if (option.id === selectedOptionId) {
      return style.selectedOption;
    }
    return style.option;
  };
  const _getText = option => {
    if (option.i18nLangKeyString && option.i18nLangKeyString.length) {
      return <LanguageParsedText keyString={option.i18nLangKeyString} />;
    }
    return option.text || "";
  };
  return (
    <div id={style.sideNavbar}>
      {options.map((option, optionIndex) => (
        <div className={_getClassName(option)} key={optionIndex} onClick={e => onSelect(option.id)}>
          <div>
            <i className={option.iconCode}></i> &nbsp;{" "}
          </div>
          <p>{_getText(option)}</p>
        </div>
      ))}
    </div>
  );
};

export default SideNavMenu;
