import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, FormGroup, Row } from "reactstrap";
import * as images from "../../../../assets/images";
import { sleepTime } from "../../../../helper-methods";
import LanguageParsedText, { getValueFromCurrentLang } from "../../../../multi-lang/lang-parsed-text/lang-parsed-text";
import { hideLoader, showLoader } from "../../../../redux/actions/loader-data";
import { updateUserData } from "../../../../redux/actions/user-data";
import { store } from "../../../../redux/store";
import Button from "../../../general/components/button/button";
import InputField from "../../../general/components/input-field/input-field";
import "./login-page.scss";
import { loginUser } from "../../../../aws-cognito";

const initialState = {
  formFields: {
    email: {
      value: "",
      isValid: false,
      isDirty: false
    },
    password: {
      value: "",
      isValid: false,
      isDirty: false
    }
  },
  isFormValid: false,
  responseError: ""
};

class LoginPage extends Component {
  state = initialState;

  _markAsDirty = fieldName => {
    const { formFields } = this.state;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields });
    this._validateForm();
    this._hideResponseError();
  };

  _updateFieldValue = (fieldName, value) => {
    this._hideResponseError();
    const { formFields } = this.state;
    formFields[fieldName].value = value;
    this.setState({ formFields });
    if (formFields[fieldName].isDirty) {
      // Validate
      this._validateForm();
    }
  };

  _showResponseError = errorText => {
    this.setState({ responseError: errorText });
  };

  _hideResponseError = () => {
    const { responseError } = this.state;
    if (responseError.length) {
      this.setState({ responseError: "" });
    }
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      let isFormValid = true;
      Object.keys(formFields).forEach((fieldName, index) => {
        switch (fieldName) {
          case "email": {
            // var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            // if (emailRegex.test(String(formFields.email.value).toLowerCase())) {
            //   formFields.email.isValid = true;
            // } else {
            //   formFields.email.isValid = false;
            //   isFormValid = false;
            // }
            if (formFields.email.value.length >= 5) {
              formFields.email.isValid = true;
            } else {
              formFields.email.isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "password": {
            if (formFields.password.value.length >= 5) {
              formFields.password.isValid = true;
            } else {
              formFields.password.isValid = false;
              isFormValid = false;
            }
            break;
          }
        }
      });
      this.setState({ formFields, isFormValid }, () => {
        resolve();
      });
    });
  };

  _makeAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((fieldName, index) => {
        formFields[fieldName].isDirty = true;
      });
      this.setState({ formFields }, () => {
        resolve();
      });
    });
  };

  _login = async e => {
    e.preventDefault();
    this._makeAllFieldDirty();
    await this._validateForm();
    const { formFields, isFormValid, redirectTo } = this.state;
    if (isFormValid) {
      try {
        store.dispatch(showLoader("Logging you in"));
        const user = await loginUser(formFields.email.value, formFields.password.value);
        this.props.updateUserData({
          token: user.signInUserSession.accessToken.jwtToken,
          cognitoUser: user
        });
        // Login success
        this.props.history.replace("/home");
        store.dispatch(hideLoader());
      } catch (loginError) {
        store.dispatch(hideLoader());
        this._showResponseError(
          loginError && loginError.message && loginError.message.length ? loginError.message : "Login server error"
        );
      }
    }
  };
  render() {
    const { formFields, responseError } = this.state;
    return (
      <React.Fragment>
        <div id="loginPageWarpper">
          <Container fluid={true}>
            <Row>
              <Col md="4" sm="12" className="imageWrapper">
                <img alt="" src={require("../../../../assets/images/files/Pharos Logo Final_Square Blue.svg")} />
              </Col>
              <Col md="8" sm="12" className="formWrapper">
                <div className="formContainer">
                  <Row>
                    <Col id="loginWrapper">
                      <h1>
                        <LanguageParsedText keyString="loginPage.header" />
                      </h1>
                      <p>
                        <LanguageParsedText keyString="loginPage.subHeader" />
                      </p>
                      <form onSubmit={e => this._login(e)}>
                        <FormGroup>
                          <InputField
                            type="email"
                            name="email"
                            placeholder={getValueFromCurrentLang("loginPage.field1.placeholder")}
                            value={formFields.email.value}
                            onChange={e => this._updateFieldValue("email", e.target.value)}
                            onBlur={() => this._markAsDirty("email")}
                          />
                          {formFields.email.isDirty && !formFields.email.isValid ? (
                            <p className="errorText">
                              <LanguageParsedText keyString="loginPage.field1.errorMessage" />
                            </p>
                          ) : null}
                        </FormGroup>
                        <FormGroup>
                          <InputField
                            type="password"
                            name="password"
                            placeholder={getValueFromCurrentLang("loginPage.field2.placeholder")}
                            value={formFields.password.value}
                            onChange={e => this._updateFieldValue("password", e.target.value)}
                            onBlur={() => this._markAsDirty("password")}
                          />
                          {formFields.password.isDirty && !formFields.password.isValid ? (
                            <p className="errorText">
                              <LanguageParsedText keyString="loginPage.field2.errorMessage" />
                            </p>
                          ) : null}
                        </FormGroup>
                      </form>
                      <div id="loginHelperPanel">
                        <label className="checkbox">
                          <input type="checkbox" className="checkbox__input" />
                          <span className="checkbox__checkmark"></span>
                          <span>
                            <LanguageParsedText keyString="loginPage.rememberMe" />
                          </span>
                        </label>
                        <div onClick={() => this.props.history.push("/auth/forgot-password")}>
                          <LanguageParsedText keyString="loginPage.forgotPassword" />
                        </div>
                      </div>
                      <Button
                        className="loginBtn"
                        text={<LanguageParsedText keyString="loginPage.submitButton" />}
                        onClick={e => this._login(e)}
                      />
                      {responseError.length ? (
                        <div>
                          <p className="errorText">{responseError}</p>
                        </div>
                      ) : null}
                      <div id="signUpAccess">
                        <p onClick={() => this.props.history.push("/auth/signup")}>
                          <LanguageParsedText keyString="loginPage.registerLabel" />
                          <span className="registerLink">
                          <LanguageParsedText keyString="loginPage.registerLink" />
                          </span>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToDispatch = dispatch => {
  return {
    showLoader: loaderText => dispatch(showLoader(loaderText)),
    hideLoader: () => dispatch(hideLoader()),
    updateUserData: userData => dispatch(updateUserData(userData))
  };
};

export default connect(null, mapStateToDispatch)(LoginPage);
