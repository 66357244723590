import React, { Component } from "react";
import PublicRoute from "../general/components/public-route";
import LoginPage from "./pages/login-page/login-page";
import { Route, Redirect, Switch } from "react-router-dom";
import ForgotPasswordPage from "./pages/forgot-password-page/forgot-password-page";
import SignupPage from "./pages/signup-page/signup-page";
import ResetPasswordPage from "./pages/reset-password-page/reset-password-page";
import WelcomePage from './pages/welcome-page/welcome-page';

class PublicModule extends Component {
  state = {};
  render() {
    return (
      <>
        <Switch>
          <PublicRoute path="/auth/login" component={LoginPage} redirectTo="/" />
          <PublicRoute path="/auth/forgot-password" component={ForgotPasswordPage} redirectTo="/" />
          <PublicRoute path="/auth/signup" component={SignupPage} redirectTo="/" />
          <PublicRoute path="/auth/reset-password" component={ResetPasswordPage} redirectTo="/" />
          <PublicRoute path="/auth/welcome" component={WelcomePage} redirectTo="/" />
          <Route path="*" render={() => <Redirect to="/auth/login" />} />
        </Switch>
      </>
    );
  }
}

export default PublicModule;
