import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, Container, Row, Col } from "reactstrap";
import "./dropbox-upload-modal.scss";
import Button from "../../../general/components/button/button";
import InputField from "../../../general/components/input-field/input-field";
import FilterChips from "../filter-chips/filter-chips";
import { s3Upload, createReport, removeFileFromS3, updateReport } from "../../../../api-calls";
import { showToast } from "../../../../helper-methods/index";
import LanguageParsedText, { getValueFromCurrentLang } from "../../../../multi-lang/lang-parsed-text/lang-parsed-text";

const DropboxUploadModal = props => {
  const { isVisible, dismissModal, onUpload, topics, isEditMode, selectedFile } = props;
  const [file, setFile] = useState(null);
  const [formFields, setFormFields] = useState({
    title: {
      value: "",
      isValid: false,
      isDirty: false
    },
    description: {
      value: "",
      isValid: false,
      isDirty: false
    }
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [tags, setTags] = useState([]);
  const [fileError, setFileError] = useState(null);

  const _resetStates = () => {
    setFile(null);
    setFormFields({
      title: {
        value: "",
        isValid: false,
        isDirty: false
      },
      description: {
        value: "",
        isValid: false,
        isDirty: false
      }
    });
    setIsFormValid(false);
    setTags([]);
    setFileError(null);
  };

  useEffect(() => {
    validateForm();
  }, [formFields]);

  const _updateTags = tags => {
    setTags(tags.map(tag => tag.toLowerCase()));
  };

  const markAsDirty = fieldName => {
    formFields[fieldName].isDirty = true;
    setFormFields({ ...formFields });
  };

  useEffect(() => {
    _resetStates();
  }, [isVisible]);

  const _updateFieldValue = (fieldName, value) => {
    formFields[fieldName].value = value;
    setFormFields({ ...formFields });
  };

  const makeAllFieldDirty = () => {
    Object.keys(formFields).forEach((fieldName, index) => {
      formFields[fieldName].isDirty = true;
    });
    setFormFields({ ...formFields });
  };

  const validateForm = () => {
    let isFormValid = true;
    Object.keys(formFields).forEach((fieldName, index) => {
      switch (fieldName) {
        case "title": {
          if (formFields.title.value.length >= 1) {
            formFields.title.isValid = true;
          } else {
            formFields.title.isValid = false;
            isFormValid = false;
          }
          break;
        }
        case "description": {
          if (formFields.description.value.length >= 1) {
            formFields.description.isValid = true;
          } else {
            formFields.description.isValid = false;
            isFormValid = false;
          }
          break;
        }
      }
    });
    setIsFormValid(isFormValid);
    setFormFields(formFields);
  };

  const _isSupportedFile = file => {
    const type = file.name.split(".").pop();
    if (["doc", "html", "docx", "xml", "pdf", "xlsx", "xls"].includes(type)) {
      return true;
    } else {
      return false;
    }
  };

  const _onFileChange = e => {
    if (e && e.target && e.target.files && e.target.files[0]) {
      if (_isSupportedFile(e.target.files[0])) {
        setFileError("");
        setFile(e.target.files[0]);
      } else {
        setFileError(getValueFromCurrentLang("dropBoxPage.uploadModal.fields.file.errorMessage.supportedFiles"));
        e.target.value = null;
      }
    }
  };

  const _uploadFile = async () => {
    makeAllFieldDirty();
    validateForm();
    if (isEditMode) {
      if (!isFormValid) {
        // Form not valid
        return;
      }
      // Update report
      // Check if new file available or not
      try {
        let filePath = null;
        let type = selectedFile.link.split(".").pop();
        if (file) {
          // New file available
          // Upload it
          props.showLoader("Uploading your file");
          filePath = await s3Upload(file);
          type = file.name.split(".").pop();
        } else {
          filePath = selectedFile.link;
        }
        props.showLoader("Updating report");
        // File uploaded
        // Construct the report params
        const report = {
          title: formFields.title.value,
          description: formFields.description.value,
          url: filePath,
          type
        };
        if (tags && tags.length) {
          report.tags = tags;
        } else {
          report.tags = [];
        }
        // Call endpoint
        await updateReport(selectedFile.id, report);
        // Successfully updated
        props.hideLoader();
        // Show toast
        showToast("Report updated successfully", "success");
        props.onUpload();
      } catch (error) {
        showToast("Report update failed", "error");
        console.log("error :>> ", error);
        props.onUpload();
      }
    } else {
      // New report create
      // First check if valid file is present
      if (!file) {
        setFileError(getValueFromCurrentLang("dropBoxPage.uploadModal.fields.file.errorMessage.provideValidFile"));
        return;
      } else {
        setFileError("");
      }
      // Valid file exists
      // Now check if form is valid
      if (!isFormValid) {
        // Form not valid
        return;
      }
      // Form is valid
      // Upload file
      let filePath = null;
      try {
        props.showLoader("Uploading your file");
        filePath = await s3Upload(file);
        props.showLoader("Creating report");
        // File uploaded
        // Construct the report params
        const report = {
          title: formFields.title.value,
          description: formFields.description.value,
          url: filePath,
          type: file.name.split(".").pop()
        };
        if (tags && tags.length) {
          report.tags = tags;
        } else {
          report.tags = [];
        }
        // Call endpoint
        await createReport(report);
        // Successfully updated
        props.hideLoader();
        // Show toast
        showToast("Report added successfully", "success");
        props.onUpload();
      } catch (error) {
        // If file is already uploaded, delete it from storage
        if (filePath) {
          await removeFileFromS3(filePath);
        }
        props.hideLoader();
        console.log("error :>> ", error);
        showToast(error.message, "error");
      }
    }
  };

  const _isFieldValid = fieldName => {
    return !(formFields[fieldName].isDirty && !formFields[fieldName].isValid);
  };

  const _fillFileData = () => {
    setFormFields({
      title: {
        value: selectedFile.title,
        isValid: false,
        isDirty: false
      },
      description: {
        value: selectedFile.description,
        isValid: false,
        isDirty: false
      }
    });
    setIsFormValid(true);
    setTags(selectedFile.tags || []);
    setFileError(null);
  };

  useEffect(() => {
    if (isEditMode) {
      _fillFileData();
    }
  }, [isEditMode]);

  return (
    <Modal isOpen={isVisible} toggle={dismissModal} size="md" centered={true} unmountOnClose={true}>
      <ModalHeader toggle={dismissModal}>
        {isEditMode ? "Update Report" : <LanguageParsedText keyString="dropBoxPage.uploadModal.header" />}
      </ModalHeader>
      <ModalBody>
        <div id="dropboxModalWrapper">
          <Container fluid="sm">
            <Row>
              <Col lg="12" className="inputWrapper">
                <div className="inputInnerWrapper">
                  <input type="file" onChange={_onFileChange} accept=".xls,.xlsx,.pdf,.doc,.docx,.xml,.html" />
                </div>
                <p className="errorText">{fileError && fileError.length ? fileError : ""}</p>
              </Col>
              <Col lg="12" className="inputWrapper">
                <InputField
                  placeholder={getValueFromCurrentLang("dropBoxPage.uploadModal.fields.title.placeHolder")}
                  type="text"
                  value={formFields.title.value}
                  onChange={e => _updateFieldValue("title", e.target.value)}
                  onBlur={() => markAsDirty("title")}
                />
                <p className="errorText">
                  {!_isFieldValid("title") &&
                    getValueFromCurrentLang("dropBoxPage.uploadModal.fields.title.errorMessage")}
                </p>
              </Col>
              <Col lg="12" className="inputWrapper">
                <div className="inputInnerWrapper">
                  <textarea
                    placeholder={getValueFromCurrentLang("dropBoxPage.uploadModal.fields.description.placeHolder")}
                    value={formFields.description.value}
                    onChange={e => _updateFieldValue("description", e.target.value)}
                    onBlur={() => markAsDirty("description")}
                  />
                </div>
                <p className="errorText">
                  {!_isFieldValid("description") &&
                    getValueFromCurrentLang("dropBoxPage.uploadModal.fields.description.errorMessage")}
                </p>
              </Col>
              <Col lg="12" className="inputWrapper">
                <p style={{ marginBottom: "-1rem" }}>
                  <LanguageParsedText keyString="dropBoxPage.uploadModal.fields.tags.label" />
                </p>
                <FilterChips
                  allOptions={topics}
                  canCreateNew={true}
                  placeholder={getValueFromCurrentLang("dropBoxPage.uploadModal.fields.tags.placeHolder")}
                  selectedOptions={tags}
                  onUpdate={_updateTags}
                />
              </Col>
            </Row>
          </Container>
          <div className="uploadBtnWrapper">
            <Button
              text={isEditMode ? "Update Report" : getValueFromCurrentLang("dropBoxPage.uploadModal.uploadButtonText")}
              className="uploadBtn"
              onClick={_uploadFile}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DropboxUploadModal;
