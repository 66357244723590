import { Auth } from "aws-amplify";

export const registerUser = async user => {
  try {
    const signUpResponse = await Auth.signUp(user);
    return signUpResponse;
  } catch (error) {
    console.log("error :>> ", error);
    throw error;
  }
};

export const loginUser = async (email, password) => {
  try {
    const signInResponse = await Auth.signIn(email, password);
    return signInResponse;
  } catch (error) {
    console.log("error :>> ", error);
    throw error;
  }
};

export const requestVerificationCode = async email => {
  try {
    const response = await Auth.forgotPassword(email);
    return response;
  } catch (error) {
    console.log("error :>> ", error);
    throw error;
  }
};

export const resetPassword = async (email, verificationCode, password) => {
  try {
    const response = await Auth.forgotPasswordSubmit(email, verificationCode, password);
    return response;
  } catch (error) {
    console.log("error :>> ", error);
    throw error;
  }
};

export const changePassword = async (oldPassword, newPassword) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const response = await Auth.changePassword(user, oldPassword, newPassword);
    return response;
  } catch (error) {
    console.log("error :>> ", error);
    throw error;
  }
};

export const updateUserData = async attributes => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const response = await Auth.updateUserAttributes(user, {
      updated_at: ((Date.now() / 1000) | 0).toString(),
      ...attributes
    });
    return response;
  } catch (error) {
    console.log("error :>> ", error);
    throw error;
  }
};

export const logoutUser = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log("error :>> ", error);
  }
};
