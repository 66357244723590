import React, { Component } from "react";
import { connect } from "react-redux";
import { hideLoader, showLoader } from "../../../../redux/actions/loader-data";
import "./resources-page.scss";
import { Jumbotron, Container, Row, Col } from "reactstrap";
import LanguageParsedText from "../../../../multi-lang/lang-parsed-text/lang-parsed-text";
import { sleepTime } from "../../../../helper-methods/index";

class ResourcesPage extends Component {
  async componentDidMount() {
    this.props.showLoader("Loading");
    await sleepTime(300);
    this.props.hideLoader();
  }

  render() {
    return (
      <div id="resourcePageWrapper">
        <Jumbotron>
          <Container fluid="sm">
            <Row xs="1" sm="2">
              <Col className={"jumbotronTextWrapper"}>
                <h1>
                  <LanguageParsedText keyString="aboutUsPage.jumbotron.bigHeading" />
                </h1>
                <p>
                  <LanguageParsedText keyString="aboutUsPage.jumbotron.smallHeading" />
                </p>
              </Col>
              <Col className={"landingImageWrapper"}></Col>
            </Row>
          </Container>
        </Jumbotron>
        <Container fluid="md" id="resourcesWrapper">
          <Row xs="1" md="1" lg="1" className="descriptionWrapper">
            <Col>
              <h1>Resources</h1>
              <p>
                Resources for conducting MDA research are plentiful and many of these are free. Below find a collection
                of common resources and tools used by C4ADS analysts in the course of their own maritime investigations.
              </p>
            </Col>
          </Row>
          <Row xs="1" md="1" lg="1">
            <Col className="listWrapper">
              <p></p>
              <h2>General Resources</h2>
              <h3>Corporate Registries</h3>
              <div className="linkWrapper">
                <h6>OpenCorporates</h6>
                <p>
                  <a href="https://opencorporates.com/" target="_blank">
                    https://opencorporates.com/
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Hong Kong Integrated Companies Registry Information System (ICRIS)</h6>
                <p>
                  <a href="https://www.icris.cr.gov.hk/" target="_blank">
                    https://www.icris.cr.gov.hk/
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>China National Enterprise Credit Information Publicity System</h6>
                <p>
                  <a href="http://www.gsxt.gov.cn" target="_blank">
                    http://www.gsxt.gov.cn
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Taiwan OpenGov (Aggregator)</h6>
                <p>
                  <a href="https://opengovtw.com" target="_blank">
                    https://opengovtw.com
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Taiwan Department of Commerce Business Database</h6>
                <p>
                  <a
                    href="https://findbiz.nat.gov.tw/fts/query/QueryBar/queryInit.do?request_locale=en"
                    target="_blank"
                  >
                    https://findbiz.nat.gov.tw/fts/query/QueryBar/queryInit.do?request_locale=en
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Singapore Accounting and Corporate Regulatory Authority (ACRA)</h6>
                <p>
                  <a href="https://www.acra.gov.sg/" target="_blank">
                    https://www.acra.gov.sg/
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Singapore QuestNet</h6>
                <p>
                  <a href="https://www.questnet.sg/" target="_blank">
                    https://www.questnet.sg/
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Companies Commission of Malaysia</h6>
                <p>
                  <a href="https://www.ssm.com.my/Pages/Quick_Link/e-Search.aspx" target="_blank">
                    https://www.ssm.com.my/Pages/Quick_Link/e-Search.aspx
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Laos National Enterprise Database</h6>
                <p>
                  <a href="http://www.erm.gov.la/index.php/en/" target="_blank">
                    http://www.erm.gov.la/index.php/en/
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Cambodia Business Database</h6>
                <p>
                  <a href="https://www.businessregistration.moc.gov.kh/searching/" target="_blank">
                    https://www.businessregistration.moc.gov.kh/searching/
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Thailand Data Warehouse</h6>
                <p>
                  <a href="https://datawarehouse.dbd.go.th/index" target="_blank">
                    https://datawarehouse.dbd.go.th/index
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Philippines Securities and Exchange Commission</h6>
                <p>
                  <a href="https://secexpress.ph/application-form" target="_blank">
                    https://secexpress.ph/application-form
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Brunei Ministry of Finance</h6>
                <p>
                  <a href="https://www.roc.gov.bn/" target="_blank">
                    https://www.roc.gov.bn/
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Vietnam Ministry of Planning and Investment Business Search</h6>
                <p>
                  <a href="https://dichvuthongtin.dkkd.gov.vn/inf/default.aspx" target="_blank">
                    https://dichvuthongtin.dkkd.gov.vn/inf/default.aspx
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Indonesia Ministry of Industry Company Search</h6>
                <p>
                  <a href="https://ahu.go.id/pencarian/profil-pt" target="_blank">
                    https://ahu.go.id/pencarian/profil-pt
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Myanmar Companies Online</h6>
                <p>
                  <a href="https://myco.dica.gov.mm/" target="_blank">
                    https://myco.dica.gov.mm/
                  </a>
                </p>
              </div>
              <h3>Network Mapping Tools</h3>
              {/* <h6>C4ADS Guide on UNSCR Sanctions on the DPRK (Separate PDF file sent)</h6> */}
              <div className="linkWrapper">
                <h6>Maltego (Community Edition)</h6>
                <p>
                  <a href="https://www.maltego.com/products/" target="_blank">
                    https://www.maltego.com/products/
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Analyst’s Notebook</h6>
                <p>
                  <a href="https://www.ibm.com/products/analysts-notebook/pricing" target="_blank">
                    https://www.ibm.com/products/analysts-notebook/pricing
                  </a>
                </p>
              </div>
              <h3>Global Watch Lists and Risk Screening Tools</h3>
              <div className="linkWrapper">
                <h6>CSI Watchdog</h6>
                <p>
                  <a
                    href="https://www.csiweb.com/how-we-help/risk-management-services/regulatory-compliance/denied-party-screening/global-watch-lists/"
                    target="_blank"
                  >
                    https://www.csiweb.com/how-we-help/risk-management-services/regulatory-compliance/denied-party-screening/global-watch-lists/
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>FactSet</h6>
                <p>
                  <a href="https://www.factset.com/products-data" target="_blank">
                    https://www.factset.com/products-data
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>D&B Credit</h6>
                <p>
                  <a href="https://www.dnb.com/products/finance-credit-risk/dnb-credit-products.html" target="_blank">
                    https://www.dnb.com/products/finance-credit-risk/dnb-credit-products.html
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>LexisNexis Compliance Solutions</h6>
                <p>
                  <a
                    href="https://internationalsales.lexisnexis.com/solutions/compliance/compliance-solutions"
                    target="_blank"
                  >
                    https://internationalsales.lexisnexis.com/solutions/compliance/compliance-solutions
                  </a>
                </p>
              </div>
              <h3>Media Monitoring and Derogatory Searches</h3>
              <div className="linkWrapper">
                <h6>LexisNexis Media Monitoring</h6>
                <p>
                  <a href="https://www.lexisnexis.com/en-us/products/newsdesk.page" target="_blank">
                    https://www.lexisnexis.com/en-us/products/newsdesk.page
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Aleph (created by OCCRP)</h6>
                <p>
                  <a href="https://aleph.occrp.org/" target="_blank">
                    https://aleph.occrp.org/
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Sayari Labs</h6>
                <p>
                  <a href="https://sayari.com/solutions/" target="_blank">
                    https://sayari.com/solutions/
                  </a>
                </p>
              </div>
              <h3>AIS Tracking Platforms</h3>
              <div className="linkWrapper">
                <h6>Windward</h6>
                <p>
                  <a href="https://wnwd.com/" target="_blank">
                    https://wnwd.com/
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>MarineTraffic</h6>
                <p>
                  <a href="https://www.marinetraffic.com/" target="_blank">
                    https://www.marinetraffic.com/
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Polestar</h6>
                <p>
                  <a href="https://www.polestarglobal.com/" target="_blank">
                    https://www.polestarglobal.com/
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>IHS Markit AISLive Ship Tracking</h6>
                <p>
                  <a
                    href="https://maritime.ihs.com/EntitlementPortal/Home/Information/AISLive_Vessel_Tracking"
                    target="_blank"
                  >
                    https://maritime.ihs.com/EntitlementPortal/Home/Information/AISLive_Vessel_Tracking
                  </a>
                </p>
              </div>
              <h3>Commercial Satellite Imagery Providers</h3>
              <div className="linkWrapper">
                <h6>Planet Labs</h6>
                <p>
                  <a href="https://www.planet.com/" target="_blank">
                    https://www.planet.com/
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Maxar</h6>
                <p>
                  <a href="https://www.maxar.com/products/satellite-imagery" target="_blank">
                    https://www.maxar.com/products/satellite-imagery
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Blacksky</h6>
                <p>
                  <a href="https://www.blacksky.com/products-services/" target="_blank">
                    https://www.blacksky.com/products-services/
                  </a>
                </p>
              </div>
              <p></p>
              <h2>Resources on DPRK Sanctions Evasion</h2>
              <div className="linkWrapper">
                <h6>C4ADS</h6>
                <p>
                  <a href="https://c4ads.org" target="_blank">
                    https://c4ads.org
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>RUSI</h6>
                <p>
                  <a href="https://rusi.org/" target="_blank">
                    https://rusi.org/
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>CNAS</h6>
                <p>
                  <a href="https://www.cnas.org/" target="_blank">
                    https://www.cnas.org/
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>CNS James Martin Center for Nonproliferation Studies</h6>
                <p>
                  <a href="https://www.nonproliferation.org/" target="_blank">
                    https://www.nonproliferation.org/
                  </a>
                </p>
              </div>
              <p></p>

              <h2>Resources on IUU Fishing</h2>
              <div className="linkWrapper">
                <h6>
                  International Plan of Action to Prevent, Deter and Eliminate Illegal, Unreported and Unregulated
                  Fishing (Food and Agriculture Organization of the United Nations)
                </h6>
                <p>
                  <a href="http://www.fao.org/3/y1224e/Y1224E.pdf" target="_blank">
                    http://www.fao.org/3/y1224e/Y1224E.pdf
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>
                  Agreement to Promote Compliance with International Conservation and Management Measures by Fishing
                  Vessels on the High Seas (United Nations)
                </h6>
                <p>
                  <a href="http://www.fao.org/3/X3130M/x3130m.pdf" target="_blank">
                    http://www.fao.org/3/X3130M/x3130m.pdf
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>
                  The United Nations Agreement for the Implementation of the Provisions of the United Nations Convention
                  on the Law of the Sea of 10 December 1982 relating to the Conservation and Management of Straddling
                  Fish Stocks and Highly Migratory Fish Stocks (United Nations)
                </h6>
                <p>
                  <a
                    href="https://www.un.org/Depts/los/convention_agreements/convention_overview_fish_stocks.htm"
                    target="_blank"
                  >
                    https://www.un.org/Depts/los/convention_agreements/convention_overview_fish_stocks.htm
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Code of Conduct for Responsible Fisheries (United Nations)</h6>
                <p>
                  <a
                    href="http://www.fao.org/publications/card/en/c/e6cf549d-589a-5281-ac13-766603db9c03"
                    target="_blank"
                  >
                    http://www.fao.org/publications/card/en/c/e6cf549d-589a-5281-ac13-766603db9c03
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>
                  Agreement on Port State Measures to Prevent, Deter and Eliminate Illegal, Unreported and Unregulated
                  Fishing (United Nations)
                </h6>
                <p>
                  <a href="http://www.fao.org/3/i5469t/I5469T.pdf" target="_blank">
                    http://www.fao.org/3/i5469t/I5469T.pdf
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>
                  Illegal, Unreported and Unregulated (IUU) fishing: Regional Mechanisms (Food and Agriculture
                  Organization of the United Nations)
                </h6>
                <p>
                  <a href="http://www.fao.org/iuu-fishing/regional-mechanisms/en/" target="_blank">
                    http://www.fao.org/iuu-fishing/regional-mechanisms/en/
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Flag State Responsibilities (Food and Agriculture Organization of the United Nations)</h6>
                <p>
                  <a href="http://www.fao.org/3/y3536e07.htm#bm07" target="_blank">
                    http://www.fao.org/3/y3536e07.htm#bm07
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>
                  Strings Attached: Exploring the Onshore Networks Behind Illegal, Unreported, and Unregulated Fishing
                  (C4ADS)
                </h6>
                <p>
                  <a href="https://www.c4reports.org/strings-attached" target="_blank">
                    https://www.c4reports.org/strings-attached
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Shining a Light: The Need for Transparency across Distant Water Fishing (Stimson Center)</h6>
                <p>
                  <a
                    href="https://www.stimson.org/wp-content/files/file-attachments/Stimson%20Distant%20Water%20Fishing%20Report.pdf"
                    target="_blank"
                  >
                    https://www.stimson.org/wp-content/files/file-attachments/Stimson%20Distant%20Water%20Fishing%20Report.pdf
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>World Ocean Review 2: Illegal Fishing (World Ocean Review)</h6>
                <p>
                  <a href="https://worldoceanreview.com/en/wor-2/fisheries/illegal-fishing/" target="_blank">
                    https://worldoceanreview.com/en/wor-2/fisheries/illegal-fishing/
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>
                  The State of World Fisheries and Aquaculture (Food and Agriculture Organization of the United Nations)
                </h6>
                <p>
                  <a href="http://www.fao.org/3/ca9229en/CA9229EN.pdf" target="_blank">
                    http://www.fao.org/3/ca9229en/CA9229EN.pdf
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>
                  Fisheries Crime: Transnational Organized Criminal Activities in the Context of the Fisheries Sector
                  (United Nations)
                </h6>
                <p>
                  <a
                    href="https://www.unodc.org/documents/about-unodc/Campaigns/Fisheries/focus_sheet_PRINT.pdf"
                    target="_blank"
                  >
                    https://www.unodc.org/documents/about-unodc/Campaigns/Fisheries/focus_sheet_PRINT.pdf
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>
                  Implementation of The IPOA-IUU Through RFMOS (Food and Agriculture Organization of the United Nations)
                </h6>
                <p>
                  <a href="http://www.fao.org/3/y3536e0b.htm#bm11" target="_blank">
                    http://www.fao.org/3/y3536e0b.htm#bm11
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>
                  Fisheries Transparency Initiative: Responsible Fisheries Through Transparency and Participation
                  (Fisheries Transparency Initiative)
                </h6>
                <p>
                  <a
                    href="http://fisheriestransparency.org/wp-content/uploads/2018/09/FiTI_AtaGlance_EN_20180925.pdf"
                    target="_blank"
                  >
                    http://fisheriestransparency.org/wp-content/uploads/2018/09/FiTI_AtaGlance_EN_20180925.pdf
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>
                  Coordinating Working Party on Fishery Statistics: Logbook and VMS (Food and Agriculture Organization
                  of the United Nations)
                </h6>
                <p>
                  <a
                    href="http://www.fao.org/cwp-on-fishery-statistics/handbook/capture-fisheries-statistics/logbooks-and-vms/en/"
                    target="_blank"
                  >
                    http://www.fao.org/cwp-on-fishery-statistics/handbook/capture-fisheries-statistics/logbooks-and-vms/en/
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Illegal, Unreported and Unregulated Fishing and Associated Drivers (World Resources Institute)</h6>
                <p>
                  <a
                    href="https://oceanpanel.org/sites/default/files/2020-02/HLP%20Blue%20Paper%20on%20IUU%20Fishing%20and%20Associated%20Drivers.pdf"
                    target="_blank"
                  >
                    https://oceanpanel.org/sites/default/files/2020-02/HLP%20Blue%20Paper%20on%20IUU%20Fishing%20and%20Associated%20Drivers.pdf
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>C029 - Forced Labour Convention, 1930 (No. 29) (International Labour Organization)</h6>
                <p>
                  <a
                    href="https://www.ilo.org/dyn/normlex/en/f?p=NORMLEXPUB:12100:0::NO::P12100_ILO_CODE:C029"
                    target="_blank"
                  >
                    https://www.ilo.org/dyn/normlex/en/f?p=NORMLEXPUB:12100:0::NO::P12100_ILO_CODE:C029
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>C188 - Work in Fishing Convention, 2007 (No. 188) (International Labour Organization)</h6>
                <p>
                  <a
                    href="https://www.ilo.org/dyn/normlex/en/f?p=NORMLEXPUB:12100:0::NO::P12100_ILO_CODE:C188"
                    target="_blank"
                  >
                    https://www.ilo.org/dyn/normlex/en/f?p=NORMLEXPUB:12100:0::NO::P12100_ILO_CODE:C188
                  </a>
                </p>
              </div>
              <p></p>
              <h2>Resources on Maritime Disputes and Gray Zone Activities</h2>
              <div className="linkWrapper">
                <h6>Countering Coercion in Maritime Asia: The Theory and Practice of Gray Zone Deterrence (CSIS)</h6>
                <p>
                  <a
                    href="https://csis-website-prod.s3.amazonaws.com/s3fs-public/publication/170505_GreenM_CounteringCoercionAsia_Web.pdf"
                    target="_blank"
                  >
                    https://csis-website-prod.s3.amazonaws.com/s3fs-public/publication/170505_GreenM_CounteringCoercionAsia_Web.pdf
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>
                  Gaining Competitive Advantage in the Gray Zone: Response Options for Coercive Aggression Below the
                  Threshold of Major War
                </h6>
                <p>
                  <a
                    href="https://www.rand.org/content/dam/rand/pubs/research_reports/RR2900/RR2942/RAND_RR2942.pdf"
                    target="_blank"
                  >
                    https://www.rand.org/content/dam/rand/pubs/research_reports/RR2900/RR2942/RAND_RR2942.pdf
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>
                  Military and Security Developments Involving the People’s Republic of China 2019 (Department of
                  Defense)
                </h6>
                <p>
                  <a
                    href="https://media.defense.gov/2019/May/02/2002127082/-1/-1/1/2019_CHINA_MILITARY_POWER_REPORT.pdf"
                    target="_blank"
                  >
                    https://media.defense.gov/2019/May/02/2002127082/-1/-1/1/2019_CHINA_MILITARY_POWER_REPORT.pdf
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Becoming a Great “Maritime Power”: A Chinese Dream</h6>
                <p>
                  <a href="https://www.cna.org/cna_files/pdf/IRM-2016-U-013646.pdf" target="_blank">
                    https://www.cna.org/cna_files/pdf/IRM-2016-U-013646.pdf
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>China’s Maritime Militia (CNA)</h6>
                <p>
                  <a href="https://www.cna.org/cna_files/pdf/Chinas-Maritime-Militia.pdf" target="_blank">
                    https://www.cna.org/cna_files/pdf/Chinas-Maritime-Militia.pdf
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>South China Sea Territorial Disputes (Peace Palace Library)</h6>
                <p>
                  <a
                    href="https://www.peacepalacelibrary.nl/library-special/south-china-sea-territorial-disputes/"
                    target="_blank"
                  >
                    https://www.peacepalacelibrary.nl/library-special/south-china-sea-territorial-disputes/
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>
                  U.S.-China Strategic Competition in South and East China Seas: Background and Issues for Congress
                </h6>
                <p>
                  <a href="https://fas.org/sgp/crs/row/R42784.pdf" target="_blank">
                    https://fas.org/sgp/crs/row/R42784.pdf
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>A Blueprint for a South China Sea Code of Conduct (CSIS)</h6>
                <p>
                  <a href="https://amti.csis.org/blueprint-for-south-china-sea-code-of-conduct/" target="_blank">
                    https://amti.csis.org/blueprint-for-south-china-sea-code-of-conduct/
                  </a>
                </p>
              </div>
              <h2></h2>
              <h2>Resources on Piracy and Maritime Trafficking</h2>
              <div className="linkWrapper">
                <h6>Transnational Organized Crime in East Asia and the Pacific: A Threat Assessment (UNODC)</h6>
                <p>
                  <a
                    href="https://www.unodc.org/res/cld/bibliography/transnational-organized-crime-in-east-asia-and-the-pacific-a-threat-assessment_html/TOCTA_EAP_web.pdf"
                    target="_blank"
                  >
                    https://www.unodc.org/res/cld/bibliography/transnational-organized-crime-in-east-asia-and-the-pacific-a-threat-assessment_html/TOCTA_EAP_web.pdf
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Transnational Organized Crime in Southeast Asia: Evolution, Growth, and Impact (UNODC)</h6>
                <p>
                  <a
                    href="https://www.unodc.org/documents/southeastasiaandpacific/Publications/2019/SEA_TOCTA_2019_web.pdf"
                    target="_blank"
                  >
                    https://www.unodc.org/documents/southeastasiaandpacific/Publications/2019/SEA_TOCTA_2019_web.pdf
                  </a>
                </p>
              </div>
              <div className="linkWrapper">
                <h6>Contemporary Asian Drug Policy: Insights and Opportunities for Change (RAND)</h6>
                <p>
                  <a
                    href="https://www.rand.org/content/dam/rand/pubs/research_reports/RR2700/RR2733/RAND_RR2733.pdf"
                    target="_blank"
                  >
                    https://www.rand.org/content/dam/rand/pubs/research_reports/RR2700/RR2733/RAND_RR2733.pdf
                  </a>
                </p>
              </div>
              {/* End of new updated Resources content */}

              {/* <div className="linkWrapper">
                <h6>Code of Conduct for Responsible Fisheries (United Nations)</h6>
                <p>
                  <a
                    href="http://www.fao.org/publications/card/en/c/e6cf549d-589a-5281-ac13-766603db9c03"
                    target="_blank"
                  ></a>
                </p>
              </div>

              <div className="linkWrapper">
                <a
                  href="https://www.treasury.gov/resource-center/sanctions/Programs/Documents/05142020_global_advisory_v1.pdf"
                  target="_blank"
                >
                  <h6>Guidance to Address Illicit Shipping and Sanctions Evasions Practices (May 2020) </h6>
                </a>
              </div>
              <h3>Network Mapping Tools</h3>
              <div className="linkWrapper">
                <a href="https://www.maltego.com/products/" target="_blank">
                  <h6>Maltego (Community Edition)</h6>
                </a>
              </div>
              <div className="linkWrapper">
                <a href="https://www.ibm.com/products/analysts-notebook/pricing" target="_blank">
                  <h6>Analyst’s Notebook</h6>
                </a>
              </div>
              <h3>Global Watch Lists and Risk Screening Tools</h3>
              <div className="linkWrapper">
                <a
                  href="https://www.csiweb.com/how-we-help/risk-management-services/regulatory-compliance/denied-party-screening/global-watch-lists/"
                  target="_blank"
                >
                  <h6>CSI Watchdog</h6>
                </a>
              </div>
              <div className="linkWrapper">
                <a href="https://www.factset.com/products-data" target="_blank">
                  <h6>FactSet</h6>
                </a>
              </div>
              <div className="linkWrapper">
                <a href="https://www.dnb.com/products/finance-credit-risk/dnb-credit-products.html" target="_blank">
                  <h6>D&B Credit</h6>
                </a>
              </div>
              <div className="linkWrapper">
                <a
                  href="https://internationalsales.lexisnexis.com/solutions/compliance/compliance-solutions"
                  target="_blank"
                >
                  <h6>LexisNexis Compliance Solutions</h6>
                </a>
              </div>
              <h3>Media and Derogatory Searches</h3>
              <div className="linkWrapper">
                <a href="https://www.lexisnexis.com/en-us/products/newsdesk.page" target="_blank">
                  <h6>LexisNexis Media Monitoring</h6>
                </a>
              </div>
              <div className="linkWrapper">
                <a href="https://aleph.occrp.org/" target="_blank">
                  <h6>Aleph (created by OCCRP)</h6>
                </a>
              </div>
              <div className="linkWrapper">
                <a href="https://sayari.com/solutions/" target="_blank">
                  <h6>Sayari Labs</h6>
                </a>
              </div>

              <h3>Media Reporting on the DPRK</h3>
              <div className="linkWrapper">
                <a href="https://c4ads.org" target="_blank">
                  <h6>C4ADS</h6>
                </a>
              </div>
              <div className="linkWrapper">
                <a href="https://rusi.org/" target="_blank">
                  <h6>RUSI</h6>
                </a>
              </div>
              <div className="linkWrapper">
                <a href="https://www.cnas.org/" target="_blank">
                  <h6>CNAS</h6>
                </a>
              </div>
              <div className="linkWrapper">
                <a href="https://www.nonproliferation.org/" target="_blank">
                  <h6>CNS James Martin Center for Nonproliferation Studies</h6>
                </a>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToDispatch = dispatch => {
  return {
    showLoader: loaderText => dispatch(showLoader(loaderText)),
    hideLoader: () => dispatch(hideLoader())
  };
};

const mapStateToProps = state => {
  return {
    userData: state.userData
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(ResourcesPage);
