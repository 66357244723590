import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from "react-toasts";
import { PersistGate } from "redux-persist/integration/react";
import "./languages/index.js";
import Modules from "./modules";
import LanguageSelectorModal from "./modules/general/components/language-selector-modal/language-selector-modal";
import FullPageLoader from "./modules/general/containers/full-page-loader";
import { persistor, store } from "./redux/store";
import Amplify from "aws-amplify";
import { AWS_COGNITO, S3_CONFIGS, API_ENDPOINTS } from "./config/index";
import ScrollToTop from "./modules/general/containers/scroll-to-top/scroll-to-top.jsx";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: AWS_COGNITO.REGION,
    userPoolId: AWS_COGNITO.USER_POOL_ID,
    userPoolWebClientId: AWS_COGNITO.APP_CLIENT_ID,
    identityPoolId: AWS_COGNITO.IDENTITY_POOL_ID
  },
  Storage: {
    region: S3_CONFIGS.REGION,
    bucket: S3_CONFIGS.BUCKET,
    identityPoolId: S3_CONFIGS.IDENTITY_POOL_ID
  },
  API: {
    endpoints: API_ENDPOINTS
  }
});

// Only for production
console.log = () => {};
console.warn = () => {};
console.error = () => {};

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<div></div>} persistor={persistor}>
        <Router>
          <ScrollToTop />
          <div>
            <LanguageSelectorModal />
            <FullPageLoader></FullPageLoader>
            <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_RIGHT} />
            <Modules />
          </div>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
