import React, { Component } from "react";
import { Jumbotron, Container, Row, Col } from "reactstrap";
import * as images from "../../../../assets/images";
import style from "./about-us-page.module.scss";
import LanguageParsedText from "../../../../multi-lang/lang-parsed-text/lang-parsed-text";
import { showLoader, hideLoader } from "../../../../redux/actions/loader-data";
import { connect } from "react-redux";
import { sleepTime } from "../../../../helper-methods";
import Button from "../../../general/components/button/button";

class AboutUsPage extends Component {
  state = {};

  async componentDidMount() {
    this.props.showLoader("Loading");
    await sleepTime(1200);
    this.props.hideLoader();
  }

  render() {
    return (
      <React.Fragment>
        <main className={style.main}>
          <Jumbotron>
            <Container fluid="sm" className={style.jumbotron}>
              <Row xs="1" sm="2">
                <Col className={style.jumbotronTextWrapper}>
                  <h1>
                    <LanguageParsedText keyString="aboutUsPage.jumbotron.bigHeading" />
                  </h1>
                  <p>
                    <LanguageParsedText keyString="aboutUsPage.jumbotron.smallHeading" />
                  </p>
                </Col>
                <Col className={style.landingImageWrapper}></Col>
              </Row>
            </Container>
          </Jumbotron>
          {/* About us section (start) */}
          <Container fluid="sm" id={style.aboutUsWrapper}>
            <Row xs="1">
              <Col>
                <h1>
                  <LanguageParsedText keyString="aboutUsPage.aboutUs.heading" />
                </h1>
              </Col>
            </Row>
            <p>
              C4ADS is a digital-age think tank dedicated to providing data-driven analysis and evidence-based reporting
              on global conflict and transnational security issues. We use publicly available data and cutting-edge
              technologies to track and disrupt illicit activity and networks of bad actors, with a focus on issues
              ranging from wildlife trafficking to nuclear proliferation to conflict financing.
            </p>
            <p>
              We seek to move beyond advocacy into action by engaging with partner organizations from around the world
              to create actionable outcomes and solutions. In doing so, we fill a critical gap left by traditional
              public sector and profit-driven institutions.
            </p>
            <p>
              Read more about us at{" "}
              <a href="https://c4ads.org/about-us" target="_blank">
                our website
              </a>
              , and our{" "}
              <a
                href="https://static1.squarespace.com/static/566ef8b4d8af107232d5358a/t/5f07337c2b31722e0c51358a/1594307479873/C4ADS+Annual+Report+2019_2020.pdf"
                target="_blank"
              >
                annual report
              </a>
              .
            </p>
            {/* <Row sm="1" xs="1" className={style.aboutUsFullText}>
              <Col className={style.textPart}>
                <p>
                <LanguageParsedText keyString="aboutUsPage.aboutUs.block1" />
                </p>
              </Col>
            </Row>
            <Row sm="1" xs="1" className={style.aboutUsFullText}>
              <Col className={style.textPart}>
                <p>
                <LanguageParsedText keyString="aboutUsPage.aboutUs.block2" />
                </p>
              </Col>
            </Row>
            <Row sm="1" xs="1" className={style.aboutUsFullText}>
              <Col className={style.textPart}>
                <p>
                <LanguageParsedText keyString="aboutUsPage.aboutUs.block3" />
                </p>
              </Col>
            </Row>
            <Row sm="1" xs="1" className={style.aboutUsFullText}>
              <Col className={style.textPart}>
                <p>
                <LanguageParsedText keyString="aboutUsPage.aboutUs.block4" />
                </p>
              </Col>
            </Row>
            <Row sm="1" xs="1" className={style.aboutUsFullText}>
              <Col className={style.textPart}>
                <p>
                <LanguageParsedText keyString="aboutUsPage.aboutUs.block5" />
                </p>
              </Col>
            </Row> */}
          </Container>
          {/* About us section (end) */}
          {/* Material section (start) */}
          {/* <Container id={style.specialistPanel}>
            <Row xs="1">
              <Col>
                <h1><LanguageParsedText keyString="aboutUsPage.specialistPanel.heading" /></h1>
              </Col>
              <Col id={style.aboutUsTextWrapper}>
                <p>
                <LanguageParsedText keyString="aboutUsPage.specialistPanel.subHeading" />
                </p>
              </Col>
            </Row>
            <Row sm="1" xs="1" lg="2">
              <Col className={style.specialistWRapper}>
                <div className={style.specialist}>
                  <div className={style.photoPartWrapper}>
                    <div className={style.photoPart}>
                      <img alt="" src={images.defaultUserThumbnail} />
                    </div>
                  </div>
                  <div className={style.detailsPart}>
                    <h2><LanguageParsedText keyString="aboutUsPage.specialistPanel.specialists.chadStinson.name" /></h2>
                    <p><LanguageParsedText keyString="aboutUsPage.specialistPanel.specialists.chadStinson.title" /></p>
                    <p><LanguageParsedText keyString="aboutUsPage.specialistPanel.specialists.chadStinson.detail" /></p>
                  </div>
                </div>
              </Col>
              <Col className={style.specialistWRapper}>
                <div className={style.specialist}>
                  <div className={style.photoPartWrapper}>
                    <div className={style.photoPart}>
                      <img alt="" src={images.defaultUserThumbnail} />
                    </div>
                  </div>
                  <div className={style.detailsPart}>
                  <h2><LanguageParsedText keyString="aboutUsPage.specialistPanel.specialists.jasonNicks.name" /></h2>
                    <p><LanguageParsedText keyString="aboutUsPage.specialistPanel.specialists.jasonNicks.title" /></p>
                    <p><LanguageParsedText keyString="aboutUsPage.specialistPanel.specialists.jasonNicks.detail" /></p>
                  </div>
                </div>
              </Col>
              <Col className={style.specialistWRapper}>
                <div className={style.specialist}>
                  <div className={style.photoPartWrapper}>
                    <div className={style.photoPart}>
                      <img alt="" src={images.defaultUserThumbnail} />
                    </div>
                  </div>
                  <div className={style.detailsPart}>
                  <h2><LanguageParsedText keyString="aboutUsPage.specialistPanel.specialists.stasyWinter.name" /></h2>
                    <p><LanguageParsedText keyString="aboutUsPage.specialistPanel.specialists.stasyWinter.title" /></p>
                    <p><LanguageParsedText keyString="aboutUsPage.specialistPanel.specialists.stasyWinter.detail" /></p>
                  </div>
                </div>
              </Col>
              <Col className={style.specialistWRapper}>
                <div className={style.specialist}>
                  <div className={style.photoPartWrapper}>
                    <div className={style.photoPart}>
                      <img alt="" src={images.defaultUserThumbnail} />
                    </div>
                  </div>
                  <div className={style.detailsPart}>
                  <h2><LanguageParsedText keyString="aboutUsPage.specialistPanel.specialists.benWallace.name" /></h2>
                    <p><LanguageParsedText keyString="aboutUsPage.specialistPanel.specialists.benWallace.title" /></p>
                    <p><LanguageParsedText keyString="aboutUsPage.specialistPanel.specialists.benWallace.detail" /></p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row sm="1" xs="1" lg="1">
              <Col>
                <Button className={style.button} onClick={this._updateData} text={<LanguageParsedText keyString="aboutUsPage.specialistPanel.seeAllButtin" />} />
              </Col>
            </Row>
          </Container> */}
          {/* Material section (end) */}
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToDispatch = dispatch => {
  return {
    showLoader: loaderText => dispatch(showLoader(loaderText)),
    hideLoader: () => dispatch(hideLoader())
  };
};

export default connect(null, mapStateToDispatch)(AboutUsPage);
