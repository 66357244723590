import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import * as images from "../../../../assets/images";
import LanguageParsedText from "../../../../multi-lang/lang-parsed-text/lang-parsed-text";
import style from "./footer.module.scss";
import { scrollToTop } from "../../../../helper-methods";

const Footer = props => {
  return (
    <Container fluid={true} id={style.footerOuterWrapper}>
      <Row xs="1">
        <Col>
          <div id={style.footerUpperPart}>
            <div>
              {/* <div className={style.footerLogoWrapper}> */}
              <div className={style.footerLogoWrapper} onClick={() => props.history.push("/")}>
                <img alt="" src={require("../../../../assets/images/files/Pharos Logo Final_Horizontal Black.svg")} />
              </div>
              <div id={style.backToTopWrapper} onClick={() => scrollToTop()}>
                <p>
                  <LanguageParsedText keyString="home.footer.backToTop" /> &nbsp; <i className="fas fa-chevron-up"></i>
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col id={style.footerLowerPartContainer}>
          <div id={style.footerLowerPart}>
            <div>
              <p>
                <LanguageParsedText keyString="home.footer.copyrightText" />
              </p>
            </div>
            <div id={style.footerLowerPartLinks}>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
              {/* Old placeholders, using language support we're no longer using */}
              {/* <p>
                <LanguageParsedText keyString="home.footer.link1" />
              </p>
              <p>
                <LanguageParsedText keyString="home.footer.link2" />
              </p> */}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    userData: state.userData
  };
};

export default withRouter(connect(mapStateToProps, null)(Footer));
