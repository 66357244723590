import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ProtectedRoute from "../general/components/protected-routes";
import UserSettingsPage from "./pages/user-settings-page/user-settings-page";
import HomePage from "./pages/home-page/home-page";
import Footer from "./containers/footer/footer";
import Header from "./containers/header/header";
import CurriculumPage from "./pages/curriculum-page/curriculum-page";
import DropBoxPage from "./pages/dropbox-page/dropbox-page";
import { Auth } from "aws-amplify";
import { logout } from "../../helper-methods/index";
import AboutUsPage from './pages/about-us-page/about-us-page';
import ResourcesPage from './pages/resources-page/resources-page';

class PrivateModule extends Component {
  state = {};
  sessionRefreshIntervalRef = null

  componentDidMount() {
    this._enableSessionRefresher();
  }
   
  componentWillUnmount() {
    this._disableSessionRefresher();
  }

  _enableSessionRefresher = () => {
    // Do an immediate refresh
    this._refreshUserSession();
    // Then schedule it for every next hour
    this.sessionRefreshIntervalRef = setInterval(() => {
      this._refreshUserSession();
    }, 3600000)
  }

  _disableSessionRefresher = () => {
    if (this.sessionRefreshIntervalRef) {
      clearInterval(this.sessionRefreshIntervalRef);
    }
  }

  _refreshUserSession = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
          // Session refreshed
          resolve();
        });
      } catch (e) {
        // Unable to refresh token
        // signout
        logout(this.props.history);
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <Header {...this.props} />
        <Switch>
          <ProtectedRoute path="/home" component={HomePage} redirectRoute="/auth" />
          <ProtectedRoute path="/user-settings" component={UserSettingsPage} redirectRoute="/auth" />
          <ProtectedRoute path="/curriculum" component={CurriculumPage} redirectRoute="/auth" />
          <ProtectedRoute path="/about-us" component={AboutUsPage} redirectRoute="/auth" />
          <ProtectedRoute path="/resources" component={ResourcesPage} redirectRoute="/auth" />
          <ProtectedRoute
            path="/insights"
            component={DropBoxPage}
            redirectRoute="/auth"
            allowedUserGroups={["Private", "Admin"]}
          />
          <Route path="*" render={() => <Redirect to="/home" />} />
        </Switch>
        <Footer />
      </React.Fragment>
    );
  }
}

export default PrivateModule;
