import React from "react";
import MultiLang from "..";
import { connect } from "react-redux";

const LanguageParsedText = props => {
  let finalString = "";
  // Get current key string
  const { keyString } = props;
  // Prepare dynamic variables
  let dynamicVariablesToBind = { ...props };
  // Delete keyString as it was in props
  delete dynamicVariablesToBind.keyString;
  delete dynamicVariablesToBind.parseHtml;
  const multiLang = new MultiLang();
  finalString = multiLang.getValueFromCurrentLang(keyString, dynamicVariablesToBind);
  if (props.parseHtml) {
    return <span dangerouslySetInnerHTML={{ __html: finalString }}></span>;
  } else {
    return <>{finalString}</>;
  }
};

const mapStateToProps = state => {
  return {
    languageData: state.languageData
  };
};

export default connect(mapStateToProps, null)(LanguageParsedText);

export const getValueFromCurrentLang = (keyString, dynamicVariables = {}) => {
  const multiLang = new MultiLang();
  return multiLang.getValueFromCurrentLang(keyString, dynamicVariables);
};
