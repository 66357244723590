import React, { useState } from "react";
import "./dropbox-table.scss";
import * as images from "../../../../assets/images";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroller";

import { Table } from "reactstrap";
import LanguageParsedText from '../../../../multi-lang/lang-parsed-text/lang-parsed-text';

const DropboxTable = props => {
  const { files = [], selectedFile } = props;
  const [sortOptions, updateSortOptions] = useState({
    fieldName: "name",
    order: "asc"
  });

  const _getIcon = file => {
    switch (_getFileType(file)) {
      case "DOC": {
        return images.docIcon;
      }
      case "DOCX": {
        return images.docIcon;
      }
      case "HTML": {
        return images.xmlIcon;
      }
      case "XML": {
        return images.xmlIcon;
      }
      case "XLSX": {
        return images.xlsIcon;
      }
      case "XLS": {
        return images.xlsIcon;
      }
      case "PDF": {
        return images.pdfIcon;
      }
      default: {
        return images.pdfIcon;
      }
    }
  };

  const _getFileType = file => {
    // Get file extension
    const fileParts = file.link.split(".");
    const extension = fileParts[fileParts.length - 1];
    return extension.toUpperCase();
  };

  const _parseDate = timestamp => {
    return moment(timestamp).format("ddd, MMM DD YYYY");
  };

  const _getRowClass = file => {
    if (selectedFile && file.fileId === selectedFile.fileId) {
      return "selectedFile";
    }
    return "";
  };

  const _sortBy = (fieldName, order) => {
    updateSortOptions({ fieldName, order });
  };

  const _toggleSortOrder = fieldName => {
    if (sortOptions.fieldName === fieldName) {
      let sortOrder = sortOptions.order === "asc" ? "desc" : "asc";
      updateSortOptions({ fieldName, order: sortOrder });
    } else {
      updateSortOptions({ fieldName, order: "asc" });
    }
  };

  const _isSortOptionSelected = (fieldName, order) => {
    if (sortOptions) {
      if (fieldName === sortOptions.fieldName && order === sortOptions.order) {
        return true;
      }
    }
  };

  // Sort
  let sortedfiles = [];
  let sortFlag = sortOptions.order === "asc" ? 1 : -1;
  switch (sortOptions.fieldName) {
    case "name": {
      sortedfiles = files.sort((row1, row2) => {
        if (row1.link < row2.link) return -sortFlag;
        if (row1.link > row2.link) return sortFlag;
        return 0;
      });
    }
    case "type": {
      sortedfiles = files.sort((row1, row2) => {
        if (_getFileType(row1) < _getFileType(row2)) return -sortFlag;
        if (_getFileType(row1) > _getFileType(row2)) return sortFlag;
        return 0;
      });
    }
    case "modified": {
      sortedfiles = files.sort((row1, row2) => {
        if (row1.modifiedAt < row2.modifiedAt) return -sortFlag;
        if (row1.modifiedAt > row2.modifiedAt) return sortFlag;
        return 0;
      });
    }
  }

  return (
    <div id="dropboxTableWrapper">
      <InfiniteScroll pageStart={0} loadMore={() => {}} hasMore={false} threshold={250}>
        <Table hover>
          <thead className="tableHead">
            <tr>
              <th>
                {" "}
                <div className="tableHeadCellWrapper" onClick={e => _toggleSortOrder("name")}>
                <LanguageParsedText keyString="dropBoxPage.table.header1" />
                  <div className="sortIconsWrapper">
                    {" "}
                    <div className={"topArrow " + (_isSortOptionSelected("name", "desc") ? "selected" : "")}>
                      <i className="fas fa-caret-up"></i>
                    </div>{" "}
                    <div className={"bottomArrow " + (_isSortOptionSelected("name", "asc") ? "selected" : "")}>
                      <i className="fas fa-caret-down"></i>
                    </div>{" "}
                  </div>{" "}
                </div>
              </th>
              <th>
                <div className="tableHeadCellWrapper" onClick={e => _toggleSortOrder("type")}>
                <LanguageParsedText keyString="dropBoxPage.table.header2" />{" "}
                  <div className="sortIconsWrapper">
                    {" "}
                    <div className={"topArrow " + (_isSortOptionSelected("type", "desc") ? "selected" : "")}>
                      <i className="fas fa-caret-up"></i>
                    </div>{" "}
                    <div className={"bottomArrow " + (_isSortOptionSelected("type", "asc") ? "selected" : "")}>
                      <i className="fas fa-caret-down"></i>
                    </div>{" "}
                  </div>{" "}
                </div>
              </th>
              <th>
                <div className="tableHeadCellWrapper lastHead" onClick={e => _toggleSortOrder("modified")}>
                <LanguageParsedText keyString="dropBoxPage.table.header3" />{" "}
                  <div className="sortIconsWrapper">
                    {" "}
                    <div className={"topArrow " + (_isSortOptionSelected("modified", "desc") ? "selected" : "")}>
                      <i className="fas fa-caret-up"></i>
                    </div>{" "}
                    <div className={"bottomArrow " + (_isSortOptionSelected("modified", "asc") ? "selected" : "")}>
                      <i className="fas fa-caret-down"></i>
                    </div>{" "}
                  </div>{" "}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedfiles.map((file, fileIndex) => (
              <tr key={fileIndex} onClick={() => props.onSelect(file)} className={_getRowClass(file)}>
                <td>
                  <img src={_getIcon(file)} className="tableIcon" /> {file.title}
                </td>
                <td className="tableCells">{_getFileType(file)}</td>
                <td className="tableCells">{_parseDate(file.modifiedAt)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </InfiniteScroll>
    </div>
  );
};

export default DropboxTable;
