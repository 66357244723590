import React, { Component } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import LanguageParsedText from "../../../../multi-lang/lang-parsed-text/lang-parsed-text";
import Button from "../../../general/components/button/button";
import style from "./notification-settings.module.scss";
import { showToast } from "../../../../helper-methods/index";
import { updateUserData } from "../../../../aws-cognito";
import FilterChips from '../../components/filter-chips/filter-chips';
import { getAllReports } from '../../../../api-calls/index';

class NotificationSettings extends Component {
  state = {
    frequency: "",
    tags: ['tag1', 'tag2'],
    selectedTags: []
  };

  async componentDidMount() {
    this._fetchCurrentFrequency();
    await this._fetchAllAvailableTags(); 
  }

  _fetchAllAvailableTags = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await getAllReports();
        this.setState({ tags: response.tags }, () => {
          resolve();  
        });
      } catch (error) {
        console.log("error :>> ", error);
      }
    });
  }

  _fetchCurrentFrequency = async () => {
    const {
      userData: {
        cognitoUser: { attributes }
      }
    } = this.props;
    if (attributes && attributes["family_name"]) {
      this.setState({ frequency: attributes["family_name"] });
    }
    if (attributes && attributes["custom:tags"]) {
      this.setState({ selectedTags: attributes["custom:tags"].split(',') });
    }
  };

  _updateFrequency = frequency => {
    this.setState({ frequency });
  };

  _save = async () => {
    console.log('object :>> ', {
      "family_name": this.state.frequency,
      "custom:tags": this.state.selectedTags.join(',')
    });
    try {
      this.props.showLoader("Updating");
      await updateUserData({
        "family_name": this.state.frequency,
        "custom:tags": this.state.selectedTags.join(',')
      });
      this._updateUserAttributes();
      showToast("Successfully updated", "success");
      this.props.hideLoader();
    } catch (error) {
      this.props.hideLoader();
      showToast(error.message, "error");
    }
  };

  _updateUserAttributes = attributes => {
    let { userData } = this.props;
    userData.cognitoUser.attributes = {
      ...userData.cognitoUser.attributes,
      "family_name": this.state.frequency,
      "custom:tags": this.state.selectedTags.join(',')
    };
    this.props.updateUserData(userData);
  };

  _updateTagSelection = selectedTags => {
    this.setState({ selectedTags }, () => {
      
    });
  }

  render() {
    const { frequency, selectedTags, tags } = this.state;

    return (
      <React.Fragment>
        <div className={style.userPageSectionsWrapper}>
          <h4>
            <i className="fas fa-bell"></i>&nbsp;{" "}
            <LanguageParsedText keyString="userSettingsPage.notificationSettingsSection.header" />
          </h4>
          <p>
            <LanguageParsedText keyString="userSettingsPage.notificationSettingsSection.subHeader" />
          </p>
        </div>
        <div className={style.inputGroup + " " + style.radioGroup}>
          <p>
            <LanguageParsedText keyString="userSettingsPage.notificationSettingsSection.emailNotificationRadio.label" />
          </p>
          <div>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="frequency"
                  value={""}
                  checked={frequency === ""}
                  onChange={e => this._updateFrequency("")}
                />{" "}
                <LanguageParsedText keyString="userSettingsPage.notificationSettingsSection.emailNotificationRadio.option1" />
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="frequency"
                  value={"daily"}
                  checked={frequency === "daily"}
                  onChange={e => this._updateFrequency("daily")}
                />{" "}
                <LanguageParsedText keyString="userSettingsPage.notificationSettingsSection.emailNotificationRadio.option2" />
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="frequency"
                  value={"weekly"}
                  checked={frequency === "weekly"}
                  onChange={e => this._updateFrequency("weekly")}
                />{" "}
                <LanguageParsedText keyString="userSettingsPage.notificationSettingsSection.emailNotificationRadio.option3" />
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="frequency"
                  value={"monthly"}
                  checked={frequency === "monthly"}
                  onChange={e => this._updateFrequency("monthly")}
                />{" "}
                <LanguageParsedText keyString="userSettingsPage.notificationSettingsSection.emailNotificationRadio.option4" />
              </Label>
            </FormGroup>
          </div>
        </div>
        <div className={style.tagsWrapper}>
          <FilterChips
            disabled={frequency === ''? true: null}
            canCreateNew={false}
            allOptions={tags}
            placeholder={"Add tags to subscribe"}
            selectedOptions={selectedTags}
            onUpdate={this._updateTagSelection}
          />
        </div>
        <Button
          className={style.button}
          text={<LanguageParsedText keyString="userSettingsPage.notificationSettingsSection.submitButton" />}
          onClick={this._save}
        />
      </React.Fragment>
    );
  }
}

export default NotificationSettings;
