import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isUserAuthenticated } from "../../../guards/auth-guard";
import { store } from "../../../redux/store";

const ProtectedRoute = ({ component: Component, allowedUserGroups = [], ...rest }) => {
  const { userData } = store.getState();
  if (isUserAuthenticated() && userData) {
    if (allowedUserGroups && allowedUserGroups.length) {
      let isAllowed = false;
      // Allow only user having specified groups
      if (
        userData &&
        userData.cognitoUser &&
        userData.cognitoUser.signInUserSession &&
        userData.cognitoUser.signInUserSession.idToken &&
        userData.cognitoUser.signInUserSession.idToken.payload &&
        userData.cognitoUser.signInUserSession.idToken.payload["cognito:groups"] &&
        userData.cognitoUser.signInUserSession.idToken.payload["cognito:groups"].length
      ) {
        const currentUserGroup = userData.cognitoUser.signInUserSession.idToken.payload["cognito:groups"][0];
        isAllowed = allowedUserGroups.includes(currentUserGroup);
      }
      if (isAllowed) {
        return <Route {...rest} render={props => <Component {...props} />} />;
      } else {
        // Not allowed
        return (
          <Route {...rest} render={props => <Redirect to={{ pathname: "/home", extras: { ...rest.location } }} />} />
        );
      }
    } else {
      // No user group specified
      // Allow all authenticated users
      return <Route {...rest} render={props => <Component {...props} />} />;
    }
  } else {
    // Not authenticated
    return (
      <Route
        {...rest}
        render={props => <Redirect to={{ pathname: rest.redirectRoute, extras: { ...rest.location } }} />}
      />
    );
  }
};

export default ProtectedRoute;
