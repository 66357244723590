import React, { Component } from "react";
import "./privacy-policy.scss";
import { Container } from "reactstrap";
import Header from "../../../private/containers/header/header";
import Footer from "../../../private/containers/footer/footer";

class PrivacyPolicyPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Header {...this.props} />
        <Container fluid="sm" id="pageWrapper">
          <h1>Privacy Policy</h1>
          <p>
            The Center for Advanced Defense Studies Inc. (C4ADS) operates the website pharos.training (“
            <strong>Site</strong>“). We are data controller for this Site.
          </p>
          <p>
            This Privacy Policy explains what personal data and information we collect when you visit our Site and how
            we use it and provides mandatory information as required under data protection laws, such as Articles 13 and
            14 of the European General Data Protection Regulation (“<strong>GDPR</strong>”) or the California Consumer
            Privacy Act (“<strong>CCPA</strong>”), regarding the transparency of personal data processing. By using our
            Site and providing Personal Information to C4ADS, you agree to the collection and use of information in
            accordance with this Policy. This Privacy Policy should be read along with our Terms of Use, in order to
            ensure you understand and are comfortable with our use and disclosure of your Personal Information.
          </p>
          <p>
            If you do not agree to any of the provisions of this Privacy Policy, you should not use the Site. If you
            have any questions or concerns about this Privacy Policy, you can contact us through info@pharos.training.
          </p>
          <h2>1. Personal Information C4ADS Collects</h2>
          <p>
            “<strong>Personal Information</strong>” is information that identifies you as an individual. C4ADS collects
            certain Personal Information — such as your first and last name, email address, job title, phone number,
            state and country of residence, organization name, when you view and use our Site. C4ADS needs to collect
            this Personal Information to provide requested services and functionality to you. If you disclose any
            Personal Information relating to other people to us, to other members of Pharos’ online community, or to our
            service providers in connection with your use of the Site, you represent that you have the authority to do
            so and permit us to use the information in accordance with this Privacy Policy. You are under no statutory
            or contractual requirement or other obligation to provide Personal Information to us via this Site.
          </p>
          <h2>2. How We Use Personal Information</h2>
          <p>
            C4ADS and its third-party service providers use Personal Information only for business purposes, including
            to:{" "}
          </p>
          <ul>
            <li>enable you to use the Site;</li>
            <li>tailor the content on our Site to the needs of our users; </li>
            <li>
              fulfill and respond to your subscription requests and inquiries, whether sent to us by email or via the
              Contact Us form on the Site;
            </li>
            <li>provide updates on C4ADS research and allow you to contact C4ADS analysts;</li>
            <li>communicate with you regarding our services and provide you with related customer service; and</li>
            <li>
              send you important information regarding the Site, changes to our terms, conditions and policies and/or
              other administrative information.
            </li>
          </ul>
          <p>
            Our third parties provide services such as website hosting, data analysis, payment processing,
            infrastructure provision, email delivery services, auditing services, and other similar services for the
            Site. To enable them to provide such services, we have agreed to provide them with access to your Personal
            Information on a need-to-know basis. If you would like specific information about our providers and the
            information they received, please contact us at info@pharos.training.
          </p>
          <p>
            We may also use Personal Information as we believe to be necessary or appropriate: (a) to comply with
            applicable law, including laws outside your country of residence; (b) to comply with legal process; to
            respond to requests from public and government authorities, including those outside your country of
            residence; (d) to enforce C4ADS’ Terms of Use for the Site; (e) to protect C4ADS’ operations; (f) to protect
            C4ADS’ rights, privacy, safety or property, you or others; and (g) to allow C4ADS to pursue available
            remedies or limit the damages that we may sustain.
          </p>
          <h2>3. How Personal Information is Disclosed</h2>
          <p>
            Your Personal Information might be disclosed (a) within C4ADS or to any C4ADS affiliate for the purposes
            described in this Privacy Policy; (b) to C4ADS’ third-party service providers; or (c) to a third party in
            the event of any reorganization, merger, sale, joint venture, assignment, transfer or other disposition of
            all or any portion of C4ADS’ business, assets or stock (including in connection with any bankruptcy or
            similar proceedings).
          </p>
          <h2>4. Other Information We Collect</h2>
          <p>
            “<strong>Other Information</strong>” is any anonymous information that does not reveal your specific
            identity or relate to you as an identifiable individual.
          </p>
          <p>Other Information includes:</p>
          <ul>
            <li>browser information and log data;</li>
            <li>information collected through cookies, pixel tags and other technologies;</li>
            <li>demographic information and other information provided by you;</li>
            <li>aggregated information.</li>
          </ul>
          <h2>5. Log Data</h2>
          <p>
            When you visit this Site, our servers automatically record information and data (the “
            <strong>Log Data</strong>”) your browser sends. This Log Data may include IP addresses, browser types
            (Firefox, Chrome etc.), browser versions, the search terms you use, date and time of your visit, and the
            pages on our Site that you visit.
          </p>
          <h2>6. Cookies</h2>
          <p>
            “<strong>Cookies</strong>” are files with a small amount of data, which may include an anonymous unique
            identifier. Cookies are sent to your browser from a web site and stored on your computer’s hard drive. Like
            many other websites, we may from time to time use cookies to collect information. You can instruct your
            browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept
            cookies, you may not be able to take full advantage of our Site.
          </p>
          <h2>7. How We Use and Disclose Other Information</h2>
          <p>
            Because Other Information does not personally identify you, we may use and disclose Other Information for
            any purpose. If we are required to treat Other Information as Personal Information under applicable law,
            then we use and disclose it for the purposes for which we use and disclose Personal Information as detailed
            in this Privacy Policy.
          </p>
          <h2>8. Security</h2>
          <p>
            C4ADS uses reasonable organizational, technical, and administrative measures to protect Personal Information
            under C4ADS’ control. Unfortunately, no data transmission over the Internet or data storage system can be
            guaranteed to be 100 percent secure. You use the Site and provide us with information at your own discretion
            and risk. If you suspect or have reason to believe that your interaction with C4ADS is no longer secure,
            immediately notify us of the problem by sending an email to info@pharos.training or calling +1.202.289.3332.
          </p>
          <h2>9. Links to Other Web Sites</h2>
          <p>
            This Privacy Policy does not cover the links within this Site linking to other websites. Those sites are not
            governed by this Privacy Policy, and if you have questions about how a site uses your information, you’ll
            need to check that site’s privacy statement.
          </p>
          <h2>10. Analytics and Third-Party Service Providers</h2>
          <p>
            We work with third party service providers for such purposes as reporting traffic and other statistics.
            Where authorized by us, these providers may use cookies and/or other monitoring technologies to compile
            statistics about our visitors, including their IP address, web browser, pages viewed, time spent on pages,
            and links clicked. This information may be used to, among other things, analyze and track data, improve our
            service, determine the popularity of certain content and better understand our users’ online activity.
          </p>
          <h2> 11.How You Can Access, Change or Suppress Your Personal Information. </h2>
          <p>
            If you would like to request to review, correct, update, suppress or delete, or object to, restrict, or
            otherwise limit C4ADS’ use of, your Personal Information that has been previously provided to C4ADS, you can
            contact us at info@pharos.training or by calling +1.202.289.3332. In your request, please make clear what
            information you would like to have changed, whether you would like to have your Personal Information
            suppressed from a C4ADS database, or otherwise let C4ADS know what limitations you would like to put on our
            use of your Personal Information. We will try to comply with your request as soon as reasonably practicable
            and in accordance with applicable law.
          </p>
          <h2>12. Retention Period</h2>
          <p>
            We will retain your Personal Information for as long as is necessary for the purposes listed above or longer
            where this is required by the law. Please contact us for further details of applicable retention periods.
          </p>
          <p>
            We may keep an anonymized form of your information, which will no longer refer to you, for statistical
            purposes without time limits, to the extent that we have a legitimate and lawful interest in doing so.
          </p>
          <p>
            We will retain information in accordance with our clients’ instructions where we act on their behalf as a
            data processor.
          </p>
          <h2>13. If you are located in the European Economic Area</h2>
          <p>
            C4ADS’ main administrative offices are based in the USA and that’s where we process Personal Information
            collected through our Site. When you provide Personal Information to us, we request your consent to transfer
            that personal information to the USA. The USA does not have an adequacy decision from the European
            Commission, which means that the Commission has not determined that the laws of the USA provide adequate
            protection for personal information. Although the laws of the USA do not provide legal protection that is
            equivalent to EU data protection laws, we safeguard your Personal Information by treating it in accordance
            with the provisions of GDPR. We take appropriate steps to protect your privacy and implement reasonable
            security measures to protect your Personal Information in storage. We use secure transmission methods to
            collect Personal Information through this Site. We also enter into contracts with our data processors that
            require them to treat Personal information in a manner that is consistent with this Notice.
          </p>
          <p>
            You have the right to be informed about our use of your Personal Information, and to correct any mistakes in
            the Personal Information we have collected and have such information erased. To exercise any of these
            rights, please contact us at info@pharos.training or by calling +1.202.289.3332
          </p>
          <h2>14. Complaint with a Supervisory Authority</h2>
          <p>
            You have the right to file a complaint concerning our processing of your Personal Information with your
            national (or in some countries, regional) data protection authority. The EU Commission has a list here:{" "}
            <a
              href="http://ec.europa.eu/justice/article- 29/structure/data-protection-authorities/index_en.htm"
              target="_blank"
            >
              http://ec.europa.eu/justice/article- 29/structure/data-protection-authorities/index_en.htm
            </a>
          </p>
          <h2>15. Changes to this Privacy Policy</h2>
          <p>
            This Privacy Policy is reviewed and updated regularly to ensure it accurately captures our practices and
            procedures. The effective date is identified above. Your use of the Site following these changes means that
            you accept the revised Privacy Policy.
          </p>
          <h2>16. Resolving Concerns and How to Contact Us</h2>
          <p>
            If you have questions or concerns about this Privacy Policy, contact us at info@pharos.training or by
            calling +1.202.289.3332.
          </p>
        </Container>
        <Footer {...this.props} />
      </React.Fragment>
    );
  }
}

export default PrivacyPolicyPage;
