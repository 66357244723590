import React, { useState } from "react";
import style from "./input-field.module.scss";

const InputField = props => {
  const [isPasswordVisible, togglePasswordVisibility] = useState(false);

  let inputType = props.type || "text";

  const _togglePasswordVisibility = () => {
    togglePasswordVisibility(!isPasswordVisible);
  };

  switch (inputType) {
    case "password": {
      return (
        <div className={style.passwordInputWrapper}>
          <input {...props} type={isPasswordVisible ? "text" : "password"} />
          <div onClick={_togglePasswordVisibility}>
            {isPasswordVisible ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
          </div>
        </div>
      );
    }
    default: {
      return (
        <div className={style.inputWrapper}>
          <input {...props} type={inputType} />
        </div>
      );
    }
  }
};

export default InputField;
