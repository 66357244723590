import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import "./curriculum-page.scss";
import { fetchCurriculum } from "../../../../http-calls";
import cuid from "cuid";
import { showLoader, hideLoader } from "../../../../redux/actions/loader-data";
import { connect } from "react-redux";
import NestedMenu from "../../components/nested-menu/nested-menu";
import CurriculumContentViewer from "../../components/curriculum-content-viewer/curriculum-content-viewer";
import { deepClone } from "../../../../helper-methods";
//import BreadCrumb from "../../components/bread-crumb/bread-crumb";
import { sleepTime } from "../../../../helper-methods/index";
import LanguageParsedText from "../../../../multi-lang/lang-parsed-text/lang-parsed-text";
import { deutschCurriculum } from "../../../../curriculum-data";
import { englishCurriculum } from "../../../../curriculum-data/index";

const initialState = {
  curriculum: {},
  selectedMenus: null,
  selectedLang: "english"
};

class CurriculumPage extends Component {
  state = initialState;

  async componentDidMount() {
    this.props.showLoader("Loading");
    await this._setLanguage();
    await this._loadLangSpecificData();
    this._selectFirstContent();
    await sleepTime(500);
    this.props.hideLoader();
  }

  _setLanguage = () => {
    return new Promise((resolve, reject) => {
      this.setState({ selectedLang: this.props.languageData.currentLang }, () => {
        resolve();
      });
    });
  };

  async componentDidUpdate(nextProps) {
    console.log(
      "this.state.selectedLang !== this.props.languageData.currentLang :>> ",
      this.state.selectedLang,
      nextProps.languageData.currentLang,
      this.props.languageData.currentLang
    );
    if (this.state.selectedLang !== this.props.languageData.currentLang) {
      this.props.showLoader("Loading");
      await this._setLanguage();
      await this._loadLangSpecificData();
      this._selectFirstContent();
      await sleepTime(500);
      this.props.hideLoader();
    }
  }

  async componentWillReceiveProps(prevProps) {
    // console.log('this.state.selectedLang !== this.props.languageData.currentLang :>> ', this.state.selectedLang , this.props.languageData.currentLang);
    // if (this.state.selectedLang !== this.props.languageData.currentLang) {
    //   this.props.showLoader("Loading");
    //   await this._setLanguage();
    //   await this._loadLangSpecificData();
    //   this._selectFirstContent();
    //   await sleepTime(500);
    //   this.props.hideLoader();
    // }
  }

  _loadLangSpecificData = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let curriculumData = {};
        switch (this.props.languageData.currentLang) {
          case "deutsch": {
            curriculumData = deutschCurriculum;
            break;
          }
          default: {
            curriculumData = englishCurriculum;
          }
        }
        const curriculum = this._formatCurriculumData(curriculumData);
        this.setState({ curriculum }, () => {
          resolve();
        });
      } catch (error) {
        console.log("error :>> ", error);
      }
    });
  };

  _selectFirstContent = () => {
    return new Promise((resolve, reject) => {
      // Search for first leaf node
      const { curriculum } = this.state;
      const firstLeafNodeTrace = this._tracePathToFirstLeaf(curriculum);
      this.setState({ selectedMenus: firstLeafNodeTrace }, () => {
        resolve();
      });
    });
  };

  _tracePathToFirstLeaf = node => {
    if (node.isLeafNode) {
      const nodeInfo = {};
      nodeInfo[node.level] = node.index;
      return nodeInfo;
    } else {
      // Not a leaf node
      let nodeInfo = {};
      // Take first node of current level
      const firstNode = node.menus[0];
      nodeInfo[firstNode.level] = firstNode.index;
      nodeInfo = {
        ...nodeInfo,
        ...this._tracePathToFirstLeaf(firstNode)
      };
      return nodeInfo;
    }
  };

  _tracePathToLastLeaf = node => {
    if (node.isLeafNode) {
      const nodeInfo = {};
      nodeInfo[node.level] = node.index;
      return nodeInfo;
    } else {
      // Not a leaf node
      let nodeInfo = {};
      // Take first node of current level
      const firstNode = node.menus[node.menus.length - 1];
      nodeInfo[firstNode.level] = firstNode.index;
      nodeInfo = {
        ...nodeInfo,
        ...this._tracePathToFirstLeaf(firstNode)
      };
      return nodeInfo;
    }
  };

  _formatCurriculumData = originalData => {
    let formattedCurriculum = {};
    if (
      originalData.curriculum &&
      Object.keys(originalData.curriculum) &&
      Object.keys(originalData.curriculum).length
    ) {
      const { curriculum } = originalData;
      formattedCurriculum = this._parseLevel(curriculum, 1);
    }
    return formattedCurriculum;
  };

  /**
   * It's a recursive function
   * To prepare the navigation tree
   */
  _parseLevel = (node, level, menuId = null) => {
    let content = {};
    // Check if node is array or object
    if (Array.isArray(node)) {
      // Node is an array
      // So it's leaf node
      // Prepare contents
      content.contents = [];
      content.isLeafNode = true;
      node.forEach((nodeContent, nodeIndex) => {
        const contentType = Object.keys(nodeContent)[0];
        content.contents.push({
          contentType,
          linkedMenuId: menuId,
          data: nodeContent[contentType],
          contentId: cuid()
        });
      });
    } else {
      // Node is an object
      // Not a leaf node
      // Prepare menus
      content.menus = [];
      if (Object.keys(node) && Object.keys(node).length) {
        // Has properties
        // Prepare menu
        Object.keys(node).forEach((subNodeKey, subNodeIndex) => {
          const id = cuid();
          content.menus.push({
            level,
            id,
            index: subNodeIndex,
            heading: subNodeKey,
            ...this._parseLevel(node[subNodeKey], level + 1, id)
          });
        });
      }
    }
    return content;
  };

  _updateSelectedMenus = selectedMenus => {
    this.setState({ selectedMenus });
  };

  _extractCurrentViewContents = () => {
    const { curriculum, selectedMenus } = this.state;
    // Extract leaf node data
    let extractedData = null;
    if (curriculum && selectedMenus) {
      extractedData = this._traverseToLeafNode(curriculum, selectedMenus, 1);
    }
    return extractedData;
  };

  _traverseToLeafNode = (node, selectedMenus, level, mergeLevelThree = true) => {
    if (node.menus) {
      if (mergeLevelThree && level === 3 && !node.isLeafNode) {
        // If it's level 2 and has conetents on next node (level 3 is leaf)
        // So merge all contents
        return this._mergeContents(node);
      } else {
        const nextNode = node.menus.find(n => n.index === selectedMenus[level]);
        if (nextNode) {
          return this._traverseToLeafNode(nextNode, selectedMenus, level + 1, mergeLevelThree);
        } else {
          return node;
        }
      }
    } else {
      return node;
    }
  };

  _mergeContents = node => {
    // Merge contents of next node
    let mergedContents = [];
    node.menus.forEach(leafNode => {
      mergedContents = [...mergedContents, ...leafNode.contents];
    });
    return {
      ...node,
      contents: mergedContents
    };
  };

  _moveToNextSection = () => {
    const { selectedMenus } = deepClone(this.state);
    const currentLeafLevel = this._determineMaxLevel(selectedMenus);
    let nextLeafPath = selectedMenus;
    for (let level = currentLeafLevel - 1; level > 0; level--) {
      if (currentLeafLevel > level) {
        delete nextLeafPath[level + 1];
      }
      nextLeafPath[level] = nextLeafPath[level] + 1;
      const isPathValid = this._traverseToNode(nextLeafPath);
      if (isPathValid) {
        nextLeafPath = this._determineFirstLeafPath(nextLeafPath);
        this._updateSelectedMenus(nextLeafPath);
        break;
      }
    }
  };

  _moveToPreviousSection = () => {
    const { selectedMenus } = deepClone(this.state);
    // Skip level 3 navigation
    if (selectedMenus[3]) {
      delete selectedMenus[3];
    }
    let currentLeafLevel = this._determineMaxLevel(selectedMenus);
    let prevLeafPath = selectedMenus;
    // prevLeafPath[3] = 0;
    for (let level = currentLeafLevel; level > 0; level--) {
      if (currentLeafLevel > level) {
        delete prevLeafPath[level + 1];
      }
      prevLeafPath[level] = prevLeafPath[level] - 1;
      const isPathValid = this._traverseToNode(prevLeafPath);
      if (isPathValid) {
        prevLeafPath = this._determineLastLeafPath(prevLeafPath);
        // If level 3 nav available, set it to first content
        if (prevLeafPath[3]) {
          prevLeafPath[3] = 0;
        }
        this._updateSelectedMenus(prevLeafPath);
        break;
      }
    }
  };

  _determineFirstLeafPath = nodePath => {
    const node = this._traverseToNode(nodePath);
    nodePath = { ...nodePath, ...this._tracePathToFirstLeaf(node) };
    return nodePath;
  };

  _determineLastLeafPath = nodePath => {
    const node = this._traverseToNode(nodePath);
    nodePath = { ...nodePath, ...this._tracePathToLastLeaf(node) };
    return nodePath;
  };

  _determineMaxLevel = nodePath => {
    let maxLevel = -1;
    for (let level in nodePath) {
      if (maxLevel < level) {
        maxLevel = level;
      }
    }
    return maxLevel;
  };

  _traverseToNode = nodePath => {
    const { curriculum } = this.state;
    let levelContents = curriculum.menus;
    const maxLevel = this._determineMaxLevel(nodePath);
    for (let levelIndex = 1; levelIndex <= maxLevel; levelIndex++) {
      if (maxLevel == levelIndex) {
        return levelContents[nodePath[levelIndex]];
      } else {
        if (levelContents && levelContents[nodePath[levelIndex]] && levelContents[nodePath[levelIndex]].menus) {
          levelContents = levelContents[nodePath[levelIndex]].menus;
        }
      }
    }
  };

  _prepareBreadCrumbMenus = () => {
    const { curriculum, selectedMenus } = this.state;
    const breadCrumbMenus = {};
    if (curriculum && selectedMenus) {
      // Add all options of level 1
      let levelTracker = {};
      Object.keys(selectedMenus).forEach(level => {
        if (level == 1) {
          levelTracker = { 1: 0 };
          breadCrumbMenus[1] = this._getSiblingMenusFor({ 1: 0 });
        } else {
          levelTracker[level] = selectedMenus[level];
          breadCrumbMenus[level] = this._getSiblingMenusFor(deepClone(levelTracker));
        }
      });
    }
    return breadCrumbMenus;
  };

  _getSiblingMenusFor = levelTracker => {
    const { curriculum } = this.state;
    const siblingMenus = [];
    if (Object.keys(levelTracker).length === 1) {
      // Get all menus of level 1
      curriculum.menus.forEach(menu => {
        const siblingMenu = {
          ...menu,
          nodeLink: { 1: menu.index }
        };
        if (levelTracker[1] === menu.index) {
          siblingMenu["isActive"] = true;
        }
        siblingMenus.push(siblingMenu);
      });
    } else if (Object.keys(levelTracker).length > 1) {
      // Get all menus of (n-1) level
      const maxLevel = this._determineMaxLevel(levelTracker);
      const levelToTraverse = deepClone(levelTracker);
      delete levelToTraverse[maxLevel];
      const menus = this._traverseToLeafNode(curriculum, levelToTraverse, 1, false).menus;
      menus.forEach(menu => {
        const nodeLink = deepClone(levelTracker);
        nodeLink[menu.level] = menu.index;
        if (!menu.isLeafNode) {
          nodeLink[menu.level + 1] = 0;
        }
        const siblingMenu = {
          ...menu,
          nodeLink
        };
        if (levelTracker[maxLevel] === menu.index) {
          siblingMenu["isActive"] = true;
        }
        siblingMenus.push(siblingMenu);
      });
    }
    return siblingMenus;
  };

  render() {
    const { curriculum, selectedMenus } = this.state;

    return (
      <div id="curriculumPageWrapper">
        <Container fluid={true}>
          <Row>
            <Col md="4" className="hideOnMobile">
              <div className="navigatorMenuWrapper">
                <div className="navigatorMenu">
                  {curriculum && curriculum.menus && curriculum.menus.length
                    ? curriculum.menus.map((node, nodeIndex) => (
                        <NestedMenu
                          key={nodeIndex}
                          node={node}
                          selectedMenus={selectedMenus}
                          onSelectionUpdate={this._updateSelectedMenus}
                          parentSelected={true}
                        />
                      ))
                    : null}
                </div>
              </div>
            </Col>
            <Col md="8">
              {/* <BreadCrumb
                menus={this._prepareBreadCrumbMenus()}
                navigateTo={this._updateSelectedMenus}
                showLoader={this.props.showLoader}
                hideLoader={this.props.hideLoader}
              /> */}
              <div className="contentDisplayWrapper">
                {
                  <CurriculumContentViewer
                    node={this._extractCurrentViewContents()}
                    nodePath={selectedMenus}
                    onNodePathUpdate={updatedNodePath => this._updateSelectedMenus(updatedNodePath)}
                  />
                }
              </div>
              <div id="bottomNavWrapper">
                <button className="prevButton" onClick={this._moveToPreviousSection}>
                  <LanguageParsedText keyString="curriculumPage.actionButtons.previous" />
                </button>

                <button className="nextButton" onClick={this._moveToNextSection}>
                  <LanguageParsedText keyString="curriculumPage.actionButtons.next" />
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToDispatch = dispatch => {
  return {
    showLoader: loaderText => dispatch(showLoader(loaderText)),
    hideLoader: () => dispatch(hideLoader())
  };
};

const mapStateToProps = state => {
  return {
    userData: state.userData,
    languageData: state.languageData
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(CurriculumPage);
