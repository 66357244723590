import React, { Component } from "react";
import style from "./user-settings.module.scss";
import LanguageParsedText, { getValueFromCurrentLang } from "../../../../multi-lang/lang-parsed-text/lang-parsed-text";
import Button from "../../../general/components/button/button";
import InputField from "../../../general/components/input-field/input-field";
import PhoneNumberInput from "../../../general/components/phone-number-input/phone-number-input";
import { showToast, scrollToTop } from '../../../../helper-methods/index';
import { updateUserData } from '../../../../aws-cognito';

class UserSettings extends Component {
  state = {
    countryCode: "+380",
    formFields: {
      fullName: {
        value: "",
        isValid: false,
        isDirty: false,
        isRequired: true,
        errorMessage: ""
      },
      organization: {
        value: "",
        isValid: false,
        isDirty: false,
        isRequired: true,
        errorMessage: ""
      },
      jobTitle: {
        value: "",
        isValid: false,
        isDirty: false,
        isRequired: true,
        errorMessage: ""
      },
      phone: {
        value: "",
        isValid: false,
        isDirty: false,
        isRequired: true,
        errorMessage: ""
      }
    },
    isFormValid: false,
    responseError: "",
    email: ""
  };

  componentDidMount() {
    this._assignValues();
    scrollToTop();
  }

  _assignValues = () => {
    const { userData: { cognitoUser: {attributes} } } = this.props;
    let { formFields, countryCode, email } = this.state;
    formFields.phone.value = attributes.phone_number.split(attributes["custom:country_code"])[1];
    formFields.fullName.value = attributes["name"];
    formFields.jobTitle.value = attributes["custom:job_title"];
    formFields.organization.value = attributes["custom:organization"];
    countryCode = attributes["custom:country_code"];
    email = attributes["email"];
    this.setState({ formFields, countryCode, email });
  }

  _updateCountryCode = countryCode => {
    this.setState({ countryCode });
  };

  _markAsDirty = fieldName => {
    const { formFields } = this.state;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields });
    this._validateForm();
  };

  _updateFieldValue = (fieldName, value) => {
    const { formFields } = this.state;
    formFields[fieldName].value = value;
    formFields[fieldName].isValid = true;
    this.setState({ formFields });
    if (formFields[fieldName].isDirty) {
      // Validate
      this._validateForm();
    }
  };

  _makeAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((fieldName, index) => {
        formFields[fieldName].isDirty = true;
      });
      this.setState({ formFields }, () => {
        resolve();
      });
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      let isFormValid = true;
      Object.keys(formFields).forEach((fieldName, index) => {
        const field = formFields[fieldName];
        if (field.isRequired || field.value.length) {
          switch (fieldName) {
            case "fullName": {
              if (field.value.length) {
                field.isValid = true;
              } else {
                field.isValid = false;
                isFormValid = false;
                field.errorMessage = <LanguageParsedText keyString="signUpPage.fields.name.errorMessage" />;
              }
              break;
            }
            case "organization": {
              if (field.value.length) {
                field.isValid = true;
              } else {
                field.isValid = false;
                isFormValid = false;
                field.errorMessage = <LanguageParsedText keyString="signUpPage.fields.organization.errorMessage" />;
              }
              break;
            }
            case "jobTitle": {
              if (field.value.length) {
                field.isValid = true;
              } else {
                field.isValid = false;
                isFormValid = false;
                field.errorMessage = <LanguageParsedText keyString="signUpPage.fields.jobTitle.errorMessage" />;
              }
              break;
            }
            case "phone": {
              if (field.value.length > 5) {
                field.isValid = true;
              } else {
                field.isValid = false;
                isFormValid = false;
                field.errorMessage = <LanguageParsedText keyString="signUpPage.fields.phone.errorMessage" />;
              }
              break;
            }
            default: {
            }
          }
        }
      });
      this.setState({ formFields, isFormValid }, () => {
        resolve();
      });
    });
  };

  _generateAttributesForCognito = () => {
    const { formFields, countryCode } = this.state;
    const attributes = {
        "name": formFields.fullName.value,
        "custom:job_title": formFields.jobTitle.value,
        "custom:organization": formFields.organization.value,
        "phone_number": countryCode+formFields.phone.value,
        "custom:country_code": countryCode
    }
    return attributes;
  }

  _updateUserAttributes = attributes => {
    let {userData} = this.props;
    userData.cognitoUser.attributes = {
      ...userData.cognitoUser.attributes,
      ...attributes
    }
    this.props.updateUserData(userData);
  }

  _updateData = async e => {
    e.preventDefault();
    this._makeAllFieldDirty();
    await this._validateForm();
    const { formFields, isFormValid } = this.state;
    if (isFormValid) {
        try {
          this.props.showLoader("Updating Account Information");
          // Add user data in cognito
          const attributes = this._generateAttributesForCognito();
          await updateUserData(attributes);
          // User updated successfully
          // Update locally
          this._updateUserAttributes(attributes);
          showToast("Successfully updated", "success");
          this.props.hideLoader();
        } catch (error) {
          this.props.hideLoader();
          showToast(error && error.message && error.message.length? error.message:"Error while updating", "error");
        }
    } else {
      showToast("Error in form, please check", "error");
    }
  };

  render() {
    const { countryCode, email, formFields } = this.state;

    return (
      <React.Fragment>
        <div className={style.userPageSectionsWrapper}>
          <h4>
            <i className="fas fa-cog"></i> &nbsp;{" "}
            <LanguageParsedText keyString="userSettingsPage.userSettingsSection.header" />
          </h4>
          <p>
            <LanguageParsedText keyString="userSettingsPage.userSettingsSection.subHeader" />
          </p>
        </div>
        <div className={style.textGroup}>
          <p>
            <LanguageParsedText keyString="userSettingsPage.userSettingsSection.emailField.label" />
          </p>
          <p>{email}</p>
        </div>
        <div className={style.inputGroup}>
          <p>
            <LanguageParsedText keyString="userSettingsPage.userSettingsSection.userNameField.label" />
          </p>
          <InputField
            placeholder={getValueFromCurrentLang("userSettingsPage.userSettingsSection.userNameField.placeholder")}
            type="email"
            value={formFields.fullName.value}
            onChange={e => this._updateFieldValue("fullName", e.target.value)}
            onBlur={() => this._markAsDirty("fullName")}
          />
          {formFields.fullName.isDirty && !formFields.fullName.isValid ? (
            <p className="errorText">
              Name cannot be empty
            </p>
          ) : null}
        </div>
        <div className={style.inputGroup}>
          <p>
            <LanguageParsedText keyString="userSettingsPage.userSettingsSection.organizationField.label" />
          </p>
          <InputField
            placeholder={getValueFromCurrentLang("userSettingsPage.userSettingsSection.organizationField.placeholder")}
            type="text"
            value={formFields.organization.value}
            onChange={e => this._updateFieldValue("organization", e.target.value)}
            onBlur={() => this._markAsDirty("organization")}
          />
          {formFields.organization.isDirty && !formFields.organization.isValid ? (
            <p className="errorText">
              Organization cannot be empty
            </p>
          ) : null}
        </div>
        <div className={style.inputGroup}>
          <p>
            <LanguageParsedText keyString="userSettingsPage.userSettingsSection.jobTitleField.label" />
          </p>
          <InputField
            placeholder={getValueFromCurrentLang("userSettingsPage.userSettingsSection.jobTitleField.placeholder")}
            type="text"
            value={formFields.jobTitle.value}
            onChange={e => this._updateFieldValue("jobTitle", e.target.value)}
            onBlur={() => this._markAsDirty("jobTitle")}
          />
          {formFields.jobTitle.isDirty && !formFields.jobTitle.isValid ? (
            <p className="errorText">
              Job title cannot be empty
            </p>
          ) : null}
        </div>
        <div className={style.inputGroup}>
          <p>Phone</p>
          <PhoneNumberInput
            countryCode={countryCode}
            updateCountryCode={this._updateCountryCode}
            phone={formFields.phone.value}
            updatePhoneNumber={phone => this._updateFieldValue("phone", phone)}
            onBlur={() => {}}
          />
          {formFields.phone.isDirty && !formFields.phone.isValid ? (
            <p className="errorText">
              Phone cannot be empty
            </p>
          ) : null}
        </div>
        <Button
          className={style.button}
          onClick={this._updateData}
          text={<LanguageParsedText keyString="userSettingsPage.userSettingsSection.submitButton" />}
        />
      </React.Fragment>
    );
  }
}

export default UserSettings;
