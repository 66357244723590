import React, { useEffect, useState } from "react";
import { deepClone } from "../../../../helper-methods";
import "./nested-menu.scss";

const NestedMenu = props => {
  const [isExpanded, updateExpandStatus] = useState(true);

  let isExpandable = true;
  const { node } = props;
  if (node.isLeafNode) {
    isExpandable = false;
  }

  let isSelected = false;
  if (props.selectedMenus && props.parentSelected && props.selectedMenus[node.level] === node.index) {
    isSelected = true;
  }

  const _getMenusClassName = () => {
    let className = "levelMenu";
    if (isSelected) {
      className += " selectedMenu";
    }
    if (!isExpandable) {
      className += " noSign";
    }
    return className;
  };

  const _prepareParentLevelIndices = () => {
    let { parentLevelIndices } = deepClone(props);
    if (!parentLevelIndices) {
      parentLevelIndices = {};
    }
    parentLevelIndices[node.level] = node.index;
    return parentLevelIndices;
  };

  const _renderNestedMenusIfAvailable = () => {
    if (isExpanded && isExpandable && node.menus && node.menus.length) {
      return (
        <>
          {node.menus.map((node, nodeIndex) => (
            <NestedMenu
              {...props}
              parentSelected={isSelected}
              key={nodeIndex}
              node={node}
              parentLevelIndices={_prepareParentLevelIndices()}
            />
          ))}
        </>
      );
    }
  };

  const _generateSerialNo = () => {
    if (node.level === 1) {
      return `${node.index + 1}.  `;
    } else if (node.level !== 3) {
      const { parentLevelIndices } = props;
      let serialNo = "";
      const length = Object.keys(parentLevelIndices).length;
      Object.keys(parentLevelIndices).forEach((level, index) => {
        if (index < length - 2) {
          serialNo += ".";
        } else {
          serialNo += parentLevelIndices[index + 1] + 1;
        }
      });
      return `${serialNo}.${node.index + 1}  `;
    }
    return ``;
  };

  const _getIconClassName = () => {
    if (isExpanded) {
      return "fas fa-chevron-up";
    } else {
      return "fas fa-chevron-down";
    }
  };

  const _handleMenuClick = () => {
    if (node.isLeafNode) {
      // Select
      // Prepare selectedMenus
      if (props.parentLevelIndices) {
        const selectedMenus = deepClone(props.parentLevelIndices);
        selectedMenus[node.level] = node.index;
        props.onSelectionUpdate(selectedMenus);
      }
    } else {
      // Toggle expand sttaus
      updateExpandStatus(!isExpanded);
    }
  };

  return (
    <div className="levelMenuWrapper">
      <div className={_getMenusClassName()} onClick={e => _handleMenuClick()}>
        <p>
          {_generateSerialNo()}
          {node.heading}
        </p>
        <div>
          <i className={_getIconClassName()}></i>
        </div>
      </div>
      {_renderNestedMenusIfAvailable()}
    </div>
  );
};

export default NestedMenu;
