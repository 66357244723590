import React, { Component } from 'react';
import { logout, showToast, extractQueryParams } from '../../../../helper-methods/index';

class AccountVerified extends Component {
	state = {  }

	componentDidMount() {
		// Parse query params and show toasts based on it
		this._parseParams();
		// Logout if session exists
		this._destroySession();
	}

	_destroySession = () => {
		logout(this.props.history);
	}

	_parseParams = () => {
		const params = extractQueryParams();
		if (params && params.success && params.success === "true") {
			// Show the toast message
			showToast("Account verified successfully, login to continue", "success");
		}
	}

	render() { 
		return (<div />);
	}
}
 
export default AccountVerified;