import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, FormGroup, Row } from "reactstrap";
import * as images from "../../../../assets/images";
import LanguageParsedText, { getValueFromCurrentLang } from "../../../../multi-lang/lang-parsed-text/lang-parsed-text";
import { hideLoader, showLoader } from "../../../../redux/actions/loader-data";
import { updateUserData } from "../../../../redux/actions/user-data";
import Button from "../../../general/components/button/button";
import InputField from "../../../general/components/input-field/input-field";
import "./forgot-password-page.scss";
import { requestVerificationCode } from "../../../../aws-cognito/index";
import { showToast } from "../../../../helper-methods/index";

const initialState = {
  formFields: {
    email: {
      value: "",
      isValid: false,
      isDirty: false
    }
  },
  isFormValid: false,
  responseError: ""
};

class ForgotPasswordPage extends Component {
  state = initialState;

  _markAsDirty = fieldName => {
    const { formFields } = this.state;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields });
    this._validateForm();
    this._hideResponseError();
  };

  _updateFieldValue = (fieldName, value) => {
    this._hideResponseError();
    const { formFields } = this.state;
    formFields[fieldName].value = value;
    this.setState({ formFields });
    if (formFields[fieldName].isDirty) {
      // Validate
      this._validateForm();
    }
  };

  _showResponseError = errorText => {
    this.setState({ responseError: errorText });
  };

  _hideResponseError = () => {
    const { responseError } = this.state;
    if (responseError.length) {
      this.setState({ responseError: "" });
    }
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      let isFormValid = true;
      Object.keys(formFields).forEach((fieldName, index) => {
        switch (fieldName) {
          case "email": {
            // var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            // if (emailRegex.test(String(formFields.email.value).toLowerCase())) {
            //   formFields.email.isValid = true;
            // } else {
            //   formFields.email.isValid = false;
            //   isFormValid = false;
            // }
            if (formFields.email.value.length) {
              formFields.email.isValid = true;
            } else {
              formFields.email.isValid = false;
              isFormValid = false;
            }
            break;
          }
        }
      });
      this.setState({ formFields, isFormValid }, () => {
        resolve();
      });
    });
  };

  _makeAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((fieldName, index) => {
        formFields[fieldName].isDirty = true;
      });
      this.setState({ formFields }, () => {
        resolve();
      });
    });
  };

  _requestPasswordResetLink = async e => {
    e.preventDefault();
    this._makeAllFieldDirty();
    await this._validateForm();
    const { formFields, isFormValid } = this.state;
    if (isFormValid) {
      // Trigger password reset request
      try {
        await requestVerificationCode(formFields.email.value);
        this.props.history.push("/auth/reset-password");
        showToast("Verification code sent to email", "success");
      } catch (error) {
        showToast(error.message, "error");
      }
    }
  };

  render() {
    const { formFields, responseError } = this.state;
    return (
      <React.Fragment>
        <div id="forgotPasswordPageWarpper">
          <Container fluid={true}>
            <Row>
              <Col md="4" sm="12" className="imageWrapper">
              <img alt="" src={require("../../../../assets/images/files/Pharos Logo Final_Square Blue.svg")} />
              </Col>
              <Col md="8" sm="12" className="formWrapper">
                <div className="formContainer">
                  <Row>
                    <Col id="forgotPasswordWrapper">
                      <h1>
                        <LanguageParsedText keyString="forgotPasswordPage.header" />
                      </h1>
                      <p>
                        <LanguageParsedText keyString="forgotPasswordPage.subHeader" />
                      </p>
                      <form onSubmit={e => this._login(e)}>
                        <FormGroup>
                          <InputField
                            type="email"
                            name="email"
                            placeholder={getValueFromCurrentLang("forgotPasswordPage.field1.placeholder")}
                            value={formFields.email.value}
                            onChange={e => this._updateFieldValue("email", e.target.value)}
                            onBlur={() => this._markAsDirty("email")}
                          />
                          {formFields.email.isDirty && !formFields.email.isValid ? (
                            <p className="errorText">
                              <LanguageParsedText keyString="loginPage.field1.errorMessage" />
                            </p>
                          ) : null}
                        </FormGroup>
                      </form>
                      <Button
                        className="forgotPasswordBtn"
                        text={<LanguageParsedText keyString="loginPage.submitButton" />}
                        onClick={e => this._requestPasswordResetLink(e)}
                      />
                      {responseError.length ? (
                        <div>
                          <p className="errorText">{responseError}</p>
                        </div>
                      ) : null}
                      <div id="signUpAccess">
                        <p onClick={() => this.props.history.push("/auth/login")}>
                          <LanguageParsedText keyString="forgotPasswordPage.loginLink" />
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToDispatch = dispatch => {
  return {
    showLoader: loaderText => dispatch(showLoader(loaderText)),
    hideLoader: () => dispatch(hideLoader()),
    updateUserData: userData => dispatch(updateUserData(userData))
  };
};

export default connect(null, mapStateToDispatch)(ForgotPasswordPage);
