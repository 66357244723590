import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import style from "./header.module.scss";
import * as images from "../../../../assets/images";
import Button from "../../../general/components/button/button";
//following import is from language-switcher.jsx file that shows a language option in the home page header
//import LanguageSwitcher from "../../../general/components/language-switcher/language-switcher";
import LanguageParsedText from "../../../../multi-lang/lang-parsed-text/lang-parsed-text";
import OutsideClickHandler from "react-outside-click-handler";
import { connect } from "react-redux";
import { logout } from "../../../../helper-methods";
import { withRouter } from "react-router-dom";

const Header = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [isUserOptionsVisible, setUserOptionsVisibility] = useState(false);

  const toggleNavMenu = () => setIsOpen(!isOpen);
  const toggleUserOptions = () => setUserOptionsVisibility(!isUserOptionsVisible);

  let userData = null;
  if (props && props.userData && props.userData.token) {
    userData = props.userData;
  }

  const _isActive = link => {
    if (props.history && props.history.location && props.history.location.pathname) {
      if (props.history.location.pathname === link) {
        return style.active;
      }
    }
    return "";
  };

  const _isDropboxSectionAllowed = () => {
    const { userData } = props;
    let isAllowed = false;
    if (userData) {
      // Allow only user having specified groups
      if (
        userData &&
        userData.cognitoUser &&
        userData.cognitoUser.signInUserSession &&
        userData.cognitoUser.signInUserSession.idToken &&
        userData.cognitoUser.signInUserSession.idToken.payload &&
        userData.cognitoUser.signInUserSession.idToken.payload["cognito:groups"] &&
        userData.cognitoUser.signInUserSession.idToken.payload["cognito:groups"].length
      ) {
        const currentUserGroup = userData.cognitoUser.signInUserSession.idToken.payload["cognito:groups"][0];
        isAllowed = ["Private", "Admin"].includes(currentUserGroup);
      }
    }
    return isAllowed;
    // return true;
  };

  const _isAuthenticated = () => {
    const { userData } = props;
    let isAllowed = false;
    if (userData) {
      // Allow only user having specified groups
      if (
        userData &&
        userData.cognitoUser &&
        userData.cognitoUser.signInUserSession &&
        userData.cognitoUser.signInUserSession.idToken &&
        userData.cognitoUser.signInUserSession.idToken.payload &&
        userData.cognitoUser.signInUserSession.idToken.payload["cognito:groups"] &&
        userData.cognitoUser.signInUserSession.idToken.payload["cognito:groups"].length
      ) {
        isAllowed = true;
      }
    }
    return isAllowed;
    // return true;
  };

  return (
    <div className={style.headerOuterWrapper}>
      <OutsideClickHandler
        onOutsideClick={() => {
          setIsOpen(false);
        }}
      >
        <Navbar color="white" light expand="md" className={style.navbar} sticky={"top"}>
          <NavbarBrand onClick={() => props.history.push("/home")}>
            <div className={style.headerLogoWrapper}>
              <img alt="" src={require("../../../../assets/images/files/Pharos Logo Final_Horizontal Black.svg")} />
            </div>
          </NavbarBrand>

          {_isAuthenticated() ? (
            <>
              <NavbarToggler onClick={toggleNavMenu} />

              <Collapse isOpen={isOpen} navbar>
                <Nav className={"mr-auto " + style.navItemsWrapper} navbar>
                  <NavItem className={style.navItem + " " + _isActive("/curriculum")}>
                    <NavLink
                      href="/"
                      onClick={e => {
                        e.preventDefault();
                        props.history.push("/curriculum");
                      }}
                    >
                      <LanguageParsedText keyString="header.link1" />
                    </NavLink>
                  </NavItem>
                  <NavItem className={style.navItem + " " + _isActive("/resources")}>
                    <NavLink
                      href="/"
                      onClick={e => {
                        e.preventDefault();
                        props.history.push("/resources");
                      }}
                    >
                      Resources
                    </NavLink>
                  </NavItem>
                  {_isDropboxSectionAllowed() ? (
                    <NavItem className={style.navItem + " " + _isActive("/insights")}>
                      <NavLink
                        href="/"
                        onClick={e => {
                          e.preventDefault();
                          props.history.push("/insights");
                        }}
                      >
                        <LanguageParsedText keyString="header.link2" />
                      </NavLink>
                    </NavItem>
                  ) : null}
                  <NavItem className={style.navItem + " " + _isActive("/about-us")}>
                    <NavLink
                      href="/"
                      onClick={e => {
                        e.preventDefault();
                        props.history.push("/about-us");
                      }}
                    >
                      About Us
                    </NavLink>
                  </NavItem>
                </Nav>
                {/* following line of code is necessary for Language option component to show */}
                {/* <LanguageSwitcher /> */}

                {userData ? (
                  <>
                    <Dropdown isOpen={isUserOptionsVisible} toggle={toggleUserOptions}>
                      <DropdownToggle nav caret id={style.headerUserInfoOuterWrapper}>
                        <div id={style.headerUserInfoWrapper} onClick={toggleUserOptions}>
                          {/* <div id={style.userThumbnailWrapper}>
                            <img alt="" src={images.defaultUserThumbnail} />
                          </div> */}
                          <div id={style.userInfoWrapper}>
                            <p>{userData.cognitoUser.attributes["name"]}</p>
                            <p>{userData.cognitoUser.attributes.email}</p>
                          </div>
                          <div>
                            {isUserOptionsVisible ? (
                              <i className="fas fa-chevron-up"></i>
                            ) : (
                              <i className="fas fa-chevron-down"></i>
                            )}
                          </div>
                        </div>
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem onClick={() => props.history.push("/user-settings")}>
                          <i className="fas fa-cog"></i> <LanguageParsedText keyString="header.link3" />
                        </DropdownItem>
                        <DropdownItem onClick={() => logout(props.history)}>
                          <i className="fas fa-sign-out-alt"></i> <LanguageParsedText keyString="header.link4" />
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </>
                ) : null}
              </Collapse>
            </>
          ) : null}
        </Navbar>
      </OutsideClickHandler>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userData: state.userData
  };
};

export default withRouter(connect(mapStateToProps, null)(Header));
