import React from 'react';
import { Button as ReactStrapButton } from 'reactstrap';
import style from "./button.module.scss";

const Button = props => {
	const {
		text = "Button",
		className
	} = props;
	return (  
		<ReactStrapButton {...props} className={style.projectButton + " " + className} >
			{ text }
		</ReactStrapButton>
	);
}
 
export default Button;