import React from 'react';
import * as images from "../../../../assets/images";
import "./form-error.scss";

const FormError = props => {
	const { alertType = "success", alertContent = "Error Occurred" } = props;
	let alertImage = null;
	let wrapperClassName = "";
	switch(alertType) {
		case "success": {
			alertImage = images.successTickIcon;
			wrapperClassName = "successAlert";
			break;
		}
		case "error": {
			alertImage = images.redAlertIcon;
			wrapperClassName = "errorAlert";
			break;
		}
		default: {}
	}
	return (  
		<div className={`formErrorOuterWarpper`}>
			<div className={`formErrorInnerWarpper ${wrapperClassName}`}>
			<div>
				<img alt="" src={alertImage} className="alertIcon" />
			</div>
			<div className="alertText">
				{alertContent}
			</div>
			</div>
		</div>
	);
}
 
export default FormError;