export const chipTheme = {
  chip: {
    padding: "0.6rem 1rem 0.4rem 1rem",
    background: "black",
		margin: "2.5px",
		color: "white",
    borderRadius: 3,
		cursor: 'default',
		fontFamily: "Century Gothic Std Regular",
		fontWeight: "bold"
  },
  chipSelected: {
    background: 'black',
  },
  chipRemove: {
		fontWeight: "bold",
		marginLeft: "1rem",
    cursor: "pointer",
    ':hover': {
      color: 'red',
    }
  }
}