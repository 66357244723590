import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { sleepTime } from "../../../../helper-methods";
import { hideLoader, showLoader } from "../../../../redux/actions/loader-data";
import MobileTopNav from "../../components/mobile-top-nav/mobile-top-nav";
import SideNavMenu from "../../components/side-nav-menu/side-nav-menu";
import ManagePassword from "../../containers/manage-password/manage-password";
import NotificationSettings from "../../containers/notification-settings/notification-settings";
import UserSettings from "../../containers/user-settings/user-settings";
import style from "./user-settings.module.scss";
import { updateUserData } from "../../../../redux/actions/user-data";
//following import is to have a learning progress content on the user settings page
//import LearningProgressManagement from '../../containers/learning-progress-management/learning-progress-management';

class UserSettingsPage extends Component {
  state = {
    navOptions: [
      {
        id: 0,
        iconCode: "fas fa-cog",
        i18nLangKeyString: "userSettingsPage.userSettingsSection.tabText"
      },
      {
        id: 1,
        iconCode: "fas fa-unlock",
        i18nLangKeyString: "userSettingsPage.changePasswordSection.tabText"
      },
      {
        id: 2,
        iconCode: "fas fa-bell",
        i18nLangKeyString: "userSettingsPage.notificationSettingsSection.tabText"
      }
      //following code shows learning progress option in the side nav bar of user settings page
      // {
      //   id: 3,
      //   iconCode: "fas fa-book-open",
      //   i18nLangKeyString: "userSettingsPage.learningProgressSection.tabText"
      // }
    ],
    selectedOptionId: 0
  };

  async componentDidMount() {
    this.props.showLoader("Loading");
    await sleepTime(1200);
    this.props.hideLoader();
  }

  _onOptionUpdate = selectedOptionId => {
    this.setState({ selectedOptionId });
  };

  _renderView = () => {
    const { selectedOptionId } = this.state;
    switch (selectedOptionId) {
      case 0: {
        return <UserSettings {...this.props} />;
      }
      case 1: {
        return <ManagePassword {...this.props} />;
      }
      case 2: {
        return <NotificationSettings {...this.props} />;
      }
      //following code returns learning progress for it to appear in side nav bar of user settings page
      // case 3: {
      //   return <LearningProgressManagement />;
      // }
    }
  };

  render() {
    const { navOptions, selectedOptionId } = this.state;

    return (
      <React.Fragment>
        <main className={style.main}>
          <MobileTopNav options={navOptions} onSelect={this._onOptionUpdate} selectedOptionId={selectedOptionId} />
          <div className={style.userSettingsPageWrapper}>
            <SideNavMenu options={navOptions} onSelect={this._onOptionUpdate} selectedOptionId={selectedOptionId} />
            <div id={style.settingsViewer}>
              <Container fluid={"sm"}>
                <Row xs="1">
                  <Col className={style.userSettingWrapper}>{this._renderView()}</Col>
                </Row>
              </Container>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToDispatch = dispatch => {
  return {
    showLoader: loaderText => dispatch(showLoader(loaderText)),
    hideLoader: () => dispatch(hideLoader()),
    updateUserData: userData => dispatch(updateUserData(userData))
  };
};

const mapStateToProps = state => {
  return {
    userData: state.userData
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(UserSettingsPage);
