import React, { Component } from "react";
import { Jumbotron, Container, Row, Col } from "reactstrap";
import * as images from "../../../../assets/images";
import style from "./home-page.module.scss";
import LanguageParsedText from "../../../../multi-lang/lang-parsed-text/lang-parsed-text";
import { showLoader, hideLoader } from "../../../../redux/actions/loader-data";
import { connect } from "react-redux";
import { sleepTime } from "../../../../helper-methods";
import Button from "../../../general/components/button/button";

class HomePage extends Component {
  state = {
    isDropboxNavigationAllowed: true
  };

  async componentDidMount() {
    this.props.showLoader("Loading");
    await sleepTime(1200);
    this._setDropboxNavigationPermission();
    this.props.hideLoader();
  }

  _setDropboxNavigationPermission = () => {
    const { userData } = this.props;
    let isAllowed = false;
    if (userData) {
      // Allow only user having specified groups
      if (
        userData &&
        userData.cognitoUser &&
        userData.cognitoUser.signInUserSession &&
        userData.cognitoUser.signInUserSession.idToken &&
        userData.cognitoUser.signInUserSession.idToken.payload &&
        userData.cognitoUser.signInUserSession.idToken.payload["cognito:groups"] &&
        userData.cognitoUser.signInUserSession.idToken.payload["cognito:groups"].length
      ) {
        const currentUserGroup = userData.cognitoUser.signInUserSession.idToken.payload["cognito:groups"][0];
        isAllowed = ["Private", "Admin"].includes(currentUserGroup);
      }
    }
    this.setState({ isDropboxNavigationAllowed: isAllowed });
  };

  render() {
    const { isDropboxNavigationAllowed } = this.state;
    return (
      <React.Fragment>
        <main className={style.main}>
          <Jumbotron>
            <Container fluid="sm" className={style.jumbotron}>
              <Row xs="1" sm="2">
                <Col className={style.jumbotronTextWrapper}>
                  <h1>
                    <LanguageParsedText keyString="home.jumbotron.bigHeading" />
                  </h1>
                  <p>
                    <LanguageParsedText keyString="home.jumbotron.smallHeading" />
                  </p>
                </Col>
                <Col className={style.landingImageWrapper}>
                  {/* <img alt="" src={images.horizonatalBlackLogo} /> */}
                </Col>
              </Row>
            </Container>
          </Jumbotron>
          {/* Advantages and characteristics section (start) */}
          <Container id={style.advAndCharWrapper} fluid="sm">
            <Row xs="1">
              <Col id={style.advAndCharHeader}>
                <h1>Pharos can:</h1>
              </Col>
            </Row>
            <Row xs="1" md="2" lg="3">
              <Col className={style.advantages}>
                {/* <div className={style.imagePart}>
                  <img alt="" src={images.smallPhotoThumbnail} />
                </div> */}
                <div className={style.textPart}>
                  <h5>Train and Educate</h5>
                  <h6>
                    Pharos provides a comprehensive curriculum on MDA and issues of maritime security in Southeast Asia
                  </h6>
                </div>
              </Col>
              <Col className={style.advantages}>
                {/* <div className={style.imagePart}>
                  <img alt="" src={images.smallPhotoThumbnail} />
                </div> */}
                <div className={style.textPart}>
                  <h5>Provide Resources and Tools</h5>
                  <h6>
                    Pharos provides an up-to-date list of resources that will help users conduct maritime analysis and
                    stay ahead of maritime industry news
                  </h6>
                </div>
              </Col>
              <Col className={style.advantages}>
                {/* <div className={style.imagePart}>
                  <img alt="" src={images.smallPhotoThumbnail} />
                </div> */}
                <div className={style.textPart}>
                  <h5>Share Analytical Insight</h5>
                  <h6>
                    Pharos brings you completed analytical products on MDA from C4ADS, where we specialize on a variety
                    of maritime security issues
                  </h6>
                </div>
              </Col>
            </Row>
          </Container>
          {/* Advantages and characteristics section (end) */}
          {/* About us section (start) */}
          <Container fluid="sm" id={style.aboutUsWrapper}>
            <Row xs="1">
              <Col>
                <h1>Pharos and Maritime Domain Awareness</h1>
              </Col>
              {/* <Col id={style.aboutUsTextWrapper}>
                <p>
                  <LanguageParsedText keyString="home.aboutUs.subHeading" />
                </p>
              </Col> */}
            </Row>
            {/* <Row sm="1" xs="1" lg="2" className={style.aboutUsImageAtRight}>
              <Col className={style.textPart}>
                <p>
                  <LanguageParsedText keyString="home.aboutUs.block1" />
                </p>
              </Col>
              <Col className={style.imagePart}>
                <div>
                  <img alt="" src={images.smallPhotoThumbnail} />
                </div>
              </Col>
            </Row> */}
            <Row sm="1" xs="1" className={style.aboutUsFullText}>
              <Col className={style.textPart}>
                <p>
                  With approximately three quarters of the Earth’s surface covered by the oceans and seas, the maritime
                  domain is a nexus of global economic activity. Over 90% of the world’s trade is carried by sea, and
                  the maritime domain is rich in resources, such as fish, oil, and natural gas. Given the vast nature of
                  the oceans however, they are vulnerable to exploitation, and the maritime domain is often both a
                  medium and the setting of illicit activity and geopolitics. Awareness of security in the maritime
                  domain is therefore important for all littoral states.
                </p>
                <p>
                  The Indo-Pacific is one of the quickest growing and economically vibrant regions of the world. Over a
                  third of the world’s total trade and energy transit the Indo-Pacific, and the region is home to some
                  of the busiest shipping in the world, such as the Strait of Malacca and the Taiwan Strait. However,
                  this region also faces challenges in the maritime domain, such as illicit trafficking and maritime
                  crime, piracy and armed robbery at sea, and gray zone geopolitical power posturing in and around
                  contended maritime borders.
                </p>
              </Col>
            </Row>
            <Row xs="1">
              <Col>
                <h1>What is Pharos?</h1>
              </Col>
            </Row>
            <Row sm="1" xs="1" className={style.aboutUsFullText}>
              <Col className={style.textPart}>
                <p>
                  Pharos is a platform for enhancing maritime domain awareness (MDA). The oceans are vast and have
                  traditionally been undergoverned, especially on the high seas. It is not always possible to have
                  physical visibility over both domestic and international maritime activity, but new technologies and
                  increased access to data are allowing greater macro and micro level insight into activities at sea.
                  This platform is a free tool designed to guide and educate users on effectively leveraging publicly
                  accessible data and tools to strengthen MDA practices.
                </p>
              </Col>
            </Row>
            <Row xs="1">
              <Col>
                <h2>Curriculum</h2>
              </Col>
            </Row>
            <Row sm="1" xs="1" className={style.aboutUsFullText}>
              <Col className={style.textPart}>
                <p>
                  The Pharos training curriculum guide the user through several educational modules, complete with case
                  studies and quizzes:
                </p>
                <ul>
                  <li>
                    <strong>Section 1</strong> of the Pharos curriculum provides an introduction to the concepts of MDA.
                    Maritime activity is often not just contained to the work of “maritime agencies” - they often impact
                    several areas of policymaking, law enforcement, the economy, and security. In order to establish a
                    common basis of understanding for every user, regardless of their background, the curriculum begins
                    by covering key ideas and terminology.
                  </li>
                  <li>
                    <strong>Sections 2 to 5</strong> cover specific maritime subject areas, such as illegal, unreported,
                    and unregulated (IUU fishing), piracy, and sanctions evasions that occur at sea. These sections
                    provide deeper dives on each of these issue areas that impact the security, safety, and economy of
                    the seas in Southeast Asia.
                  </li>
                  {/* <li><strong>Section 6</strong> provides a step-by-step guide to conducting investigations on specific targets or analyzing activity trends, as well as tips and tricks on staying informed on current MDA issues.</li> */}
                </ul>
              </Col>
            </Row>
            <Row xs="1">
              <Col>
                <h2>Resources</h2>
              </Col>
            </Row>
            <Row sm="1" xs="1" className={style.aboutUsFullText}>
              <Col className={style.textPart}>
                <p>
                  Pharos also provides a separate list of resources for users to reference when conducting MDA. This
                  list provides direct links to sources of publicly available information (such as national-level
                  corporate registries), media sites, industry-specific advisories, and expert analysis.
                </p>
              </Col>
            </Row>
            <Row xs="1">
              <Col>
                <h2>Insights</h2>
              </Col>
            </Row>
            <Row sm="1" xs="1" className={style.aboutUsFullText}>
              <Col className={style.textPart}>
                <p>
                  Available only for premium users, this is a repository of maritime analytical findings and completed
                  products created by C4ADS. These materials are compiled from publicly available information, and are
                  designed to be shared through this platform to facilitate law enforcement action by our local partners
                  in the region and to inform policymakers and industry stakeholders.
                </p>
              </Col>
            </Row>
            {/* <Row sm="1" xs="1" lg="2" className={style.aboutUsImageAtLeft}>
              <Col className={style.imagePart}>
                <div>
                  <img alt="" src={images.smallPhotoThumbnail} />
                </div>
              </Col>
              <Col className={style.textPart}>
                <p>
                  <LanguageParsedText keyString="home.aboutUs.block3" />
                </p>
              </Col>
            </Row> */}
          </Container>
          {/* About us section (end) */}
          {/* Material section (start) */}
          {/* <Container fluid={true} id={style.materialOuterWrapper}>
            <Row sm="1">
              <Col>
                <Container fluid={false} id={style.materialInnerWrapper}>
                  <Row sm="1" xs="1" lg={isDropboxNavigationAllowed ? "2" : "1"}>
                    <Col id={style.curriculumCardWrapper}>
                      <div className={style.materialCard}>
                        <div className={style.materialCardIconWrapper}>
                          <img alt="" src={images.gradCapIcon} />
                        </div>
                        <h5>
                          <LanguageParsedText keyString="home.materials.block1.heading" />
                        </h5>
                        <p>
                          <LanguageParsedText keyString="home.materials.block1.details" />
                        </p>
                        <div>
                          <Button
                            className={style.actionButton}
                            text={<LanguageParsedText keyString="home.materials.block1.buttonText" />}
                            onClick={() => this.props.history.push("/curriculum")}
                          />
                        </div>
                      </div>
                    </Col>
                    {isDropboxNavigationAllowed ? (
                      <Col id={style.dropBoxCardWrapper}>
                        <div className={style.materialCard}>
                          <div className={style.materialCardIconWrapper}>
                            <img alt="" src={images.dropBoxIcon} />
                          </div>
                          <h5>
                            <LanguageParsedText keyString="home.materials.block2.heading" />
                          </h5>
                          <p>
                            <LanguageParsedText keyString="home.materials.block2.details" />
                          </p>
                          <div>
                            <Button
                              className={style.actionButton}
                              text={<LanguageParsedText keyString="home.materials.block2.buttonText" />}
                              onClick={() => this.props.history.push("/insights")}
                            />
                          </div>
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container> */}
          {/* Material section (end) */}
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToDispatch = dispatch => {
  return {
    showLoader: loaderText => dispatch(showLoader(loaderText)),
    hideLoader: () => dispatch(hideLoader())
  };
};

const mapStateToProps = state => {
  return {
    userData: state.userData
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(HomePage);
