import React, { useState } from "react";
import Chips from "react-chips";
import { chipTheme } from "./chip-theme";
import { disabledChipTheme } from "./disabled-chip-theme";
import theme from "./chips-theme";
import themeWithDisabledInput from "./disabled-chips-theme";
import "./filter-chips.scss";

const FilterChips = props => {
  const { allOptions, selectedOptions, onUpdate, canCreateNew = false, placeholder = "Add Filters", disabled = false } = props;

  const _onUpdate = filters => {
    onUpdate(filters);
  };

  return (
    <div id="filterWrapper">
      <Chips
        value={selectedOptions}
        onChange={_onUpdate}
        chipTheme={disabled? disabledChipTheme: chipTheme}
        theme={disabled? themeWithDisabledInput :theme}
        suggestions={allOptions}
        highlightFirstSuggestion={true}
        placeholder={placeholder}
        uniqueChips={true}
        createChipKeys={[9, 13]}
        fromSuggestionsOnly={!canCreateNew}
        alwaysRenderSuggestions={false}
        focusInputOnSuggestionClick={true}
      />
    </div>
  );
};

export default FilterChips;
