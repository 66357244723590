import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { hideLoader, showLoader } from "../../../../redux/actions/loader-data";
import Button from "../../../general/components/button/button";
import DropboxUploadModal from "../../components/dropbox-upload-modal/dropbox-upload-modal";
import FilterChips from "../../components/filter-chips/filter-chips";
import "./dropbox-page.scss";
import DropboxTable from "../../components/dropbox-table/dropbox-table";
import DropboxFileDetailsViewer from "../../components/dropbox-file-details-viewer/dropbox-file-details-viewer";
import { fetchDropboxTopics } from "../../../../http-calls";
import { fetchDropboxFiles } from "../../../../http-calls/index";
import cuid from "cuid";
import DropboxFileDetailsModal from "../../components/dropbox-file-details-modal/dropbox-file-details-modal";
import { getAllReports, deleteReport } from "../../../../api-calls/index";
import { scrollToTop } from "../../../../helper-methods";
import LanguageParsedText, { getValueFromCurrentLang } from "../../../../multi-lang/lang-parsed-text/lang-parsed-text";
import PromptDialog from "../../components/prompt-dialog/prompt-dialog";
import { showToast } from "../../../../helper-methods/index";

const initialState = {
  uploadModal: {
    isVisible: false,
    isEditMode: false
  },
  isDeletePromptVisible: false,
  detailsModal: {
    isVisible: false
  },
  topics: [],
  filterTopics: [],
  files: [],
  filteredFiles: [],
  selectedFile: null
};

class DropBoxPage extends Component {
  state = initialState;

  async componentDidMount() {
    scrollToTop();
    this.props.showLoader("Loading");
    await this._fetchFiles();
    this._filterFiles();
    this._selectFirstFile();
    this.props.hideLoader();
  }

  _setTopics = topics => {
    return new Promise(async (resolve, reject) => {
      this.setState({ topics }, () => {
        resolve();
      });
    });
  };

  _formatFiles = files => {
    return files.map(file => {
      return {
        link: file.url,
        topics: file.tags,
        description: file.description && file.description.length ? file.description : "--",
        modifiedAt: file.updated_at,
        title: file.title,
        fileId: cuid(),
        ...file
      };
    });
  };

  _setFiles = files => {
    return new Promise(async (resolve, reject) => {
      this.setState({ files: this._formatFiles(files) }, () => {
        resolve();
      });
    });
  };

  _selectFirstFile = () => {
    this.setState({ selectedFile: this.state.filteredFiles[0] });
  };

  _fetchFiles = async () => {
    try {
      const response = await getAllReports();
      await this._setTopics(response.tags);
      await this._setFiles(response.reports);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  _onDropboxUpload = async () => {
    this._hideDropboxModal();
    this.props.showLoader("Refreshing");
    await this._fetchFiles();
    this._filterFiles();
    this._selectFirstFile();
    this.props.hideLoader();
  };

  _editSelectedFile = () => {
    this._hideDetailsModal();
    const { uploadModal } = this.state;
    uploadModal.isEditMode = true;
    uploadModal.isVisible = true;
    this.setState({ uploadModal });
  };

  _deleteSelectedFile = () => {
    this._hideDetailsModal();
    this.setState({ isDeletePromptVisible: true });
  };

  _onDelete = async () => {
    this._dismissDeletePrompt();
    // Delete selected file
    this.props.showLoader("Removing report");
    try {
      await deleteReport(this.state.selectedFile.id);
      this.props.showLoader("Refreshing");
      await this._fetchFiles();
      this._filterFiles();
      this._selectFirstFile();
      this.props.hideLoader();
      showToast("Report deleted successfully", "success");
    } catch (error) {
      console.log("error :>> ", error);
      this.props.hideLoader();
      showToast("Report not deleted", "error");
    }
  };

  _dismissDeletePrompt = () => {
    this.setState({ isDeletePromptVisible: false });
  };

  _hideDropboxModal = () => {
    const { uploadModal } = this.state;
    uploadModal.isVisible = false;
    uploadModal.isEditMode = false;
    this.setState({ uploadModal });
  };

  _showDropboxModal = () => {
    const { uploadModal } = this.state;
    uploadModal.isVisible = true;
    this.setState({ uploadModal });
  };

  _hideDetailsModal = () => {
    const { detailsModal } = this.state;
    detailsModal.isVisible = false;
    this.setState({ detailsModal });
  };

  _showDetailsModal = () => {
    const { detailsModal } = this.state;
    detailsModal.isVisible = true;
    this.setState({ detailsModal });
  };

  _updateFilters = filterTopics => {
    this.setState({ filterTopics }, () => {
      this._filterFiles();
    });
  };

  _filterFiles = () => {
    const { files, filterTopics } = this.state;
    let filteredFiles = files;
    if (filterTopics && filterTopics.length) {
      filteredFiles = filteredFiles.filter(file => {
        var result = filterTopics.every(val => {
          return file.topics.indexOf(val) >= 0;
        });
        if (result) {
          return true;
        }
      });
    }
    let { selectedFile } = this.state;
    if (!filteredFiles.length) {
      selectedFile = null;
    }
    this.setState({ filteredFiles, selectedFile });
  };

  _onFileSelectionUpdate = selectedFile => {
    this.setState({ selectedFile }, () => {
      this._showDetailsModal();
    });
  };

  _canAlterFiles = () => {
    const { userData } = this.props;
    let isAllowed = false;
    if (userData) {
      // Allow only user having specified groups
      if (
        userData &&
        userData.cognitoUser &&
        userData.cognitoUser.signInUserSession &&
        userData.cognitoUser.signInUserSession.idToken &&
        userData.cognitoUser.signInUserSession.idToken.payload &&
        userData.cognitoUser.signInUserSession.idToken.payload["cognito:groups"] &&
        userData.cognitoUser.signInUserSession.idToken.payload["cognito:groups"].length
      ) {
        const currentUserGroup = userData.cognitoUser.signInUserSession.idToken.payload["cognito:groups"][0];
        isAllowed = ["Admin"].includes(currentUserGroup);
      }
    }
    return isAllowed;
  };

  render() {
    const {
      uploadModal,
      topics,
      filterTopics,
      filteredFiles,
      selectedFile,
      detailsModal,
      isDeletePromptVisible
    } = this.state;

    return (
      <>
        <div id="dropboxPageWrapper">
          <Container fluid={true}>
            <Row>
              <Col md="6" id="pageHeader">
                <h3>Insights</h3>
                <h6>
                  {/* <LanguageParsedText keyString="dropBoxPage.subHeader" /> */}
                  C4ADS investigates cases of maritime sanctions evasion, crime, and illicit activity. Click below to
                  download and explore prepared investigations to better understand how open information can be used to
                  assess maritime risk.
                </h6>
              </Col>
              <Col md="6" id="uploadWrapper">
                {this._canAlterFiles() ? (
                  <Button
                    className="uploadButton"
                    onClick={this._showDropboxModal}
                    text={
                      <>
                        <i className="fas fa-upload"></i>&nbsp;{" "}
                        <LanguageParsedText keyString="dropBoxPage.uploadButtonText" />
                      </>
                    }
                  />
                ) : null}
                <a href="mailto:info@pharos.training" target="_blank" className="contactButton">
                  <Button text="Contact Us"></Button>
                </a>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FilterChips
                  canCreateNew={false}
                  allOptions={topics}
                  placeholder={getValueFromCurrentLang("dropBoxPage.addFilterPlaceHolder")}
                  selectedOptions={filterTopics}
                  onUpdate={this._updateFilters}
                />
              </Col>
            </Row>
            <Row>
              <Col md={selectedFile ? "8" : "12"}>
                <DropboxTable
                  files={filteredFiles}
                  onSelect={this._onFileSelectionUpdate}
                  selectedFile={selectedFile}
                />
              </Col>
              <Col md="4">
                <DropboxFileDetailsViewer
                  selectedFile={selectedFile}
                  onEdit={this._editSelectedFile}
                  onDelete={this._deleteSelectedFile}
                  hasEditPermission={this._canAlterFiles()}
                  {...this.props}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <DropboxUploadModal
          {...this.props}
          topics={topics}
          isVisible={uploadModal.isVisible}
          dismissModal={this._hideDropboxModal}
          onUpload={this._onDropboxUpload}
          selectedFile={selectedFile}
          isEditMode={uploadModal.isEditMode}
        />
        <DropboxFileDetailsModal
          {...this.props}
          isVisible={detailsModal.isVisible}
          dismissModal={this._hideDetailsModal}
          selectedFile={selectedFile}
          onEdit={this._editSelectedFile}
          onDelete={this._deleteSelectedFile}
          hasEditPermission={this._canAlterFiles()}
        />
        <PromptDialog
          headerText="Confirmation"
          bodyText="Are you sure to delete the report"
          onConfirm={this._onDelete}
          onCancel={this._dismissDeletePrompt}
          isVisible={isDeletePromptVisible}
        />
      </>
    );
  }
}

const mapStateToDispatch = dispatch => {
  return {
    showLoader: loaderText => dispatch(showLoader(loaderText)),
    hideLoader: () => dispatch(hideLoader())
  };
};

const mapStateToProps = state => {
  return {
    userData: state.userData
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(DropBoxPage);
