import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Container, FormGroup, Row } from "reactstrap";
import * as images from "../../../../assets/images";
import { sleepTime } from "../../../../helper-methods";
import { hideLoader, showLoader } from "../../../../redux/actions/loader-data";
import { updateUserData } from "../../../../redux/actions/user-data";
import Button from "../../../general/components/button/button";
import FormError from "../../../general/components/form-error/form-error";
import InputField from "../../../general/components/input-field/input-field";
import PhoneNumberInput from "../../../general/components/phone-number-input/phone-number-input";
import "./signup-page.scss";
import LanguageParsedText, { getValueFromCurrentLang } from "../../../../multi-lang/lang-parsed-text/lang-parsed-text";
import { registerUser } from "../../../../aws-cognito/index";
import cuid from "cuid";
import { showToast, checkIfPasswordIsProper } from "../../../../helper-methods/index";

class SignUpPage extends Component {
  state = {
    formFields: {
      fullName: {
        value: "",
        isValid: false,
        isDirty: false,
        isRequired: true,
        errorMessage: ""
      },
      organization: {
        value: "",
        isValid: false,
        isDirty: false,
        isRequired: true,
        errorMessage: ""
      },
      jobTitle: {
        value: "",
        isValid: false,
        isDirty: false,
        isRequired: true,
        errorMessage: ""
      },
      phone: {
        value: "",
        isValid: false,
        isDirty: false,
        isRequired: true,
        errorMessage: ""
      },
      email: {
        value: "",
        isValid: false,
        isDirty: false,
        isRequired: true,
        errorMessage: ""
      },
      password: {
        value: "",
        isValid: false,
        isDirty: false,
        isRequired: true,
        errorMessage: ""
      },
      confirmPassword: {
        value: "",
        isValid: false,
        isDirty: false,
        isRequired: true,
        errorMessage: ""
      }
    },
    isFormValid: false,
    responseError: "",
    isDisclaimerChecked: false,
    countryCode: "+1"
  };

  _markAsDirty = fieldName => {
    const { formFields } = this.state;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields });
    this._validateForm();
    this._hideResponseError();
  };

  _updateFieldValue = (fieldName, value) => {
    this._hideResponseError();
    const { formFields } = this.state;
    formFields[fieldName].value = value;
    formFields[fieldName].isValid = true;
    this.setState({ formFields });
    if (formFields[fieldName].isDirty) {
      // Validate
      this._validateForm();
    }
  };

  _makeAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((fieldName, index) => {
        formFields[fieldName].isDirty = true;
      });
      this.setState({ formFields }, () => {
        resolve();
      });
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      let isFormValid = true;
      Object.keys(formFields).forEach((fieldName, index) => {
        const field = formFields[fieldName];
        if (field.isRequired || field.value.length) {
          switch (fieldName) {
            case "fullName": {
              if (field.value.length) {
                field.isValid = true;
              } else {
                field.isValid = false;
                isFormValid = false;
                field.errorMessage = <LanguageParsedText keyString="signUpPage.fields.name.errorMessage" />;
              }
              break;
            }
            case "organization": {
              if (field.value.length) {
                field.isValid = true;
              } else {
                field.isValid = false;
                isFormValid = false;
                field.errorMessage = <LanguageParsedText keyString="signUpPage.fields.organization.errorMessage" />;
              }
              break;
            }
            case "jobTitle": {
              if (field.value.length) {
                field.isValid = true;
              } else {
                field.isValid = false;
                isFormValid = false;
                field.errorMessage = <LanguageParsedText keyString="signUpPage.fields.jobTitle.errorMessage" />;
              }
              break;
            }
            case "phone": {
              if (field.value.length > 5) {
                field.isValid = true;
              } else {
                field.isValid = false;
                isFormValid = false;
                field.errorMessage = <LanguageParsedText keyString="signUpPage.fields.phone.errorMessage" />;
              }
              break;
            }
            case "email": {
              var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              if (emailRegex.test(String(field.value).toLowerCase())) {
                field.isValid = true;
              } else {
                field.isValid = false;
                isFormValid = false;
                field.errorMessage = <LanguageParsedText keyString="signUpPage.fields.email.errorMessage" />;
              }
              break;
            }
            case "password": {
              if (checkIfPasswordIsProper(field.value)) {
                field.isValid = true;
              } else {
                field.isValid = false;
                isFormValid = false;
                field.errorMessage =
                  "Minimum Password length is 6 and should contain minimum one uppercase, one lowercase, one digit and one special charecter";
              }
              break;
            }
            case "confirmPassword": {
              if (field.value.length) {
                // Input length is > 0
                // Now check if it matches with password
                if (formFields.password.value === field.value) {
                  field.isValid = true;
                } else {
                  // Didn't match
                  field.isValid = false;
                  isFormValid = false;
                  field.errorMessage = (
                    <LanguageParsedText keyString="signUpPage.fields.confirmPassword.errorMessage.didntMatch" />
                  );
                }
              } else {
                // Input Length is 0
                field.isValid = false;
                isFormValid = false;
                field.errorMessage = (
                  <LanguageParsedText keyString="signUpPage.fields.confirmPassword.errorMessage.cannotBeEmpty" />
                );
              }
              break;
            }
            default: {
            }
          }
        }
      });
      this.setState({ formFields, isFormValid }, () => {
        resolve();
      });
    });
  };

  _generateUserForCognito = () => {
    const { formFields, countryCode } = this.state;
    console.log("parseInt(countryCode) :>> ", parseInt(countryCode));
    const user = {
      password: formFields.password.value,
      username: formFields.email.value.split("@")[0],
      attributes: {
        email: formFields.email.value,
        updated_at: ((Date.now() / 1000) | 0).toString(),
        "custom:created_at": ((Date.now() / 1000) | 0).toString(),
        name: formFields.fullName.value,
        "custom:job_title": formFields.jobTitle.value,
        "custom:organization": formFields.organization.value,
        phone_number: countryCode + formFields.phone.value,
        "custom:country_code": countryCode
      }
    };
    return user;
  };

  _register = async e => {
    e.preventDefault();
    this._makeAllFieldDirty();
    await this._validateForm();
    const { formFields, isFormValid, isDisclaimerChecked } = this.state;
    if (isFormValid) {
      if (isDisclaimerChecked) {
        try {
          this.props.showLoader(<LanguageParsedText keyString="signUpPage.loaderTexts.creatingAccount" />);
          // Add user to cognito
          const user = this._generateUserForCognito();
          await registerUser(user);
          // User added successfully
          // Redirect to welcome page
          // showToast("Successfully registered, you will receive verification mail shortly", "success");
          this.props.history.replace("/auth/welcome");
          this.props.hideLoader();
        } catch (loginError) {
          this.props.hideLoader();
          this._showResponseError(
            loginError && loginError.message && loginError.message.length ? (
              loginError.message
            ) : (
              <LanguageParsedText keyString="signUpPage.formErrors.loginServerError" />
            )
          );
        }
      } else {
        this._showResponseError(getValueFromCurrentLang("signUpPage.formErrors.acceptTerms"));
      }
    }
  };

  _showResponseError = errorText => {
    this.setState({ responseError: errorText });
  };

  _hideResponseError = () => {
    const { responseError } = this.state;
    if (responseError.length) {
      this.setState({ responseError: "" });
    }
  };

  _toggleDisclaimerCheckBox = e => {
    const { isDisclaimerChecked } = this.state;
    this.setState({ isDisclaimerChecked: !isDisclaimerChecked });
    this._hideResponseError();
  };

  _updateCountryCode = countryCode => {
    this.setState({ countryCode });
  };

  render() {
    const { formFields, responseError, isDisclaimerChecked, countryCode } = this.state;
    return (
      <React.Fragment>
        <div id="signupPageWarpper">
          <Container fluid={true}>
            <Row>
              <Col lg="4" sm="12" className="imageWrapper">
                <img alt="" src={require("../../../../assets/images/files/Pharos Logo Final_Square Blue.svg")} />
              </Col>
              <Col lg="8" sm="12" className="formWrapper">
                <div className="formContainer">
                  <Row>
                    <Col id="signupWrapper">
                      <h1>
                        <LanguageParsedText keyString="signUpPage.header" />
                      </h1>
                      <p>
                        <LanguageParsedText keyString="signUpPage.subHeader" />
                      </p>
                      <form onSubmit={e => this._login(e)}>
                        <Row>
                          <Col lg="6" sm="12">
                            <FormGroup className="smallFormGroup">
                              <label>
                                <LanguageParsedText keyString="signUpPage.fields.name.label" />{" "}
                                <span className="requiredStar">*</span>
                              </label>
                              <InputField
                                type="text"
                                value={formFields.fullName.value}
                                onChange={e => this._updateFieldValue("fullName", e.target.value)}
                                onBlur={() => this._markAsDirty("fullName")}
                              />
                              {formFields.fullName.isDirty && !formFields.fullName.isValid ? (
                                <p className="errorText">{formFields.fullName.errorMessage}</p>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col lg="6" sm="12">
                            <FormGroup className="smallFormGroup">
                              <label>
                                <LanguageParsedText keyString="signUpPage.fields.organization.label" />{" "}
                                <span className="requiredStar">*</span>
                              </label>
                              <InputField
                                type="text"
                                value={formFields.organization.value}
                                onChange={e => this._updateFieldValue("organization", e.target.value)}
                                onBlur={() => this._markAsDirty("organization")}
                              />
                              {formFields.organization.isDirty && !formFields.organization.isValid ? (
                                <p className="errorText">{formFields.organization.errorMessage}</p>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6" sm="12">
                            <FormGroup className="smallFormGroup">
                              <label>
                                <LanguageParsedText keyString="signUpPage.fields.jobTitle.label" />{" "}
                                <span className="requiredStar">*</span>
                              </label>
                              <InputField
                                type="text"
                                value={formFields.jobTitle.value}
                                onChange={e => this._updateFieldValue("jobTitle", e.target.value)}
                                onBlur={() => this._markAsDirty("jobTitle")}
                              />
                              {formFields.jobTitle.isDirty && !formFields.jobTitle.isValid ? (
                                <p className="errorText">{formFields.jobTitle.errorMessage}</p>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col lg="6" sm="12">
                            <FormGroup className="smallFormGroup">
                              <label>
                                <LanguageParsedText keyString="signUpPage.fields.phone.label" />
                                <span className="requiredStar">*</span>
                              </label>
                              <PhoneNumberInput
                                countryCode={countryCode}
                                updateCountryCode={this._updateCountryCode}
                                phone={formFields.phone.value}
                                updatePhoneNumber={phone => this._updateFieldValue("phone", phone)}
                                onBlur={() => this._markAsDirty("phone")}
                              />

                              {formFields.phone.isDirty && !formFields.phone.isValid ? (
                                <p className="errorText">{formFields.phone.errorMessage}</p>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12" sm="12">
                            <FormGroup className="smallFormGroup">
                              <label>
                                <LanguageParsedText keyString="signUpPage.fields.email.label" />{" "}
                                <span className="requiredStar">*</span>
                              </label>
                              <InputField
                                type="text"
                                value={formFields.email.value}
                                onChange={e => this._updateFieldValue("email", e.target.value)}
                                onBlur={() => this._markAsDirty("email")}
                              />
                              {formFields.email.isDirty && !formFields.email.isValid ? (
                                <p className="errorText">{formFields.email.errorMessage}</p>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6" sm="12">
                            <FormGroup className="smallFormGroup">
                              <label>
                                <LanguageParsedText keyString="signUpPage.fields.password.label" />{" "}
                                <span className="requiredStar">*</span>
                              </label>
                              <InputField
                                type="password"
                                value={formFields.password.value}
                                onChange={e => this._updateFieldValue("password", e.target.value)}
                                onBlur={() => this._markAsDirty("password")}
                              />
                              {formFields.password.isDirty && !formFields.password.isValid ? (
                                <p className="errorText">{formFields.password.errorMessage}</p>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col lg="6" sm="12">
                            <FormGroup className="smallFormGroup">
                              <label>
                                <LanguageParsedText keyString="signUpPage.fields.confirmPassword.label" />{" "}
                                <span className="requiredStar">*</span>
                              </label>
                              <InputField
                                type="password"
                                value={formFields.confirmPassword.value}
                                onChange={e => this._updateFieldValue("confirmPassword", e.target.value)}
                                onBlur={() => this._markAsDirty("confirmPassword")}
                              />
                              {formFields.confirmPassword.isDirty && !formFields.confirmPassword.isValid ? (
                                <p className="errorText">{formFields.confirmPassword.errorMessage}</p>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>
                      </form>
                      <div id="signupHelperPanel">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            className="checkbox__input"
                            checked={isDisclaimerChecked}
                            onChange={this._toggleDisclaimerCheckBox}
                          />
                          <span className="checkbox__checkmark"></span>
                          <span className="disclaimerText">
                            I have read and agree to the <Link to="/terms-and-conditions">Terms and Conditions</Link>{" "}
                            and <Link to="/privacy-policy">Privacy Policy</Link>.
                            {/* <LanguageParsedText keyString="signUpPage.terms" parseHtml /> */}
                          </span>
                        </label>
                      </div>
                      {responseError.length ? <FormError alertType={"error"} alertContent={responseError} /> : null}
                      <Button
                        className="signupBtn"
                        text={<LanguageParsedText keyString="signUpPage.submitButton" />}
                        onClick={this._register}
                        disabled={!isDisclaimerChecked}
                      />
                      <div id="loginLink">
                        <p onClick={() => this.props.history.push("/auth/login")}>
                          <LanguageParsedText keyString="signUpPage.loginLink" />
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToDispatch = dispatch => {
  return {
    showLoader: loaderText => dispatch(showLoader(loaderText)),
    hideLoader: () => dispatch(hideLoader()),
    updateUserData: userData => dispatch(updateUserData(userData))
  };
};

export default connect(null, mapStateToDispatch)(SignUpPage);
