import React, { Component } from "react";
import "./terms-and-condititions.scss";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";
import Header from "../../../private/containers/header/header";
import Footer from "../../../private/containers/footer/footer";

class TermsAndConditionsPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Header {...this.props} />
        <Container fluid="sm" id="pageWrapper">
          <h1>Terms of Service</h1>
          <h2>1. Terms</h2>
          <p>
            The Center for Advanced Defense Studies, Inc. (hereinafter "C4ADS/Pharos", "we", "us", or "our"), having its
            registered address at 1201 I Street NW, Suite 200, Washington, DC, provides training on common maritime
            illicit activity typologies throughout the world (the “Services”), accessible by using our website,
            currently www.pharos.training (the "Website").
          </p>
          <p>
            These Terms of Service (the "Terms") set forth the legally binding terms and conditions governing any use of
            the Services (as defined below) provided by C4ADS. By using the Services, you acknowledge your understanding
            of, and agreement to, these Terms, which also incorporate our Privacy Policy. Any use of the Service is
            subject to these Terms. We may, from time to time, revise these Terms. Your continued use of the Services
            after the posting of any revisions will constitute your agreement to be bound by such revisions.
          </p>
          <p>
            Please read these Terms carefully before using the Services. If you do not agree to these Terms, you do not
            have the right to use the Services.
          </p>
          <h2>2. Registration process</h2>
          <p>
            Registered users of the Services are able to use certain additional services and functionality and may
            access additional data [as further explained in the service description].
          </p>
          <p>
            During registration you will be asked to provide your e-mail address and password. You are responsible for
            providing accurate and correct registration information and we assume no liability for any error, inaccuracy
            or omission in such information or in the service resulting from any information provided by you. By
            becoming a registered user of our Services, you will also be prompted to give your consent to our processing
            of your personal data in accordance with our <Link to="/privacy-policy">Privacy Policy</Link>.
          </p>
          <p>
            If you are a physical person, you must be over 18 years of age in order to register an account for the
            services.
          </p>
          <p>
            Upon completing the registration process, you will receive a confirmation e-mail containing your chosen
            information to the e-mail address you provided.
          </p>
          <p>
            This license will automatically terminate if you violate any of these restrictions and may be terminated by
            C4ADS at any time. Upon terminating your viewing of these materials or upon the termination of this license,
            you must destroy any downloaded materials in your possession whether in electronic or printed format.
          </p>
          <h2>3. User Account Security</h2>
          <p>
            Each user is personally and solely responsible for all actions and transactions made through his/her
            account. You are responsible for maintaining the confidentiality of your password and you should ensure that
            you exit properly from your account at the end of each session. You agree to notify C4ADS immediately if you
            suspect any unauthorized use of your account or access to your password or account.
          </p>
          <p>If you wish to deactivate your account please contact us by using info@pharos.training.</p>
          <h2>4. License</h2>
          <p>
            Subject to your compliance with these Terms, we grant you a non-exclusive, non-transferable right to access
            and use the Services. Unless otherwise stated in these Terms, this right is granted for your personal,
            non-commercial use only. Permission is granted to temporarily download one copy of the materials
            (information or software) from the Services for personal, non-commercial transitory viewing only. This is
            the grant of a license, not a transfer of title, and under this license you may not:
          </p>
          <ul>
            <li>Modify or copy the materials;</li>
            <li>
              Use the materials for any commercial purpose, or for any public display (commercial or non-commercial);
            </li>
            <li>Attempt to decompile or reverse engineer any software contained on the Services;</li>
            <li>Remove any copyright or other proprietary notations from the materials; or</li>
            <li>Transfer the materials to another person or "mirror" the materials on any other server.</li>
          </ul>
          <p>
            This license and the right to access the Services shall automatically terminate if you violate any provision
            of these Terms. Upon the termination of this license, you must destroy any downloaded materials in your
            possession whether in electronic or printed format.
          </p>
          <h2>5. Trademarks and Intellectual Property Rights</h2>
          <p>
            Ownership and all intellectual property rights in the Services or any content on the Services, including but
            not limited to patents, design rights, copyrights, trademarks, trade-secrets and proprietary know-how, shall
            be owned by and vested in us, or our licensor, and nothing in these Terms shall be interpreted as a transfer
            of such rights from us to You. You are solely entitled to the limited license to the Services specifically
            granted under these Terms.
          </p>
          <p>
            Specifically, the "Pharos/C4ADS" trademark and all trademarks appearing on the Services are property of us
            or our licensors and are protected by applicable laws. Use of these trademarks is by all means prohibited.
          </p>
          <h2>6. Unauthorized Use/No Interference</h2>
          <p>
            You agree that you will not use or attempt to use any method, device, software or routine to harm others or
            interfere with functioning of the Services or use and/or monitor any information in or related to the
            Services for any unauthorized purpose. Specifically, each user, whether authorized or unauthorized, agrees
            that it will not (and will not permit any of its employees, agents, and any other third party to) do any of
            the following:
          </p>
          <ul>
            <li>
              Use the Services or any data contain therein or provide, link, or otherwise make available, any
              information on the Services that is unlawful, threatening, abusive, harassing, torturous, defamatory,
              vulgar, obscene, libelous, invasive of another’s privacy, hateful, harmful to minors or racially,
              ethnically or otherwise objectionable;
            </li>
            <li>• Impersonate any person or entity, or otherwise misrepresent your affiliation;</li>
            <li>
              Manipulate, forge, or otherwise modify information or identifiers in a manner which may hide or disguise
              the origin of any information;
            </li>
            <li>
              Modify, reverse engineer, reverse assemble, decompile or hack into any of the software applications or
              related tools or utilities used by the Services;
            </li>
            <li>
              Alter or delete information not provided by you, or interfere with the operation of the Services
              including, but not limited to, by distribution of unsolicited advertising or mail messages or propagation
              of worms, viruses and the like.
            </li>
          </ul>
          <p>
            You also agree not to permit any other user of the Services to access, view, download or reproduce the
            material for any use not permitted by us.
          </p>
          <h2>7. No warranties</h2>
          <p>
            The Services and the content therein are provided "as is" and "as available". We make no warranties,
            expressed or implied, and hereby disclaim any warranty, including without limitation, implied warranties or
            conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual
            property or other violation of rights. Further, we do not warrant or make any representations concerning the
            accuracy, likely results, or reliability of the use of the content accessible through the Services and other
            services or otherwise relating to such content or on any sites linked to the Services.
          </p>
          <p>
            We offer no guarantee that the pages, services, functions, choices and content of the Services will be
            provided without interruption or errors or that any mistakes or errors will be corrected. We do not
            guarantee that the Services or the servers through which they become available to users do not contain
            viruses or other harmful components.
          </p>
          <p>
            The Services and the content therein may include technical, typographical, or photographic errors. We do not
            warrant that any of the materials on the Services are accurate, complete, or current. We may make changes to
            the materials contained on the Services at any time without notice. We do not, however, make any commitment
            to update the materials.
          </p>
          <p>
            Consequently, we cannot provide any type of guarantee and are neither responsible for the correctness,
            validity, thoroughness and accuracy of that information published, nor for the suitability of their usage
            for purposes other than informational only.
          </p>
          <h2>8. Exclusion of liability</h2>
          <p>
            Unless caused by our gross negligence or intent, we shall in no event be liable for any losses or damages,
            whether direct or indirect (including, without limitation, damages for loss of data or profit, or due to
            business interruption,) arising out of the Services or the use or inability to use the content of the
            Services, even if we have been notified of the possibility of such damage.
          </p>
          <p>
            We cannot guarantee the security of any transfer of information made via the Services. We do not assume any
            liability for the information contained on the Services. The Services may contain links to third-party
            websites which are not under our control. We are not responsible for the contents of, nor do we endorse, any
            third-party sites that are linked to the Services. We have not reviewed content on sites other than the
            Services and are not responsible for such content. We expressly disclaim responsibility for all
            representations made or implied in relation to products, publications or sites not owned by us.
          </p>
          <h2>9. Changes to the Services</h2>
          <p>
            You acknowledge and agree that nothing in these Terms constitutes an undertaking by us to provide the
            Services in its present form or under any specifications. We may, in our sole and absolute discretion, from
            time to time make additions to, deletions from, modifications to, or change the format and features of, the
            Services, or to the Terms and Conditions governing them.
          </p>
          <p>
            You further acknowledge that we may be obliged to alter, modify or reduce the extent of the Services due to
            mandatory law or decisions by competent governmental authorities. If such restriction apply to the Service
            in a way that may affect or impair the Paid Services, C4ADS shall bear no responsibility and shall not be
            obliged to perform any full or partial refund of any payments made by you for any Paid Services.
          </p>
          <h2>10. Governing Law and disputes</h2>
          <p>
            Any claim relating to the Services shall be governed by the laws of the District of Columbia without regard
            to its conflict of law provisions.
          </p>
          <p>
            All disputes arising out of or in connection with the present Agreement shall be finally settled by district
            courts of the District of Columbia.
          </p>
        </Container>
        <Footer {...this.props} />
      </React.Fragment>
    );
  }
}

export default TermsAndConditionsPage;
