import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Container, Row, Col } from "reactstrap";
import "./prompt-dialog.scss";
import Button from "../../../general/components/button/button";

const PromptDialog = props => {
	const { 
		headerText = 'Confirmation', 
		bodyText = 'Are you sure to continue', 
		confirmText = 'Confirm', 
		cancelText = 'Cancel',
		onConfirm = () => {},
		onCancel = () => {},
		isVisible = true
	} = props;

	return (  
		<Modal isOpen={isVisible} toggle={onCancel} size="md" centered={true} unmountOnClose={true}>
      <ModalHeader toggle={onCancel}>
        { headerText }
      </ModalHeader>
      <ModalBody>
        <div id="promptDialogWrapper">
          <Container fluid="sm">
            <Row>
              <Col lg="12" className="bodyText">
                { bodyText }
              </Col>
            </Row>
          </Container>
          <div className="actionArea">
            <Button
              text={cancelText}
              className="cancelBtn"
              onClick={onCancel}
            />
						<Button
              text={confirmText}
              className="confirmBtn"
              onClick={onConfirm}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
	);
}
 
export default PromptDialog;