import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, FormGroup, Row } from "reactstrap";
import * as images from "../../../../assets/images";
import LanguageParsedText, { getValueFromCurrentLang } from "../../../../multi-lang/lang-parsed-text/lang-parsed-text";
import { hideLoader, showLoader } from "../../../../redux/actions/loader-data";
import { updateUserData } from "../../../../redux/actions/user-data";
import Button from "../../../general/components/button/button";
import InputField from "../../../general/components/input-field/input-field";
import "./welcome-page.scss";
import { checkIfPasswordIsProper, showToast } from '../../../../helper-methods/index';
import { resetPassword } from "../../../../aws-cognito";

const initialState = {
  formFields: {
    email: {
      value: "",
      isValid: false,
      isDirty: false
		},
		verificationCode: {
      value: "",
      isValid: false,
      isDirty: false
		},
		password: {
      value: "",
      isValid: false,
      isDirty: false
    },
    confirmPassword: {
      value: "",
      isValid: false,
      isDirty: false
    }
  },
  isFormValid: false,
  responseError: ""
};

class WelcomePage extends Component {
  state = initialState;

  _markAsDirty = fieldName => {
    const { formFields } = this.state;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields });
    this._validateForm();
    this._hideResponseError();
  };

  _updateFieldValue = (fieldName, value) => {
    this._hideResponseError();
    const { formFields } = this.state;
    formFields[fieldName].value = value;
    this.setState({ formFields });
    if (formFields[fieldName].isDirty) {
      // Validate
      this._validateForm();
    }
  };

  _showResponseError = errorText => {
    this.setState({ responseError: errorText });
  };

  _hideResponseError = () => {
    const { responseError } = this.state;
    if (responseError.length) {
      this.setState({ responseError: "" });
    }
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      let isFormValid = true;
      Object.keys(formFields).forEach((fieldName, index) => {
        switch (fieldName) {
          case "email": {
            var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            // if (emailRegex.test(String(formFields.email.value).toLowerCase())) {
            //   formFields.email.isValid = true;
            // } else {
            //   formFields.email.isValid = false;
            //   isFormValid = false;
            // }
            if (formFields.email.value.length) {
              formFields.email.isValid = true;
            } else {
              formFields.email.isValid = false;
              isFormValid = false;
            }
            break;
					}
					case "verificationCode": {
						if (formFields.verificationCode.value && formFields.verificationCode.value.length === 6 && !isNaN(parseInt(formFields.verificationCode.value))) {
							formFields.verificationCode.isValid = true;
						} else {
							formFields.verificationCode.isValid = false;
							isFormValid = false;
						}
            break;
					}
					case "password": {
						if (checkIfPasswordIsProper(formFields.password.value)) {
							formFields.password.isValid = true;
						} else {
							formFields.password.isValid = false;
							isFormValid = false;
						}
            break;
          }
          case "confirmPassword": {
						if (formFields.confirmPassword.value === formFields.password.value) {
							formFields.confirmPassword.isValid = true;
						} else {
							formFields.confirmPassword.isValid = false;
							isFormValid = false;
						}
            break;
          }
        }
      });
      this.setState({ formFields, isFormValid }, () => {
        resolve();
      });
    });
  };

  _makeAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((fieldName, index) => {
        formFields[fieldName].isDirty = true;
      });
      this.setState({ formFields }, () => {
        resolve();
      });
    });
  };

  _resetPassword = async e => {
    e.preventDefault();
    this._makeAllFieldDirty();
    await this._validateForm();
    const { formFields, isFormValid } = this.state;
    if (isFormValid) {
			// Trigger password reset request
			try {
				const {
					email, verificationCode, password
				} = formFields;
				this.props.showLoader("Please Wait");
				await resetPassword(email.value, verificationCode.value, password.value);
				showToast("Password updated successfully", "success");
				this.props.hideLoader();
				this.props.history.push("/auth/login")
			} catch (error) {
				this.props.hideLoader();
				showToast(error.message, "error");
			}
    }
  };

  render() {
    const { formFields, responseError } = this.state;
    return (
      <React.Fragment>
        <div id="resetPasswordPageWarpper">
          <Container fluid={true}>
            <Row>
              <Col md="4" sm="12" className="imageWrapper">
								<img alt="" src={require("../../../../assets/images/files/Pharos Logo Final_Square Blue.svg")} />
              </Col>
              <Col md="8" sm="12" className="formWrapper">
								<h1>Thank you for registering</h1>
								<p>Shortly you will get an email with a verification link</p>
							</Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToDispatch = dispatch => {
  return {
    showLoader: loaderText => dispatch(showLoader(loaderText)),
    hideLoader: () => dispatch(hideLoader()),
    updateUserData: userData => dispatch(updateUserData(userData))
  };
};

export default connect(null, mapStateToDispatch)(WelcomePage);
