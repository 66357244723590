/**Dev values */
export const AWS_COGNITO = {
  REGION: "us-east-1",
  USER_POOL_ID: "us-east-1_JBk8Yg04e",
  APP_CLIENT_ID: "2039o3a0gpu2cssufpcr127o8j",
  IDENTITY_POOL_ID: "us-east-1:6715b765-dd5a-446d-a830-65aa5ed4d01d"
};

export const S3_CONFIGS = {
  REGION: "us-east-1",
  BUCKET: "pharos-infrastructure-prod-reportsbucket-ghvesldb7oas",
  IDENTITY_POOL_ID: "us-east-1:6715b765-dd5a-446d-a830-65aa5ed4d01d"
};

export const API_ENDPOINTS = [
  {
    name: "create-report",
    endpoint: "https://4p4msnqc0h.execute-api.us-east-1.amazonaws.com/prod",
    region: "us-east-1"
  },
  {
    name: "list-report",
    endpoint: "https://4p4msnqc0h.execute-api.us-east-1.amazonaws.com/prod",
    region: "us-east-1"
  },
  {
    name: "update-report",
    endpoint: "https://4p4msnqc0h.execute-api.us-east-1.amazonaws.com/prod",
    region: "us-east-1"
  },
  {
    name: "delete-report",
    endpoint: "https://4p4msnqc0h.execute-api.us-east-1.amazonaws.com/prod",
    region: "us-east-1"
  }
];

// /**Alpha values */
// export const AWS_COGNITO = {
// 	"REGION": "us-east-1",
// 	"USER_POOL_ID": "us-east-1_6owGh6dcy",
// 	"APP_CLIENT_ID": "4fi2lhdgh74md4iqd6rjec2gjh",
// 	"IDENTITY_POOL_ID": "us-east-1:88debce3-8c69-4b47-ab26-4deb8f71a097"
// }

// export const S3_CONFIGS = {
// 	"REGION": "us-east-1",
// 	"BUCKET": "pharos-infrastructure-alpha-reportsbucket-m8nd4xzdrgo0",
// 	"IDENTITY_POOL_ID": "us-east-1:88debce3-8c69-4b47-ab26-4deb8f71a097"
// }

// export const API_ENDPOINTS = [{
// 	name: "create-report",
// 	endpoint: "https://kh27hwk3if.execute-api.us-east-1.amazonaws.com/alpha",
// 	region: "us-east-1"
// },{
// 	name: "list-report",
// 	endpoint: "https://kh27hwk3if.execute-api.us-east-1.amazonaws.com/alpha",
// 	region: "us-east-1"
// },{
// 	name: "update-report",
// 	endpoint: "https://kh27hwk3if.execute-api.us-east-1.amazonaws.com/alpha",
// 	region: "us-east-1"
// },{
// 	name: "delete-report",
// 	endpoint: "https://kh27hwk3if.execute-api.us-east-1.amazonaws.com/alpha",
// 	region: "us-east-1"
// }]
