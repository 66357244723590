import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Container, Row, Col } from "reactstrap";
import style from "./language-selector-modal.module.scss";
import { EventEmitter } from "../../../../helper-methods";
import Button from "../button/button";
import { LANGUAGES } from "../../../../languages/index";
import { connect } from "react-redux";
import { updateCurrentLanguage } from "../../../../redux/actions/language-data";
import MultiLang from "../../../../multi-lang/index";
import LanguageParsedText from "../../../../multi-lang/lang-parsed-text/lang-parsed-text";

const LanguageSelectorModal = props => {
  const [modal, setModal] = useState(false);

  const {
    languageData: { currentLang = "english" }
  } = props;

  const [activeLanguage, updateActiveLanguage] = useState(currentLang);

  const _saveChanges = () => {
    // Change language
    MultiLang.setCurrentLang(activeLanguage);
    // Update redux to persist changes
    props.updateCurrentLanguage(activeLanguage);
    // Dismiss modal
    setModal(false);
  };

  const _updateCurrentLanguage = languageKey => {
    updateActiveLanguage(languageKey);
  };

  const _isSaveButtonEnabled = () => {
    if (currentLang === activeLanguage) {
      return " " + style.disabledButton;
    }
    return "";
  };

  // Subscribe to the event to listen modal open requests from any other components/pages
  EventEmitter.subscribe("open-language-modal", () => {
    setModal(true);
  });

  const _isActiveLanguage = language => {
    if (activeLanguage === language.key) {
      return " " + style.active;
    }
    return "";
  };

  // Get available languages and convert them into a iterable array
  const availableLanguages = Object.values(LANGUAGES);

  return (
    <Modal isOpen={modal} toggle={() => setModal(false)} size="lg" centered={true} unmountOnClose={true}>
      <ModalHeader toggle={() => setModal(false)}>
        <LanguageParsedText keyString="languageSwitchModal.heading" />
      </ModalHeader>
      <ModalBody>
        <div className={style.flagListWrapper}>
          <Container fluid="sm">
            <Row xs="1" sm="2" lg="4">
              {availableLanguages.map((language, languageIndex) => (
                <Col key={languageIndex} onClick={() => _updateCurrentLanguage(language.key)}>
                  <div className={style.languageBtnWarpper + _isActiveLanguage(language)}>
                    <img alt="" src={language.flagImage} className={style.flagIcon} />
                    <p>{language.label}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
        <div className={style.modalFooterwrapper}>
          <p onClick={() => setModal(false)}>
            <LanguageParsedText keyString="languageSwitchModal.cancelButton" />
          </p>
          <Button
            text={<LanguageParsedText keyString="languageSwitchModal.confirmButton" />}
            className={style.confirmButton + _isSaveButtonEnabled()}
            onClick={_saveChanges}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    languageData: state.languageData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateCurrentLanguage: languageKey => dispatch(updateCurrentLanguage(languageKey))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelectorModal);
