import { store } from "../redux/store";
import { clearUserData } from "../redux/actions/user-data";
import * as _deepClone from "clone-deep";
import cogoToast from "cogo-toast";
import { logoutUser } from "../aws-cognito/index";

export const logout = navRef => {
  store.dispatch(clearUserData());
  // Cognito logout
  logoutUser();
  // Clear other reducers data also
  if (navRef) {
    navRef.replace("/home");
  }
};

export const deepClone = data => {
  return _deepClone(data);
};

export const sleepTime = n => new Promise(r => setTimeout(() => r(), n));

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const showToast = (message, type = "error") => {
  cogoToast[type](message, {
    hideAfter: 5,
    position: "bottom-right"
  });
};

export const EventEmitter = {
  events: {},
  dispatch: function (event, data = null) {
    // Check if the specified event is exist / subscribed by anyone
    if (!this.events[event]) {
      // Doesn't exist, so just return
      return;
    } else {
      // Exists
      // Process all bound callbacks
      this.events[event].forEach(callback => callback(data));
    }
  },
  subscribe: function (event, callback) {
    // Check if the specified event is exist / subscribed by anyone
    if (!this.events[event]) {
      // Not subscribed yet, so make it an array so that further callbacks can be pushed
      this.events[event] = [];
    }
    // Push the current callback
    this.events[event].push(callback);
  }
};

export const checkIfPasswordIsProper = password => {
  const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
  return re.test(password);
};


export const extractQueryParams = () => {
  let { location: { search: queryParamString }} = window;
  let params = {};
  if (queryParamString.length > 1 && queryParamString.indexOf("?") > -1) {
    queryParamString = queryParamString.replace("?","");
    if (queryParamString.indexOf("&") === -1) {
      // Contains only one param
      const paramParts = queryParamString.split("=");
      params[paramParts[0]] = paramParts[1];
    } else {
      // Contains multiple params
      const queryParams = queryParamString.split("&");
      queryParams.forEach(queryParam => {
        const paramParts = queryParam.split("=");
        params[paramParts[0]] = paramParts[1];
      })
    }
  }
  return params;
}