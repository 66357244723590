import MultiLang from "../multi-lang/index";
import ukEnglish from "./uk-english/index";
import usEnglish from "./us-english/index";
import { store } from "../redux/store";
import { updateCurrentLanguage } from '../redux/actions/language-data';

// List of languages
export const LANGUAGES = {
  usEnglish,
  ukEnglish
};

// Initialize multi language
// Set available langugage modules
MultiLang.setLangModules(LANGUAGES);
// Set default fallback language
MultiLang.setDefaultLang("usEnglish");
// Wait for perist rehydration
setTimeout(() => {
  let {
    languageData: { currentLang = "us-english" }
  } = store.getState();
  if (currentLang !== 'ukEnglish' && currentLang !== 'usEnglish') {
    currentLang = "usEnglish"
  }
  // Set current language
  MultiLang.setCurrentLang(currentLang);
  store.dispatch(updateCurrentLanguage(currentLang))
}, 300);
