import { Storage, API } from "aws-amplify";

export async function s3Upload(file) {
  const filename = `${Date.now()}-${file.name}`;
  const stored = await Storage.put(filename, file, {
    contentType: file.type,
	});
  return stored.key;
}

export async function removeFileFromS3(key) {
  await Storage.remove(key);
}

export async function getS3DownloadLink(fileLink) {
  const response = await Storage.get(fileLink)
	console.log('response :>> ', response);
	return response;
}

export const createReport = (report) => {
	return API.post("create-report", "/reports", {
		body: report
	})
}

export const getAllReports = () => {
	return API.get("list-report", "/reports")
}

export const updateReport = (reportId, report) => {
	return API.put("update-report", `/reports/${reportId}`, {
		body: report
	})
}

export const deleteReport = (reportId) => {
	return API.del("delete-report", `/reports/${reportId}`)
}