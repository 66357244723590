import React, { Component } from "react";
import style from "./manage-password.module.scss";
import LanguageParsedText, { getValueFromCurrentLang } from "../../../../multi-lang/lang-parsed-text/lang-parsed-text";
import Button from "../../../general/components/button/button";
import InputField from "../../../general/components/input-field/input-field";
import { scrollToTop, deepClone } from "../../../../helper-methods";
import { changePassword } from '../../../../aws-cognito/index';
import { showToast, checkIfPasswordIsProper } from '../../../../helper-methods/index';

const initialState = {
  formFields: {
    oldPassword: {
      value: "",
      isValid: false,
      isDirty: false
		},
		newPassword: {
      value: "",
      isValid: false,
      isDirty: false
		},
		confirmPassword: {
      value: "",
      isValid: false,
      isDirty: false
		}
  },
  isFormValid: false,
  responseError: ""
};

class ManagePassword extends Component {
  state = deepClone(initialState);

  componentDidMount() {
    scrollToTop();
  }

  _markAsDirty = fieldName => {
    const { formFields } = this.state;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields });
    this._validateForm();
  };

  _updateFieldValue = (fieldName, value) => {
    const { formFields } = this.state;
    formFields[fieldName].value = value;
    this.setState({ formFields });
    if (formFields[fieldName].isDirty) {
      // Validate
      this._validateForm();
    }
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      let isFormValid = true;
      Object.keys(formFields).forEach((fieldName, index) => {
        switch (fieldName) {
          case "oldPassword": {
						if (formFields.oldPassword.value.length >= 6) {
							formFields.oldPassword.isValid = true;
						} else {
							formFields.oldPassword.isValid = false;
							isFormValid = false;
						}
            break;
          }
					case "newPassword": {
						if (checkIfPasswordIsProper(formFields.newPassword.value)) {
							formFields.newPassword.isValid = true;
						} else {
							formFields.newPassword.isValid = false;
							isFormValid = false;
						}
            break;
          }
          case "confirmPassword": {
						if (formFields.confirmPassword.value === formFields.newPassword.value) {
							formFields.confirmPassword.isValid = true;
						} else {
							formFields.confirmPassword.isValid = false;
							isFormValid = false;
						}
            break;
          }
        }
      });
      this.setState({ formFields, isFormValid }, () => {
        resolve();
      });
    });
  };

  _makeAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((fieldName, index) => {
        formFields[fieldName].isDirty = true;
      });
      this.setState({ formFields }, () => {
        resolve();
      });
    });
  };

  _changePassword = async e => {
    e.preventDefault();
    this._makeAllFieldDirty();
    await this._validateForm();
    const { formFields, isFormValid } = this.state;
    if (isFormValid) {
			// Trigger password reset request
			try {
				const {
					oldPassword, newPassword
				} = formFields;
				this.props.showLoader("Please Wait");
				await changePassword(oldPassword.value, newPassword.value);
				showToast("Password updated successfully", "success");
				this.props.hideLoader();
			} catch (error) {
        this.props.hideLoader();
        if (error.message.indexOf("Incorrect") > -1) {
          showToast("Old password didn't match", "error");
        } else {
          showToast(error.message, "error");
        }
			}
    }
  };

  render() {
    const { formFields } = this.state;
    return (
      <React.Fragment>
        <div className={style.userPageSectionsWrapper}>
          <h4>
            <i className="fas fa-unlock"></i> &nbsp;{" "}
            <LanguageParsedText keyString="userSettingsPage.changePasswordSection.header" />
          </h4>
          <p>
            <LanguageParsedText keyString="userSettingsPage.changePasswordSection.subHeader" />
          </p>
        </div>
        <div className={style.inputGroup}>
          <p>
            <LanguageParsedText keyString="userSettingsPage.changePasswordSection.oldPasswordField.label" />
          </p>
          <InputField
            placeholder={getValueFromCurrentLang("userSettingsPage.changePasswordSection.oldPasswordField.placeholder")}
            type="password"
            value={formFields.oldPassword.value}
            onChange={e => this._updateFieldValue("oldPassword", e.target.value)}
            onBlur={() => this._markAsDirty("oldPassword")}
          />
          {formFields.oldPassword.isDirty && !formFields.oldPassword.isValid ? (
            <p className="errorText" style={{width: 300}}>
              Please provide valid password
            </p>
          ) : null}
        </div>
        <div className={style.inputGroup}>
          <p>
            <LanguageParsedText keyString="userSettingsPage.changePasswordSection.newPasswordField.label" />
          </p>
          <InputField
            placeholder={getValueFromCurrentLang("userSettingsPage.changePasswordSection.newPasswordField.placeholder")}
            type="password"
            value={formFields.newPassword.value}
            onChange={e => this._updateFieldValue("newPassword", e.target.value)}
            onBlur={() => this._markAsDirty("newPassword")}
          />
          {formFields.newPassword.isDirty && !formFields.newPassword.isValid ? (
            <p className="errorText" style={{width: 300}}>
              Minimum Password length is 6 and should contain minimum one uppercase, one lowercase, one digit and one special charecter
            </p>
          ) : null}
        </div>
        <div className={style.inputGroup}>
          <p>
            <LanguageParsedText keyString="userSettingsPage.changePasswordSection.confirmPasswordField.label" />
          </p>
          <InputField
            placeholder={getValueFromCurrentLang(
              "userSettingsPage.changePasswordSection.confirmPasswordField.placeholder"
            )}
            type="password"
            value={formFields.confirmPassword.value}
            onChange={e => this._updateFieldValue("confirmPassword", e.target.value)}
            onBlur={() => this._markAsDirty("confirmPassword")}
          />
          {formFields.confirmPassword.isDirty && !formFields.confirmPassword.isValid ? (
            <p className="errorText" style={{width: 300}}>
              Password didn't match
            </p>
          ) : null}
        </div>
        <Button
          className={style.button}
          onClick={this._changePassword}
          text={<LanguageParsedText keyString="userSettingsPage.changePasswordSection.submitButton" />}
        />
      </React.Fragment>
    );
  }
}

export default ManagePassword;
