import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Container, Row, Col } from "reactstrap";
import "./dropbox-file-details-modal.scss";
import Button from "../../../general/components/button/button";
import { getS3DownloadLink } from "../../../../api-calls";
import LanguageParsedText from "../../../../multi-lang/lang-parsed-text/lang-parsed-text";

const DropboxFileDetailsModal = props => {
  const { isVisible, dismissModal, selectedFile, hasEditPermission, onEdit, onDelete } = props;
  const [downloadLink, setDownloadLink] = useState("");

  const _setDownloadLink = async () => {
    const fileLink = await getS3DownloadLink(selectedFile.link);
    setDownloadLink(fileLink);
  };

  useEffect(() => {
    if (selectedFile && selectedFile.link) {
      _setDownloadLink();
    } else {
      setDownloadLink("");
    }
  }, [selectedFile]);

  const _getFileName = file => {
    return file.title;
  };

  const _getFileType = file => {
    // Get file extension
    const fileParts = file.link.split(".");
    const extension = fileParts[fileParts.length - 1];
    return extension.charAt(0).toUpperCase() + extension.slice(1);
  };

  const _downloadFile = () => {
    props.showLoader("Preparing to download");
    fetch(downloadLink)
      .then(response => response.blob())
      .then(blob => {
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobURL;
        a.style = "display: none";
        a.target = "_blank";

        document.body.appendChild(a);
        a.setAttribute("download", `${selectedFile.title}.${_getFileType(selectedFile)}`);
        a.click();
        props.hideLoader();
      })
      .catch(() => {});
  };

  if (window.innerWidth <= 768) {
    return (
      <Modal isOpen={isVisible} toggle={dismissModal} size="md" centered={true} unmountOnClose={true}>
        <ModalHeader toggle={dismissModal}>
          <LanguageParsedText keyString="dropBoxPage.detailsWrapper.header" />
        </ModalHeader>
        <ModalBody>
          {selectedFile ? (
            <div id="dropBoxFileDetailsModalWrapper">
              <div id="detailsWrapper">
                <div className="fluidRow">
                  <div className="fileActions">
                    {hasEditPermission ? (
                      <>
                        <button className="editBtn" onClick={onEdit}>
                          {" "}
                          <i className="fas fa-pencil-alt"></i>
                        </button>
                        <button className="deleteBtn" onClick={onDelete}>
                          {" "}
                          <i className="fa fa-trash"></i>
                        </button>
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="fluidRow">
                  <div className="titlePart">
                    <LanguageParsedText keyString="dropBoxPage.detailsWrapper.labels.name" />
                  </div>
                  <div className="dataPart">{_getFileName(selectedFile)}</div>
                </div>
                <div className="fluidRow">
                  <div className="titlePart">
                    <LanguageParsedText keyString="dropBoxPage.detailsWrapper.labels.description" />
                  </div>
                  <div className="dataPart">{selectedFile.description}</div>
                </div>
                <div className="fluidRow">
                  <div className="titlePart">
                    <LanguageParsedText keyString="dropBoxPage.detailsWrapper.labels.type" />
                  </div>
                  <div className="dataPart">{_getFileType(selectedFile)} file</div>
                </div>
                <div className="fluidRow">
                  <div className="titlePart">
                    <LanguageParsedText keyString="dropBoxPage.detailsWrapper.labels.tags" />
                  </div>
                  <div className="dataPart" id="fileTopics">
                    {selectedFile.topics.map((topic, topicIndex) => (
                      <div className="topicChip" key={topicIndex}>
                        {topic}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div id="downloadBtnWrapper">
                <a
                  href=""
                  onClick={e => {
                    e.preventDefault();
                    _downloadFile();
                  }}
                >
                  <Button
                    className="downloadButton"
                    onClick={() => {}}
                    text={
                      <>
                        <i className="fas fa-download"></i>&nbsp;{" "}
                        <LanguageParsedText keyString="dropBoxPage.detailsWrapper.downloadButtonText" />
                      </>
                    }
                  />
                </a>
              </div>
            </div>
          ) : null}
        </ModalBody>
      </Modal>
    );
  } else {
    return null;
  }
};

export default DropboxFileDetailsModal;
