const ukEnglish = {
  flagImage: require("./uk_flag.png"),
  label: "UK English",
  key: "ukEnglish",
  data: {
    home: {
      jumbotron: {
        bigHeading: `Pharos - Educational portal for maritime domain awareness (MDA) training`,
        smallHeading: `Project Pharos is an educational portal for maritime domain awareness (MDA)training. Project Pharos will disseminate key concepts and best practices across organizations responsible for maritime security within the Indo-Pacific.`
      },
      trainingInfo: `To access the online training tool, please email <strong><<email>></strong> with your name,
      position and organization`,
      advantagesAndCharacteristics: {
        heading: `Advantages and characteristics of the Pharos educational portal`,
        block1: {
          heading: `Understand Watch Time`,
          details: `The landing page headline and advertisement wording should complement each other. Your AdWords score
          allows a site to discover.`
        },
        block2: {
          heading: `Identify Your Traffic`,
          details: `All website grammar needs to be flawless. Always double and triple check your copy, and have someone
          else read it through. In the example.`
        },
        block3: {
          heading: `Your Real-Time Report`,
          details: `Place the call-to-action button above the fold and in a location where the viewer's eye will scan
          to. Never have the button or form in a place where`
        }
      },
      aboutUs: {
        heading: `About Us`,
        subHeading: `Project Pharos is an educational portal for maritime domain awareness (MDA)training. Project
        Pharos will disseminate key concepts and best practices across organizations.`,
        block1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
        dolore magna aliqua. Morbi tristique senectus et netus et malesuada fames. Senectus et netus et
        malesuada fames ac turpis egestas maecenas. Donec enim diam vulputate ut. Felis imperdiet proin
        fermentum leo vel. Diam vel quam elementum pulvinar etiam non quam lacus suspendisse. Auctor elit sed
        vulputate mi sit amet mauris. Nec nam aliquam sem et tortor consequat id porta nibh. Turpis egestas
        pretium aenean pharetra magna ac placerat vestibulum. Rhoncus aenean vel elit scelerisque mauris.
        Mattis molestie a iaculis at erat pellentesque adipiscing commodo. Facilisis mauris sit amet massa.
        Convallis aenean et tortor at risus viverra adipiscing at in. Sagittis eu volutpat odio facilisis
        mauris. At tellus at urna condimentum mattis pellentesque id nibh tortor. Quis eleifend quam
        adipiscing vitae proin sagittis. Congue quisque egestas diam in arcu cursus. In ante metus dictum at
        tempor commodo ullamcorper a. Euismod elementum nisi quis eleifend`,
        block2: `Sit amet luctus venenatis lectus magna fringilla urna porttitor rhoncus. Odio aenean sed adipiscing
        diam donec adipiscing tristique. Libero justo laoreet sit amet cursus sit amet dictum sit. Varius quam
        quisque id diam vel quam elementum pulvinar etiam. Amet consectetur adipiscing elit ut aliquam purus.
        Tortor consequat id porta nibh. Enim sed faucibus turpis in eu mi bibendum neque. Pharetra pharetra
        massa massa ultricies mi quis hendrerit. Sed viverra ipsum nunc aliquet bibendum enim facilisis
        gravida neque. Laoreet non curabitur gravida arcu ac tortor. Blandit cursus risus at ultrices mi
        tempus imperdiet. Feugiat pretium nibh ipsum consequat nisl vel pretium lectus quam. Euismod lacinia
        at quis risus sed. Sem et tortor consequat id porta nibh venenatis. Ut consequat semper viverra nam
        libero justo laoreet sit amet. Nunc scelerisque viverra mauris in aliquam. Massa id neque aliquam
        vestibulum. Blandit aliquam etiam erat velit scelerisque in dictum non consectetur. Eget mauris
        pharetra et ultrices neque ornare aenean euismod. Eros donec ac odio pretium nibh ipsum consequat nisl
        vel pretium lectus quam. Euismod lacinia at quis risus sed. Sem`,
        block3: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
        dolore magna aliqua. Morbi tristique senectus et netus et malesuada fames. Senectus et netus et
        malesuada fames ac turpis egestas maecenas. Donec enim diam vulputate ut. Felis imperdiet proin
        fermentum leo vel. Diam vel quam elementum pulvinar etiam non quam lacus suspendisse. Auctor elit sed
        vulputate mi sit amet mauris. Nec nam aliquam sem et tortor consequat id porta nibh. Turpis egestas
        pretium aenean pharetra magna ac placerat vestibulum. Rhoncus aenean vel elit scelerisque mauris.
        Mattis molestie a iaculis at erat pellentesque adipiscing commodo. Facilisis mauris sit amet massa.
        Convallis aenean et tortor at risus viverra adipiscing at in. Sagittis eu volutpat odio facilisis
        mauris. At tellus at urna condimentum mattis pellentesque id nibh tortor. Quis eleifend quam
        adipiscing vitae proin sagittis. Congue quisque egestas diam in arcu cursus. In ante metus dictum at
        tempor commodo ullamcorper a. Euismod elementum nisi quis eleifend`
      },
      materials: {
        block1: {
          heading: `Curriculum`,
          details: `Open these files and links in uTorrent Mobile to quickly and efficiently download your
          torrents.`,
          buttonText: `Go to Curriculam`
        },
        block2: {
          heading: `Insights`,
          details: `Easily play back your music and audio files using the integrated media player. Use your
          browser to find.`,
          buttonText: `Go to Insights`
        }
      },
      footer: {
        backToTop: `Back to the top`,
        copyrightText: `© 2016 - 2020 Pharos All rights reserved`,
        link1: `Privacy Policy`,
        link2: `Terms & Conditions`
      }
    },
    header: {
      link1: `Curriculum`,
      link2: `Insights`,
      loginButton: `Log In`,
      link3: `User Settings`,
      link4: `Logout`
    },
    languageSwitchModal: {
      heading: `Choose your language`,
      cancelButton: `Cancel`,
      confirmButton: `Confirm`
    },
    loginPage: {
      header: `Log In to Pharos`,
      subHeader: `Welcome back, please login to your account`,
      field1: {
        placeholder: `Username or Email`,
        errorMessage: `Please provide valid email`
      },
      field2: {
        placeholder: `Password`,
        errorMessage: `Please provide valid password`
      },
      rememberMe: `Remember me`,
      forgotPassword: `Forgot Password`,
      submitButton: `Submit`,
      registerLabel: `Don't have account?`,
      registerLink: `Create a new account`
    },
    forgotPasswordPage: {
      header: `Forgot Password`,
      subHeader: `Enter your email we will send you a link to recover your password.`,
      field1: {
        placeholder: `Your Email`,
        errorMessage: `Please provide valid email`
      },
      submitButton: `Send reset instructions`,
      loginLink: `Back to Log In`
    },
    resetPassword: {
      header: `Reset password`,
      subHeader: `Please check your mail for the verification code.`,
      fields: {
        name: {
          placeholder: `Email`,
          errorMessage: `Please provide valid email`
        },
        verificationCode: {
          placeholder: `Verification Code`,
          errorMessage: `Please provide valid verification code`
        },
        newPassword: {
          placeholder: `New Password`,
          errorMessage: `Minimum Password length is 6 and should contain minimum one uppercase, one lowercase, one digit and one special charecter`
        },
        confirmPassword: {
          placeholder: `Confirm Password`,
          errorMessage: `Password didn't match`
        },
      },
      submitButton: `Reset Password`,
    },
    signUpPage: {
      header: `Create a new account`,
      subHeader: `Give us some your information to get free access to Pharos.`,
      fields: {
        name: {
          label: `Name`,
          errorMessage: `Please provide valid name`
        },
        organization: {
          label: `Organization`,
          errorMessage: `Please provide valid organization name`
        },
        jobTitle: {
          label: `Job Title`,
          errorMessage: `Please provide valid job title`
        },
        phone: {
          label: `Phone`,
          errorMessage: `Please provide valid phone number`
        },
        email: {
          label: `Email`,
          errorMessage: `Please provide a valid email`
        },
        password: {
          label: `Password`,
          errorMessage: `Minimum length must be 5`
        },
        confirmPassword: {
          label: `Confirm Password`,
          errorMessage: {
            didntMatch: `Password didn't match`,
            cannotBeEmpty: `Please confirm password`
          }
        }
      },
      submitButton: `Create Account`,
      terms: `
        Creating an account means you're okay our <a href="#">Terms of Service</a>, 
        <a href="#">Privacy Policy</a>, and our default
        <a href="#"> Notification Settings</a>.
        `,
      loginLink: `Already have an account? Log In`,
      formErrors: {
        acceptTerms: `Please accept the terms to continue`,
        loginServerError: `Login server error`
      },
      loaderTexts: {
        creatingAccount: `Creating your account`
      }
    },
    userSettingsPage: {
      userSettingsSection: {
        tabText: `User Settings`,
        header: `User Settings`,
        subHeader: `Change your user settings below`,
        emailField: {
          label: `Email`
        },
        userNameField: {
          label: `Name`,
          placeholder: `Name`
        },
        organizationField: {
          label: `Organization`,
          placeholder: `Organization`
        },
        jobTitleField: {
          label: `Job Title`,
          placeholder: `Job Title`
        },
        phoneNumberField: {
          label: `Phone Number`,
          placeholder: `Phone Number`
        },
        submitButton: `Save & Update`
      },
      changePasswordSection: {
        tabText: `Password`,
        header: `Change Password`,
        subHeader: `Please enter your old and new password`,
        oldPasswordField: {
          label: `Old password`,
          placeholder: `Old password`
        },
        newPasswordField: {
          label: `New password`,
          placeholder: `New password`
        },
        confirmPasswordField: {
          label: `Confirm new password`,
          placeholder: `Confirm new password`
        },
        submitButton: `Change password`
      },
      notificationSettingsSection: {
        tabText: `Notifications`,
        header: `Notification Settings`,
        subHeader: `Update your notification settings below`,
        emailNotificationRadio: {
          label: `Email notification frequency`,
          option1: `Off`,
          option2: `Once a Day`,
          option3: `Once a Week`,
          option4: `Once a Month`
        },
        submitButton: `Save & Update`
      },
      learningProgressSection: {
        tabText: `Learning Progress`,
      }
    },
    curriculumPage: {
      actionButtons: {
        previous: "Previous Section",
        next: "Next Section",
      }
    },
    dropBoxPage: {
      subHeader: "Access C4ADS maritime investigations",
      uploadButtonText: "Upload new file",
      addFilterPlaceHolder: "Add Filters",
      table: {
        header1: "Name",
        header2: "Type",
        header3: "Modified",
      },
      detailsWrapper: {
        header: "Details",
        labels: {
          name: "Name",
          description: "Description",
          type: "Type",
          tags: "Tags"
        },
        downloadButtonText: "Download file"
      },
      uploadModal: {
        header: "Upload new file",
        fields: {
          file: {
            placeHolder: "No is file chosen",
            errorMessage: {
              provideValidFile: "Please provide valid file",
              supportedFiles: "Supported files type: PDF, DOC XLSX, XLS, HTML, XML"
            }
          },
          title: {
            placeHolder: "Title",
            errorMessage: "Please provide valid title"
          },
          description: {
            placeHolder: "Description",
            errorMessage: "Please provide valid description"
          },
          tags: {
            label: "Tags",
            placeHolder: "Add Tags",
          }
        },
        uploadButtonText: "Upload File"
      }
    },
    aboutUsPage: {
      jumbotron: {
        bigHeading: `Pharos - Educational portal for maritime domain awareness (MDA) training`,
        smallHeading: `Project Pharos is an educational portal for maritime domain awareness (MDA)training. Project Pharos will disseminate key concepts and best practices across organizations responsible for maritime security within the Indo-Pacific.`
      },
      trainingInfo: `To access the online training tool, please email <strong><<email>></strong> with your name,
      position and organization`,
      advantagesAndCharacteristics: {
        heading: `Advantages and characteristics of the Pharos educational portal`,
        block1: {
          heading: `Understand Watch Time`,
          details: `The landing page headline and advertisement wording should complement each other. Your AdWords score
          allows a site to discover.`
        },
        block2: {
          heading: `Identify Your Traffic`,
          details: `All website grammar needs to be flawless. Always double and triple check your copy, and have someone
          else read it through. In the example.`
        },
        block3: {
          heading: `Your Real-Time Report`,
          details: `Place the call-to-action button above the fold and in a location where the viewer's eye will scan
          to. Never have the button or form in a place where`
        }
      },
      aboutUs: {
        heading: `About Us`,
        block1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dignissim convallis aenean et tortor at. Viverra suspendisse potenti nullam ac tortor vitae purus faucibus. Tellus molestie nunc non blandit massa. A pellentesque sit amet porttitor eget. Ut aliquam purus sit amet luctus venenatis lectus magna fringilla. Nulla facilisi nullam vehicula ipsum a arcu. Diam vel quam elementum pulvinar etiam non quam lacus suspendisse. Mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar. Cursus metus aliquam eleifend mi. Feugiat in fermentum posuere urna nec tincidunt praesent semper. Porta non pulvinar neque laoreet suspendisse interdum. Tortor condimentum lacinia quis vel eros donec ac odio tempor. Orci a scelerisque purus semper eget duis. Tortor at risus viverra adipiscing at in.`,
        block2: `Imperdiet sed euismod nisi porta lorem mollis aliquam. Tempor nec feugiat nisl pretium fusce id. Sit amet nisl suscipit adipiscing bibendum est ultricies integer. Enim praesent elementum facilisis leo vel. Vel facilisis volutpat est velit. Sagittis vitae et leo duis ut. Platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim cras. Nibh ipsum consequat nisl vel pretium lectus quam id leo. Duis convallis convallis tellus id interdum velit laoreet id donec. Porta lorem mollis aliquam ut porttitor leo a diam sollicitudin. Vivamus arcu felis bibendum ut tristique et egestas quis ipsum. Diam phasellus vestibulum lorem sed. Quis eleifend quam adipiscing vitae proin sagittis nisl. Vitae justo eget magna fermentum iaculis. Urna molestie at elementum eu facilisis sed. Maecenas accumsan lacus vel facilisis volutpat. Ac turpis egestas sed tempus urna et pharetra. Suscipit adipiscing bibendum est ultricies integer quis auctor elit sed.`,
        block3: `A iaculis at erat pellentesque adipiscing commodo. Gravida arcu ac tortor dignissim convallis aenean et. Aliquam malesuada bibendum arcu vitae elementum. Scelerisque varius morbi enim nunc faucibus a pellentesque sit amet. Id volutpat lacus laoreet non curabitur gravida arcu. Gravida quis blandit turpis cursus in hac habitasse platea. Augue ut lectus arcu bibendum at. Nisl rhoncus mattis rhoncus urna neque viverra justo nec. Quisque sagittis purus sit amet volutpat. Commodo elit at imperdiet dui accumsan. Mauris vitae ultricies leo integer malesuada nunc vel risus commodo. Purus viverra accumsan in nisl nisi scelerisque eu ultrices vitae. Sed arcu non odio euismod lacinia. Ullamcorper dignissim cras tincidunt lobortis. Erat imperdiet sed euismod nisi porta lorem mollis aliquam. Pellentesque pulvinar pellentesque habitant morbi. Aliquam ultrices sagittis orci a scelerisque purus semper eget. Interdum posuere lorem ipsum dolor sit.`,
        block4: `Cras pulvinar mattis nunc sed blandit libero volutpat. Tortor at risus viverra adipiscing at in tellus integer feugiat. Et leo duis ut diam quam. Vestibulum mattis ullamcorper velit sed ullamcorper. Et odio pellentesque diam volutpat commodo sed egestas. Tellus molestie nunc non blandit massa enim. Feugiat in ante metus dictum at tempor commodo. Ut pharetra sit amet aliquam id diam maecenas ultricies. Interdum velit laoreet id donec ultrices tincidunt arcu non. Eget dolor morbi non arcu. Bibendum arcu vitae elementum curabitur vitae nunc sed velit. Vitae congue eu consequat ac felis donec et odio. Sed velit dignissim sodales ut eu sem integer vitae justo. Viverra maecenas accumsan lacus vel. Dignissim sodales ut eu sem. Nulla aliquet porttitor lacus luctus.`,
        block5: `Egestas maecenas pharetra convallis posuere morbi. Leo vel orci porta non pulvinar. Habitasse platea dictumst quisque sagittis purus. Feugiat nibh sed pulvinar proin. Commodo ullamcorper a lacus vestibulum sed arcu non odio. Sed vulputate mi sit amet mauris commodo quis. Leo integer malesuada nunc vel risus commodo viverra. Volutpat maecenas volutpat blandit aliquam. Elementum nibh tellus molestie nunc non blandit. A diam maecenas sed enim ut sem viverra. Morbi tristique senectus et netus et malesuada fames. Quis ipsum suspendisse ultrices gravida dictum fusce. Non arcu risus quis varius. Eleifend quam adipiscing vitae proin sagittis nisl rhoncus mattis. Sit amet venenatis urna cursus eget nunc scelerisque. Sagittis nisl rhoncus mattis rhoncus urna neque. Gravida in fermentum et sollicitudin ac orci phasellus egestas. Bibendum est ultricies integer quis auctor elit sed vulputate. Justo eget magna fermentum iaculis eu non.`
      },
      specialistPanel: {
        heading: `Our Best Qualified Specialists`,
        subHeading: `We are a team of like-minded people who do business, <br />
        generate ideas and realize them.`,
        specialists: {
          chadStinson: {
          name: `Chad Stinson`,
          title: `LEAD COUNSEL`,
          detail: `Nobody knows business jurisdiction better than Chad! He is the best specialist.`,
        },
        jasonNicks: {
          name: `Jason Nicks`,
          title: `FOUNDER & CEO`,
          detail: `Jason is the most devoted person! He started his career in Finance when he was 20.`,
        },
        stasyWinter: {
          name: `Stasy Winter`,
          title: `SENIOR MARKETER`,
          detail: `Stacy is a first-class professional in Marketing and PR sphere. We call her.`,
        },
        benWallace: {
          name: `Ben Wallace`,
          title: `TRADER`,
          detail: `On August 11, 1999, Wallace was traded to the Orlando Magic in a multiplayer deal`,
        }},
        seeAllButtin: `See All`
      },
      footer: {
        backToTop: `Back to the top`,
        copyrightText: `© 2016 - 2020 Pharos All rights reserved`,
        link1: `Privacy Policy`,
        link2: `Terms & Conditions`
      }
    }
  }
};

export default ukEnglish;
